import { apiSlice } from "../app/api/apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSettings: builder.query({
            query: () => `/user/settings`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['settings']
        }),

        editLogo: builder.mutation({
            query: (input) => ({
                url: `user/edit-logo`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['settings']
        }),
    })
})

export const {
    useEditLogoMutation,
    useGetSettingsQuery,
} = settingsApiSlice

