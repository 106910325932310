import { apiSlice } from "../app/api/apiSlice";

export const productsSliceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllProducts: builder.query({
            query: ({pagination, page}) => (page < 1)? `/user/products/${pagination}` : `user/products/${pagination}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['products']
        }),
        getAdminAllProducts: builder.query({
            query: ({pagination, page}) => (page < 1)? `/user/admin-products/${pagination}` : `user/admin-products/${pagination}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['products']
        }),
        seachProducts: builder.mutation({
            query: ({input, page}) => (page==1)? `user/products-seach/${input.pagination}/${input.search}` : `user/products-seach/${input.pagination}/${input.search}/?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['products']
        }),
        seachAdminProducts: builder.mutation({
            query: ({input, page}) => (page==1)? `user/admin-products-seach/${input.pagination}/${input.search}` : `user/admin-products-seach/${input.pagination}/${input.search}/?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['products']
        }),
        getAllFeaturedProducts: builder.query({
            query: () => `/user/featured-products`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['products']
        }),
        getBestSellingProducts: builder.query({
            query: () => `/user/best-selling-products`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['products']
        }),
        createProduct: builder.mutation({
            query: (input) => ({
                url: `user/create-product`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['products']
        }),
        editProduct: builder.mutation({
            query: (input) => ({
                url: `user/edit-product/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['products']
        }),
        getProductById: builder.query({
            query: (id) => `/user/product/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['products']
        }),
        getProductByIdMutation: builder.mutation({
            query: (id) => `/user/product/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['products']
        }),
        toggleProductStatus: builder.mutation({
            query: (id) => ({
                url: `user/toggle-product-status/${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['products']
        }),
        deleteBrand: builder.mutation({
            query: (id) => ({
                url: `user/delete-brand/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['products']
        }),
        nextPage: builder.mutation({
            query: (url) => `${url}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['products']
        }),
    })
})

export const {
    useCreateProductMutation,
    useGetAllProductsQuery,
    useGetProductByIdQuery,
    useGetProductByIdMutationMutation,
    useEditProductMutation,
    useGetAllFeaturedProductsQuery,
    useGetBestSellingProductsQuery,
    useToggleProductStatusMutation,
    useSeachProductsMutation,
    useSeachAdminProductsMutation,
    useGetAdminAllProductsQuery,
    useNextPageMutation,
} = productsSliceApiSlice

