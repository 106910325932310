import { Link, useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { ArrowCircleDownIcon, PencilAltIcon } from '@heroicons/react/outline';


function EmptyOrdersTableHelperView() {

    return (
            <div class="bg-white overflow-auto">
                <table class="text-left w-full border-collapse">
                    <thead>
                            <tr>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Date
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Name
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Phone
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Location
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Charge
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Shipping
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Discount
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Units
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Status
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                    <tbody>
                        <tr class="hover:bg-gray-50">
                            <td class="py-4 px-6 border-b border-grey-light"></td>
                            <td class="py-4 px-6 border-b border-grey-light"></td>
                            <td class="py-4 px-6 border-b border-grey-light"></td>
                            <td class="py-4 px-6 border-b border-grey-light"></td>
                            <td class="py-4 px-6 border-b border-grey-light"></td>
                            <td class="py-4 px-6 border-b border-grey-light"></td>
                            <td class="py-4 px-6 border-b border-grey-light"></td>
                            <td class="py-4 px-6 border-b border-grey-light"></td>
                            <td class="py-4 px-6 border-b border-grey-light">
                                
                            </td>
                            <td class="py-4 px-6 border-b border-grey-light">
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>
    )
}

export default EmptyOrdersTableHelperView;
