import { useNavigate } from 'react-router-dom';

function HomeHeader() {
  const navigate = useNavigate();

  const back = ()=>{
    navigate('/');
  }
   
  return (
      <main className="sticky top-0 z-50 flex justify-center items-center bg-teal-700">
          {/* <div onClick={back} className="text-white bg-blue-700 hover:bg-blue-800 cursor-pointer pr-4 pl-4 pt-1 pb-1 rounded-md">Home</div> */}
          <div className="flex items-center justify-center py-4 px-6">
            <h1 className="flex text-md font-semibold rounded-xl">
                <h1 className="uppercase text-white">swift Ticket system</h1>
            </h1>
          </div>
      </main>
  )
  }
  
  export default HomeHeader;