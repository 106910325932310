import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FileUploader } from "react-drag-drop-files";
import swal from 'sweetalert';
import LoadingSpinner from '../LoadingSpinner';
import { useCreateBrandModelMutation, useCreateBrandMutation, useDeleteBrandMutation, useEditBrandImageMutation, useEditBrandMutation, useGetAllBrandsQuery, useGetBrandByIdMutationMutation, useGetBrandModelsMutationMutation } from '../../Features/brandsSlice';


function AdminUserBrandsView({props}) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openEditImageModal, setOpenEditImageModal] = useState(false)
    const cancelButtonRef = useRef(null)
    
    const [file, setFile] = useState(null);
    const fileTypes = ["JPG", "PNG", "GIF"];

    const getBase64 = (file) => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
      });
    };

    const handleFileChange = async(file) => {
      const base64 = await getBase64(file[0])
      setFile(file);
      setInput({...input,  
        brand_image: base64,
      })
    };

    const [input, setInput] = useState({
      name: '',
      brand_image: [],
      id: '',
      model: '',
    })

    const [errors, setErrors] = useState({
        name: '',
        brand_image: [],
        model: '',
    })
      
    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const handleFile = (file) =>{
      setInput({...input, brand_image: file.base64})
    }

    const handleCloseModal = (e)=>{
      setInput({...input,  
        name: '',
        brand_image: [],
      })

      setErrors({...errors,  
        name: '',
        brand_image: [],
        id: '',
      })
      setOpen(false)
      setFile(null);
    }

    const handleCloseEditModal = (e)=>{
      setInput({...input,  
        name: '',
        brand_image: [],
      })

      setErrors({...errors,  
        name: '',
        brand_image: [],
      })
      setOpenEditModal(false)
      setFile(null); 
    }
    
    const handleCloseEditImageModal = (e)=>{
      setInput({...input,  
        name: '',
        brand_image: [],
      })

      setErrors({...errors,  
        name: '',
        brand_image: [],
        id: ''
      })
      setOpenEditImageModal(false)
      setFile(null); 
    }

    const {
      data: brands,
      isLoading: isLoadingBrands,
      isSuccess: isSuccessBrands,
      isError: isErrorBrands,
      error: errorBrands
    } = useGetAllBrandsQuery()

    const [createBrand, {isLoading, isSuccess, isError}] = useCreateBrandMutation()

    const handleSubmit = async(e)=>{
        e.preventDefault();
        // return console.log(input.brand_image)
        try {
            const resp = await createBrand(input).unwrap()
            handleCloseModal()
            swal("Success",resp.message,"success");
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors,  
                  name: err.data.errors.name,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const [editBrand, {isLoading: isLoadingEdit, isSuccess: isSuccessEdit}] = useEditBrandMutation()

    const handleSubmitEdit = async(e)=>{
        e.preventDefault();
        try {
            const resp = await editBrand(input).unwrap()
            handleCloseEditModal()
            swal("Success",resp.message,"success");
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors,  
                  name: err.data.errors.name,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const [editBrandImage, {isLoading: isLoadingEditImage, isSuccess: isSuccessEditImage}] = useEditBrandImageMutation()

    const handleSubmitEditImage = async(e)=>{
      e.preventDefault();
      try {
          const resp = await editBrandImage(input).unwrap()
          handleCloseEditImageModal()
          swal("Success",resp.message,"success");
      } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
              setErrors({...errors,  
                name: err.data.errors.name,
              })
          }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
          }
      }           
  }

    const [deleteBrand, {isLoading: isLoadingDelete, isSuccess: isSuccessDelete}] = useDeleteBrandMutation()

    const handleDelete = async(e, id)=>{
      if(window.confirm("Are you sure you want to delete this item?")){
        try {
            const resp = await deleteBrand(id).unwrap()
            swal("Success",resp.message,"success");
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
      }
    }

    const [getBrandByIdMutation, {isLoading: isLoadingGetCategoryById, isSuccess: isSuccessGetCategoryById}] = useGetBrandByIdMutationMutation()

    const handleEdit = async(e, id)=>{
      try {
          const resp = await getBrandByIdMutation(id).unwrap()

          setInput({...input,  
            name: resp.data.name,
            id: resp.data.id,
          })
          setOpenEditModal(true)
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
      }
    }

    const handleEditImage = async(e, id)=>{
      try {
          const resp = await getBrandByIdMutation(id).unwrap()

          setInput({...input,  
            brand_image: [],
            id: resp.data.id,
          })
          setOpenEditImageModal(true)
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
      }
    }

    return (
        <main className='w-full flex-grow'>
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                            Create Brand
                        </p>
                        <form autoComplete='off'>
                        <div class="p-6">
                            <label className="block text-sm text-gray-600" for="name">Name</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="name" 
                              id="name" 
                              value={input.name}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                        </div>
                        <div class="p-6">
                              <label className="block text-sm text-gray-600" for="name">Brand Logo</label>
                              <FileUploader
                                multiple={true}
                                handleChange={handleFileChange}
                                name="file"
                                types={fileTypes}
                              />
                              <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={handleCloseModal}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <Transition.Root show={openEditModal} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenEditModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                            Edit Brand
                        </p>
                        <form autoComplete='off'>
                        <div class="p-6">
                            <label className="block text-sm text-gray-600" for="name">Name</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="name" 
                              id="name" 
                              value={input.name}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                            onClick={handleSubmitEdit}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={handleCloseEditModal}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <Transition.Root show={openEditImageModal} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenEditImageModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                            Edit Brand Image
                        </p>
                        <form autoComplete='off'>
                        <div class="p-6">
                              <label className="block text-sm text-gray-600" for="name">Brand Logo</label>
                              <FileUploader
                                multiple={true}
                                handleChange={handleFileChange}
                                name="file"
                                types={fileTypes}
                              />
                              <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                            onClick={handleSubmitEditImage}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={handleCloseEditImageModal}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <div className='flex items-center justify-between'>
            <div className="w-full flex-grow">
                <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                    <h1 className="text-lg font-semibold">Brands</h1>
                </div>
            </div>
            </div>
            {
              (isSuccessBrands)?
                  <div className='flex justify-center'>
                    <div className="w-full lg:w-2/2 mt-4 p-4 rounded-md">
                      <div className="bg-white p-4 rounded-md">
                        <button
                          type="button"
                          class="py-2 px-4 sm:ml-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                          onClick={() => setOpen(true)}
                        >
                          Add Brand
                        </button>
                        <section class="text-gray-600 body-font">
                            <div class="container px-5 py-10 mx-auto">
                              <div class="flex flex-wrap -m-4">
                                {
                                  (brands.data.length > 0)?
                                  brands.data.map(data => (
                                      <div class="p-4 md:w-1/3">
                                        <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                          <img 
                                              className="object-fill h-60 w-full rounded-t-md shodow-lg group-hover:opacity-50"
                                              src={`https://api.autozedltd.com/uploads/`+data.brand_logo} 
                                              alt="Image" 
                                          />
                                          <div class="p-4">
                                            <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">Brand Name</h2>
                                            <h1 class="title-font text-lg font-semibold text-gray-900 mb-3">{data.name}</h1>
                                            <div class="flex items-center flex-wrap ">
                                            <div className="">
                                                <div className='flex items-center space-x-3'>
                                                  <div onClick={(e)=>handleEdit(e,data.id)} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Edit</div>
                                                  <div onClick={(e)=>handleEditImage(e,data.id)} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Edit Image</div>
                                                  <div onClick={(e)=>handleDelete(e,data.id)} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Delete</div>
                                                  <Link to={`/admin/brand-models/${data.id}`} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Models</Link>
                                                </div>
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  ))
                                  :null
                                }
                              </div>
                            </div>
                          </section>
                      </div>
                    </div>
                  </div>
                :(isLoadingBrands)?
                  <LoadingSpinner />
                :(isErrorBrands)?
                <div className='flex justify-center'>
                  <div className="w-full lg:w-2/2 mt-4 p-4 rounded-md">
                    <div className="bg-white p-4 rounded-md">
                      <button
                        type="button"
                        class="py-2 px-4 sm:ml-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                        onClick={() => setOpen(true)}
                      >
                        Add Brand
                      </button>
                      <p class="text-md sm:ml-4 mt-2 mb-2 bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                            <i class=""></i> No brands found
                        </p>
                      <section class="text-gray-600 body-font">
                          <div class="container px-5 py-10 mx-auto">
                            <div class="flex flex-wrap -m-4">
                              
                            </div>
                          </div>
                        </section>
                    </div>
                  </div>
              </div>
              :null
            }
        </main>
    )
}

export default AdminUserBrandsView;
