import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import swal from 'sweetalert'
import { logOut, setCredentials } from '../../Features/auth/authSlice'

const baseQuery = fetchBaseQuery({
    reducerPath: 'apiSlice',
    baseUrl: 'https://api.autozedltd.com',
    // http://localhost:8000
    // https://api.autozedltd.com
    // credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    
    if(result.error){
        if (result?.error?.originalStatus === 401){
            api.dispatch(logOut())
            return swal("SESSION EXPIRED",result.error.data.error,"error")
        }else if (result?.error?.status === 403){
            api.dispatch(logOut())
            return swal("ACCESS DENIED",result.error.data.error,"error")
        }
    }

    // if(result.meta.response === 200){
    //     // store the new token 
    //     const newToken = await result.data.token;
    //     console.log(newToken)
    //     const user = api.getState().auth.user
    //     const roles = api.getState().auth.roles
    //     const client_role = api.getState().auth.client_role
    //     api.dispatch(setCredentials({user, newToken, roles, client_role}))
    // }
    
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Roles',
        'Client_Roles', 
        'User',
        "Clients",
        "AgentUser",
        ///
        "categories",
        "products",
        'brands',
        "top_category",
        "Locations",
        "booking-locations",
        "Sales",
        "bookings",
        "brand-models",
        "settings",
    ],
    endpoints: builder => ({})
})