
import { Link, useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useGetClientByIdQuery, useToggleClientStatusMutation } from '../../Features/clientsSlice';
import { useDeleteSystemRoleMutation } from '../../Features/rolesSlice';
import LoadingSpinner from '../LoadingSpinner';

function MobileAppCheckoutLandingPageView(props) {
    const navigate = useNavigate();
   
        return (
            <div className="bg-white h-screen flex flex-col flex-1 justify-center items-center">
                <div className="flex items-center justify-center bg-white p-1">
                    <h1 className="text-sm text-gray-800 p-2">Comtinue to checkout</h1>
                </div>
            </div>
        )
}

export default MobileAppCheckoutLandingPageView;
