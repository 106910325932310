import { Menu } from "@headlessui/react";
import { HeartIcon, MenuIcon } from "@heroicons/react/solid";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { useGetAllCategoriesQuery } from "../Features/categoriesSlice";

import { useRecoilState } from 'recoil';
import { CartState } from "../CartState";
import { useGetSettingsQuery } from "../Features/settingsSlice";

import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import swal from 'sweetalert';
import LoadingSpinner from "../Views/LoadingSpinner";
import { useGetAllBrandsQuery, useGetBrandModelByIdMutationMutation, useGetBrandModelsMutationMutation } from "../Features/brandsSlice";
import { useGetAllBookinLocationsQuery, useGetBookinLocationByIdMutationMutation } from "../Features/bookingLocationsSlice";
import { useCreateBookingMutation, useValidateBookingMutation } from "../Features/bookingsSlice";


function Home() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  const [openCheckoutModal, setOpenCheckoutModal] = useState(false)
  const [openServiceChargeCheckoutModal, setOpenServiceChargeCheckoutModal] = useState(false)
  const [openTransportChargeCheckoutModal, setOpenTransportChargeCheckoutModal] = useState(false)
  const [serviceType, setServiceType] = useState(null);
  const [workingLocation, setWorkingLocation] = useState(null);
  const [serviceAndTransportTotal, setServiceAndTransportTotal] = useState(null);
  const [transportCharge, setTransportCharge] = useState(null);
  const [brandModels, setBrandModels] = useState(false)
  const [brandModel, setBrandModel] = useState(false)
  const [allowSubmit, setAllowSubmit] = useState(false);
  const cancelButtonRef = useRef(null)

  const [cartItem] = useRecoilState(CartState)

  const {
    data: settings,
    isLoading: isLoadingSettings,
    isSuccess: isSuccessSettings,
    isError: isErrorSettings,
    error: errorTSettings
  } = useGetSettingsQuery()

  const {
    data: categories,
    isLoading: isLoadingCategories,
    isSuccess: isSuccessCategories,
    isError: isErrorCategories,
    error: errorCategories
  } = useGetAllCategoriesQuery()

  //Booking

  const [input, setInput] = useState({
    name: '',
    phone: '',
    address: '',
    email: '',
    instructions: '',
    brand_id: '',
    brand_model_id: '',
    service_type: '',
    service_charge: '',
    working_location: '',
    off_site_location: '',
    payment_method:'',
    phone_number:'',
    date: '',
    search: '',
    transaction_reference: '',
    paymentReference: '',
    mobile: '',
    first_name: '',
    last_name: '',
  })

  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    address: '',
    email: '',
    instructions: '',
    brand_id: '',
    brand_model_id: '',
    service_type: '',
    service_charge: '',
    working_location: '',
    off_site_location: '',
    payment_method:'',
    phone_number:'',
    date: '',
  })
    
  const handleChange = (e)=>{
      setInput({...input, [e.target.name]: e.target.value})
  }

  const {
    data: brands,
    isLoading: isLoadingBrands,
    isSuccess: isSuccessBrands,
    isError: isErrorBrands,
    error: errorBrands
  } = useGetAllBrandsQuery()

  const {
    data: locations,
    isLoading: isLoadingLocations,
    isSuccess: isSuccessLocations,
    isError: isErrorLocations,
    error: errorLocations
  } = useGetAllBookinLocationsQuery()

  useEffect(()=>{
    setInput({...input,  
      paymentReference: refId(),
    })
    return () =>{
    }
  },[]);

  const serviceChargeOnlyTotal = ()=>{
    if(serviceType == '1'){
      return brandModel.data.full_service_charge 
    }else if(serviceType == '2'){
      return brandModel.data.partial_service_charge 
    }else if(serviceType == '3'){
      return brandModel.data.custom_service_charge; 
    }
  }

  const [validateBooking] = useValidateBookingMutation()

  const handlePaymentMethod = async(e)=>{
    e.preventDefault();
    try {
      if(input.working_location == '2' && !input.off_site_location){
        return swal("Error",'Choose location',"error");
      }

        const resp = await validateBooking(input).unwrap();
        if(input.working_location == '2' && input.off_site_location && input.payment_method == '2'){
          //Service and transport charge
          setOpen(false)
          return setOpenCheckoutModal(true)
        }else if(input.working_location == '1' && input.payment_method == '2'){
          //Service charge only
          setOpen(false)
          return setOpenServiceChargeCheckoutModal(true)
        }else if(input.working_location == '2' && input.payment_method == '1'){
          //Transport charge only
          setOpen(false)
          return setOpenTransportChargeCheckoutModal(true)
        }
        handleSubmit()
        return handleCloseModal()
    } catch (err) {
        if(err.data.hasOwnProperty('errors') == true){
            setErrors({...errors,  
              name: err.data.errors.name,
              phone: err.data.errors.phone,
              address: err.data.errors.address,
              email: err.data.errors.email,
              instructions: err.data.errors.instructions,
              date: err.data.errors.date,
              service_type: err.data.errors.service_type,
              service_charge: err.data.errors.service_charge,
              working_location: err.data.errors.working_location,
              off_site_location: err.data.errors.off_site_location,
              payment_method: err.data.errors.payment_method,
              brand_id: err.data.errors.brand_id,
              brand_model_id: err.data.errors.brand_model_id,
            })
        }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
        }
    }           
}

const handleSubmitTransportAndServiceCharge = async(e)=>{
  e.preventDefault();
  try {
    return handleSubmit()
  } catch (err) {
      swal("Error",err.data.error,"error");
  }           
}

const handleSubmitTransportCharge = async(e)=>{
  e.preventDefault();
  try {
    return handleSubmit()
  } catch (err) {
      swal("Error",err.data.error,"error");
  }           
}

const handleSubmitServiceCharge = async(e)=>{
  e.preventDefault();
  try {
      return handleSubmit()
  } catch (err) {
      swal("Error",err.data.error,"error");
  }           
}

const [createBooking, {isLoading, isSuccess, isError}] = useCreateBookingMutation()

  const handleSubmit = async(e)=>{
      try {
        const resp = await createBooking(input).unwrap()

        if(input.working_location == '2' && input.off_site_location && input.payment_method == '2'){
          //Service and transport charge
          setAllowSubmit(true)
          return document.getElementById('service_and_transport_payment_form').submit();
        }else if(input.working_location == '1' && input.payment_method == '2'){
          //Service charge only
          setAllowSubmit(true)
          return document.getElementById('service_payment_form').submit();
        }else if(input.working_location == '2' && input.payment_method == '1'){
          //Transport charge only
          setAllowSubmit(true)
          return document.getElementById('transport_payment_form').submit();
        }

        navigate('/booking-on-site-receipt/'+resp.data.id)
        handleCloseCheckoutModal()
        handleCloseServiceChargeCheckoutModal()
        handleCloseTransportChargeCheckoutModal()
        swal(resp.message);
      } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
              setErrors({...errors,  
                name: err.data.errors.name,
                phone: err.data.errors.phone,
                address: err.data.errors.address,
                email: err.data.errors.email,
                instructions: err.data.errors.instructions,
                date: err.data.errors.date,
                service_type: err.data.errors.service_type,
                service_charge: err.data.errors.service_charge,
                working_location: err.data.errors.working_location,
                off_site_location: err.data.errors.off_site_location,
                payment_method: err.data.errors.payment_method,
                brand_id: err.data.errors.brand_id,
                brand_model_id: err.data.errors.brand_model_id,
              })
          }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
          }
      }           
  }

  const [getBrandModelByIdMutation] = useGetBrandModelByIdMutationMutation()

  const handleGetBrandModelById = async(e, id)=>{
    const value = e.target.value
    setInput({...input, [e.target.name]: value})
    try {
        const resp = await getBrandModelByIdMutation(value).unwrap()
        setBrandModel(resp)
      } catch (err) {
        if(err.data.hasOwnProperty('errors') == true){
          swal("Error",err.data.error,"error");
        }else if (err.data.hasOwnProperty('error') == true){
          swal("Error",err.data.error,"error");
        }
    }
  }

  const [getBrandModelsMutation] = useGetBrandModelsMutationMutation()

  const handleBrandChange = async(e)=>{
    const value = e.target.value
    setInput({...input, [e.target.name]: value})
    try {
      const resp = await getBrandModelsMutation(value).unwrap()
      setBrandModels(resp)
    } catch (err) {
      if(err.data.hasOwnProperty('errors') == true){
        setBrandModels(false)
        swal("Error","No vehicle models found","error");
      }else if (err.data.hasOwnProperty('error') == true){
        setBrandModels(false)
        swal("Error","No vehicle models found","error");
      }
  }
  }

  const handleCloseModal = ()=>{
    setInput({...input,  
      name: '',
      phone: '',
      address: '',
      email: '',
      instructions: '',
      brand_id: '',
      brand_model_id: '',
      service_type: '',
      service_charge: '',
      working_location: '',
      off_site_location: '',
      payment_method:'',
      phone_number:'',
      date: '',
    })

    setErrors({...errors,  
      name: '',
      phone: '',
      address: '',
      email: '',
      instructions: '',
      brand_id: '',
      brand_model_id: '',
      service_type: '',
      service_charge: '',
      working_location: '',
      off_site_location: '',
      payment_method:'',
      phone_number:'',
      date: '',
    })
    setOpen(false)
  }

  const handleKeepDataCloseModal = ()=>{
    setOpen(false)
  }

  const handleCloseCheckoutModal = (e)=>{
    handleCloseModal()
    setOpenCheckoutModal(false)
  }
  
  const handleCloseServiceChargeCheckoutModal = (e)=>{
    handleCloseModal()
    setOpenServiceChargeCheckoutModal(false)
  }
  
  const handleCloseTransportChargeCheckoutModal = (e)=>{
    handleCloseModal()
    setOpenTransportChargeCheckoutModal(false)
  }

  const handleServiceTypeChange = async(e)=>{
    const { value, checked } = e.target;
    (checked)? setServiceType(value) : setServiceType(false)
    setInput({...input,  
      service_type: value,
      service_charge: (value == '1')? brandModel.data.full_service_charge :(value == '2')? brandModel.data.partial_service_charge :(value == '3')? brandModel.data.custom_service_charge : null,
    })
    
  }

  const [getBookinLocationByIdMutation] = useGetBookinLocationByIdMutationMutation()

  const handleWorkingLocationChange = async(e)=>{
    const { value, checked } = e.target;
    (checked)? setWorkingLocation(value) : setWorkingLocation(false)
      setInput({...input,  
        working_location: value,
      })  
  }

  const handleOffSiteLocationChange = async(e)=>{
    const { value, checked } = e.target;
    (checked)? setWorkingLocation(value) : setWorkingLocation(false)
    try {
      const resp = await getBookinLocationByIdMutation(value).unwrap();
      if(serviceType == '1'){
        setServiceAndTransportTotal(parseInt(brandModel.data.full_service_charge) + resp.data.charge)
      }else if(serviceType == '2'){
        setServiceAndTransportTotal((parseInt(brandModel.data.partial_service_charge) + resp.data.charge))
      }else if(serviceType == '3'){
        setServiceAndTransportTotal((parseInt(brandModel.data.custom_service_charge) + resp.data.charge))
      }
      setTransportCharge(resp.data.charge)

      setInput({...input,  
        off_site_location: value,
      })
    } catch (error) {
      swal("Error",error.data.message,"error");
    }
    
  }

  const refId = (length) => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

    return (
      <main  className="">
         <Transition.Root show={openCheckoutModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseCheckoutModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                        Provide Checkout Details
                    </p>
                    <form 
                      id="service_and_transport_payment_form" 
                      action={allowSubmit ? 'https://paymentservices.probasegroup.com/pbs/payments' : ''} 
                      method="post" 
                      autoComplete='off'
                      rel="noopener noreferrer"
                    >
                    <div class="pl-6 pr-6 pb-2 pt-4 flex justify-end items-center">
                        <div className="flex flex-col gap-y-2">
                          <div className="flex items-center justify-end">
                            <p className="mr-4 text-gray-600">Service Charge:</p>
                            <p className="text-gray-700">K{serviceChargeOnlyTotal()}</p>
                          </div>
                          <div className="flex items-center justify-end">
                            <p className="mr-4 text-gray-600">Transport Charge:</p>
                            <p className="text-gray-700">K{(transportCharge)? transportCharge : '00.00'}</p>
                          </div>
                          <div className="flex items-center justify-end">
                            <p className="font-bold mr-4">Total:</p>
                            <p className="text-gray-700 font-semibold">K{serviceAndTransportTotal}</p>
                          </div>
                        </div>
                    </div>
                    <div class="pl-6 pr-6 pb-2 pt-4">
                            <label className="block text-sm text-gray-600" for="phone">Phone number</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="number"
                              placeholder=''
                              name="mobile" 
                              id="mobile" 
                              value={input.mobile}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.mobile}</p>
                        </div>
                        <div class="pl-6 pr-6 pb-2">
                            <label className="block text-sm text-gray-600" for="fname">First Name</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="text"
                              placeholder=''
                              name="first_name" 
                              id="first_name" 
                              value={input.first_name}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.first_name}</p>
                        </div>
                        <div class="pl-6 pr-6">
                            <label className="block text-sm text-gray-600" for="lname">Last Name</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="text"
                              placeholder=''
                              name="last_name" 
                              id="last_name" 
                              value={input.last_name}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.last_name}</p>
                        </div>
                        <input type="hidden" name="responseMethod" value="GET" /> 
                        <input type="hidden" name="sourceInstitution" value="Autozedltd" /> 
                        <input type="hidden" id="external_ref"  name="paymentReference" onChange={handleChange} value={input.paymentReference} />
                        <input type="hidden"  id="redirectUrl"  name="redirectUrl" value="https://autozedltd.com/booking-off-site-receipt/" /> 
                        <input type="hidden" id="amount"  name="amount" value={serviceAndTransportTotal}/> 
                        <input type="hidden"  id="paymentType"  name="paymentType" value="Booking service" />
                        <input type="hidden"  id="customerType"  name="customerType" value="cooperate" />
                        <input type="hidden" id="company_name" name="companyName" value="Autozed" />
                        <input type="hidden" id="description"  name="paymentDescription" value="Booking service"/>
                        <input type="hidden" id="tpin"  name="tpin" value="98765434554" />
                        <input type="hidden" id="system_id" name="systemId" value="AutoZed-102300010" />
                        <input type="hidden" id="email" name="email" value="admin@autozedltd.com" />
                        <input type="hidden"  name="password" value="Njz-rQ4{FvX7hnUwcaTE"/>

                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-rose-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-600 sm:ml-3 sm:w-auto"
                        onClick={handleSubmitTransportAndServiceCharge}
                      >
                        Checkout
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={handleCloseCheckoutModal}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openServiceChargeCheckoutModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseServiceChargeCheckoutModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                        Provide Checkout Details
                    </p>
                    <form 
                      id="service_payment_form" 
                      action={allowSubmit ? 'https://paymentservices.probasegroup.com/pbs/payments' : ''} 
                      method="post" 
                      autoComplete='off'
                      rel="noopener noreferrer"
                    >
                    <div class="pl-6 pr-6 pb-2 pt-4 flex justify-end items-center">
                        <div className="flex flex-col gap-y-2">
                          <div className="flex items-center justify-end">
                            <p className="mr-4 text-gray-600">Service Charge:</p>
                            <p className="text-gray-700">K{serviceChargeOnlyTotal()}</p>
                          </div>
                          <div className="flex items-center justify-end">
                            <p className="font-bold mr-4">Total:</p>
                            <p className="text-gray-700 font-semibold">K{serviceChargeOnlyTotal()}</p>
                          </div>
                        </div>
                    </div>
                    <div class="pl-6 pr-6 pb-2 pt-4">
                            <label className="block text-sm text-gray-600" for="phone">Phone number</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="number"
                              placeholder=''
                              name="mobile" 
                              id="mobile" 
                              value={input.mobile}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.mobile}</p>
                        </div>
                        <div class="pl-6 pr-6 pb-2">
                            <label className="block text-sm text-gray-600" for="fname">First Name</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="text"
                              placeholder=''
                              name="first_name" 
                              id="first_name" 
                              value={input.first_name}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.first_name}</p>
                        </div>
                        <div class="pl-6 pr-6">
                            <label className="block text-sm text-gray-600" for="lname">Last Name</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="text"
                              placeholder=''
                              name="last_name" 
                              id="last_name" 
                              value={input.last_name}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.last_name}</p>
                        </div>
                        <input type="hidden" name="responseMethod" value="GET" />
                        <input type="hidden" name="sourceInstitution" value="Autozedltd" /> 
                        <input type="hidden" id="external_ref"  name="paymentReference" onChange={handleChange} value={input.paymentReference} />
                        <input type="hidden"  id="redirectUrl"  name="redirectUrl" value="https://autozedltd.com/booking-service-charge-receipt/" /> 
                        <input type="hidden" id="amount"  name="amount" value={serviceChargeOnlyTotal()}/> 
                        <input type="hidden"  id="paymentType"  name="paymentType" value="Booking service" />
                        <input type="hidden"  id="customerType"  name="customerType" value="cooperate" />
                        <input type="hidden" id="company_name" name="companyName" value="Autozed" />
                        <input type="hidden" id="description"  name="paymentDescription" value="Booking service"/>
                        <input type="hidden" id="tpin"  name="tpin" value="98765434554" />
                        <input type="hidden" id="system_id" name="systemId" value="AutoZed-102300010" />
                        <input type="hidden" id="email" name="email" value="admin@autozedltd.com" />
                        <input type="hidden"  name="password" value="Njz-rQ4{FvX7hnUwcaTE"/>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-rose-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-600 sm:ml-3 sm:w-auto"
                        onClick={handleSubmitServiceCharge}
                      >
                        Checkout
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={handleCloseServiceChargeCheckoutModal}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openTransportChargeCheckoutModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseTransportChargeCheckoutModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                        Provide Checkout Details
                    </p>
                    <form 
                      id="transport_payment_form" 
                      action={allowSubmit ? 'https://paymentservices.probasegroup.com/pbs/payments' : ''} 
                      method="post" 
                      autoComplete='off'
                      rel="noopener noreferrer"
                    >
                    <div class="pl-6 pr-6 pb-2 pt-4 flex justify-end items-center">
                        <div className="flex flex-col gap-y-2">
                          <div className="flex items-center justify-end">
                            <p className="mr-4 text-gray-600">Transport Charge:</p>
                            <p className="text-gray-700">K{(transportCharge)? transportCharge : '00.00'}</p>
                          </div>
                          <div className="flex items-center justify-end">
                            <p className="font-bold mr-4">Total:</p>
                            <p className="text-gray-700 font-semibold">K{(transportCharge)? transportCharge : '00.00'}</p>
                          </div>
                        </div>
                    </div>
                    <div class="pl-6 pr-6 pb-2 pt-4">
                            <label className="block text-sm text-gray-600" for="phone">Phone number</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="number"
                              placeholder=''
                              name="mobile" 
                              id="mobile" 
                              value={input.mobile}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.mobile}</p>
                        </div>
                        <div class="pl-6 pr-6 pb-2">
                            <label className="block text-sm text-gray-600" for="fname">First Name</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="text"
                              placeholder=''
                              name="first_name" 
                              id="first_name" 
                              value={input.first_name}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.first_name}</p>
                        </div>
                        <div class="pl-6 pr-6">
                            <label className="block text-sm text-gray-600" for="lname">Last Name</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="text"
                              placeholder=''
                              name="last_name" 
                              id="last_name" 
                              value={input.last_name}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.last_name}</p>
                        </div>
                        <input type="hidden" name="responseMethod" value="GET" />
                        <input type="hidden" name="sourceInstitution" value="Autozedltd" /> 
                        <input type="hidden" id="external_ref"  name="paymentReference" onChange={handleChange} value={input.paymentReference} />
                        <input type="hidden"  id="redirectUrl"  name="redirectUrl" value="https://autozedltd.com/booking-transport-charge-receipt/" /> 
                        <input type="hidden" id="amount"  name="amount" value={transportCharge}/> 
                        <input type="hidden"  id="paymentType"  name="paymentType" value="Purchase of goods" />
                        <input type="hidden"  id="customerType"  name="customerType" value="cooperate" />
                        <input type="hidden" id="company_name" name="companyName" value="Autozed" />
                        <input type="hidden" id="description"  name="paymentDescription" value="Bookin service"/>
                        <input type="hidden" id="tpin"  name="tpin" value="98765434554" />
                        <input type="hidden" id="system_id" name="systemId" value="AutoZed-102300010" />
                        <input type="hidden" id="email" name="email" value="admin@autozedltd.com" />
                        <input type="hidden"  name="password" value="Njz-rQ4{FvX7hnUwcaTE"/>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-rose-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-600 sm:ml-3 sm:w-auto"
                        onClick={handleSubmitTransportCharge}
                      >
                        Checkout
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={handleCloseTransportChargeCheckoutModal}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={(openCheckoutModal || openServiceChargeCheckoutModal || openTransportChargeCheckoutModal)? handleKeepDataCloseModal : handleCloseModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative md:w-3/4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                    {
                        (isSuccessBrands)?
                            (isSuccessLocations)?
                                <div style={{backgroundImage: `url(https://demoapi.autozedltd.com/uploads/h3_BG.jpg)`, backgroundSize: 'cover', backgroundRepeat  : 'no-repeat'}} className='flex justify-center p-10'>
                                <div className="w-full lg:w-2/2 mt-1">
                                    <div  className=" flex justify-center rounded-md p-4">
                                    <form onSubmit={handlePaymentMethod} autoComplete="off">
                                    <div class="space-y-12">
                                        <div class="pb-12">
                                        <h2 class="flex justify-center uppercase font-bold text-xl leading-7 text-white">Book A Home Vehicle Servicing</h2>
                                        <div class="mt-10 grid grid-cols-1 gap-x-16 gap-y-6 sm:grid-cols-6">
                                            <div class="sm:col-span-3">
                                            <div class="mt-2">
                                                <input 
                                                type="text" 
                                                name="name" 
                                                id="name" 
                                                placeholder="Full name"
                                                value={input.name} 
                                                onChange={handleChange} 
                                                class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-rose-600 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" 
                                                />
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                                            </div>
                                            </div>

                                            <div class="sm:col-span-3">
                                            <div class="mt-2">
                                                <input 
                                                type="number" 
                                                name="phone" 
                                                id="phone" 
                                                placeholder="Your Phone Number"
                                                value={input.phone} 
                                                onChange={handleChange} 
                                                class="block w-full rounded-md border-0 focus:ring-rose-600 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" 
                                                />
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.phone}</p>
                                            </div>
                                            </div>

                                            <div class="sm:col-span-4">
                                            <div class="mt-2">
                                                <input 
                                                type="text" 
                                                name="address" 
                                                id="address" 
                                                placeholder="Home Address"
                                                value={input.address} 
                                                onChange={handleChange} 
                                                class="block w-full rounded-md border-0 focus:ring-rose-600 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.address}</p>
                                            </div>
                                            </div>

                                            <div class="sm:col-span-2">
                                            <div class="mt-2">
                                                <input 
                                                type="date" 
                                                name="date" 
                                                id="date" 
                                                placeholder="Date Of Servicing"
                                                value={input.date} 
                                                onChange={handleChange}  
                                                class="block w-full rounded-md border-0 focus:ring-rose-600 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.date}</p>
                                            </div>
                                            </div>

                                            <div class="sm:col-span-4">
                                            <div class="mt-2">
                                                <input 
                                                type="email" 
                                                name="email" 
                                                id="email" 
                                                placeholder="Email Address"
                                                value={input.email} 
                                                onChange={handleChange} 
                                                class="block w-full rounded-md border-0 focus:ring-rose-600 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.email}</p>
                                            </div>
                                            </div>

                                            <div class="sm:col-span-3">
                                            <div class="mt-2">
                                                <select id="brand_id" value={input.brand_id} onChange={handleBrandChange} name="brand_id" class="block w-full rounded-md focus:ring-rose-600 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option>Vehicle Make</option>
                                                {
                                                    (brands.data.length > 0)?
                                                    brands.data.map(data => (
                                                        <option value={data.id}>{data.name}</option>
                                                    ))
                                                    :null
                                                }
                                                </select>
                                            </div>
                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.brand_id}</p>
                                            </div>
                                            <div class="sm:col-span-3">
                                            <div class="mt-2 ">
                                                <select id="brand_model_id" value={input.brand_model_id} onChange={handleGetBrandModelById} name="brand_model_id" class="block w-full rounded-md border-0 py-1.5 focus:ring-rose-600 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option>Vehicle Model</option>
                                                {
                                                    (brandModels)?
                                                    (brandModels.data.length > 0)?
                                                    brandModels.data.map(data => (
                                                        <option value={data.id}>{data.model}</option>
                                                        ))
                                                    :null
                                                    :null
                                                }
                                                </select>
                                            </div>
                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.brand_model_id}</p>
                                            </div>
                                            {
                                            (input.brand_model_id && brandModel)?
                                                <div class="sm:col-span-3">
                                                    <div className={``}>
                                                    <div class="relative flex gap-x-3">
                                                        <div class="flex h-6 items-center text-white">
                                                        Service Type
                                                        </div>
                                                        <div className='flex flex-col'>
                                                        <div class="text-sm leading-6 flex items-center space-x-2">
                                                            <label for="is_latest" class="font-medium text-gray-900">
                                                            <input id="is_latest" value="1" onChange={handleServiceTypeChange} name="service_type" type="radio" class="h-4 w-4 rounded border-gray-300 text-rose-600 focus:ring-rose-600" />
                                                            </label>
                                                            <p class="text-white">Full Servicing K{brandModel.data.full_service_charge}</p>
                                                        </div>
                                                        <div class="text-sm leading-6 flex items-center space-x-2">
                                                            <label for="is_latest" class="font-medium text-gray-900">
                                                            <input id="is_latest" value="2" onChange={handleServiceTypeChange} name="service_type" type="radio" class="h-4 w-4 rounded border-gray-300 text-rose-600 focus:ring-rose-600" />
                                                            </label>
                                                            <p class="text-white">Partial Servicingv K{brandModel.data.partial_service_charge}</p>
                                                        </div>
                                                        <div class="text-sm leading-6 flex items-center space-x-2">
                                                            <label for="is_latest" class="font-medium text-gray-900">
                                                            <input id="is_latest" value="3" onChange={handleServiceTypeChange} name="service_type" type="radio" class="h-4 w-4 rounded border-gray-300 text-rose-600 focus:ring-rose-600" />
                                                            </label>
                                                            <p class="text-white">Custom Servicing K{brandModel.data.custom_service_charge}</p>
                                                        </div>
                                                        <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.service_type}</p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            :null
                                            }

                                            {
                                            (input.brand_model_id && brandModel)?
                                                <div class="sm:col-span-3">
                                                    <div className={``}>
                                                    <div class="relative flex gap-x-3">
                                                        <div class="flex h-6 items-center text-white">
                                                        Working Location
                                                        </div>
                                                        <div className='flex flex-col'>
                                                        <div class="text-sm leading-6 flex items-center space-x-2">
                                                            <label for="working_location" class="font-medium text-gray-900">
                                                            <input id="working_location" value="1" onChange={handleWorkingLocationChange} name="working_location" type="radio" class="h-4 w-4 rounded border-gray-300 text-rose-600 focus:ring-rose-600" />
                                                            </label>
                                                            <p class="text-white">Our Place</p>
                                                        </div>
                                                        <div class="text-sm leading-6 flex items-center space-x-2">
                                                            <label for="working_location" class="font-medium text-gray-900">
                                                            <input id="working_location" value="2" onChange={handleWorkingLocationChange} name="working_location" type="radio" class="h-4 w-4 rounded border-gray-300 text-rose-600 focus:ring-rose-600" />
                                                            </label>
                                                            <p class="text-white">Your Place</p>
                                                        </div>
                                                        <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.working_location}</p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            :null
                                            }

                                            {
                                            (input.working_location && input.working_location == '2')?
                                                <div class="sm:col-span-3">
                                                <label for="off_site_location" class="block text-sm font-medium leading-6 text-white">Transport Fee</label>
                                                <div class="mt-2">
                                                    <select id="off_site_location" value={input.off_site_location} onChange={handleOffSiteLocationChange} name="off_site_location" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-rose-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option>Select location</option>
                                                    {
                                                        (locations.data.length > 0)?
                                                        locations.data.map(data => (
                                                            <option value={data.id}>{data.name} K{data.charge}</option>
                                                        ))
                                                        :null
                                                    }
                                                    </select>
                                                </div>
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.off_site_location}</p>
                                                </div>
                                            :null
                                            }

                                            {
                                            (input.working_location)?
                                                <div class="sm:col-span-3">
                                                <label for="payment_method" class="block text-sm font-medium leading-6 text-white">Payment Method</label>
                                                <div class="mt-2">
                                                    <select id="payment_method" value={input.payment_method} onChange={handleChange} name="payment_method" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-rose-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option>Select method</option>
                                                    <option value="1">Cash payment</option>
                                                    <option value="2">Online payment</option>
                                                    </select>
                                                </div>
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.payment_method}</p>
                                                </div>
                                            :null
                                            }

                                            {
                                            (input.working_location)?
                                              <div class="col-span-full">
                                                <div class="mt-2">
                                                    <textarea id="instructions" value={input.instructions} onChange={handleChange} name="instructions" rows="3" placeholder='Instructions...' class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-rose-600 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"></textarea>
                                                </div>
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.instructions}</p>
                                              </div>
                                            :null
                                            }



                                        </div>
                                        </div>
                                    </div>

                                    <div class="mt-6 flex items-center justify-end gap-x-6">
                                        <div onClick={(openCheckoutModal || openServiceChargeCheckoutModal || openTransportChargeCheckoutModal)? handleKeepDataCloseModal : handleCloseModal} class="rounded-md px-3 py-2 text-sm font-semibold text-gray-300 cursor-pointer hover:text-gray-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Cancel</div>
                                        <button type="submit" class="rounded-md bg-rose-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit</button>
                                    </div>
                                    </form>
                                    </div>
                                </div>
                                </div>
                            :isErrorLocations?
                            <div>
                                failed
                            </div>
                            :null
                            :(isLoadingBrands)?
                            <LoadingSpinner />
                            :(isErrorBrands)?
                            <div>
                                failed
                            </div>
                        :null
                        }
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <header className="bg-gray-900 text-gray-600 body-font">
          <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <div className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
              <p className="text-gray-500 text-lg">Shop for motor parts 24/7</p>
            </div>
            <nav className="md:ml-auto hidden md:flex flex-wrap items-center text-base justify-center">
              <Link to='/support/' className="mr-5 text-gray-400 font-sembold hover:text-rose-700 cursor-pointer">CONTACT US</Link>
              <div className="mr-5  text-gray-400 font-semibold hover:text-rose-700 cursor-pointer">
                <Link to='/login' className="mr-5 text-rose-700 font-semibold hover:text-rose-600 cursor-pointer">Sign in</Link>
              </div>
            </nav>
          </div>
        </header>

        <header className="bg-gray-800 text-gray-600 body-font">
          <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
              {  
                (isSuccessSettings)?
                  <Link to="/">
                    <img 
                        className="object-fill w-64 rounded-t-md shodow-lg group-hover:opacity-50"
                        src={`https://api.autozedltd.com/uploads/`+settings.data.logo_image} 
                        alt="Image" 
                    />
                  </Link> 
                :null
              }
            </a>
            <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base md:space-x-6 justify-center">
              <form>
                <div class="relative w-60 md:w-96">
                  <input 
                    type="search" 
                    class="block w-full rounded-md p-3 text-gray-900 shadow-sm ring-1 ring-inset border-none focus:ring-rose-600 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" 
                    placeholder="Search product..." 
                    name="phone_number" 
                    id="search" 
                    value={input.search}
                    onChange={handleChange}
                    required 
                  />
                  <button type="submit" class="absolute top-0 right-0 p-3 text-sm font-medium text-gray-600 bg-white rounded-r-lg border border-white hover:bg-white focus:ring-4 focus:outline-none">
                      <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                      <span class="sr-only">Search</span>
                  </button>
                </div>
              </form>
              <button onClick={() => setOpen(true)} className="inline-flex uppercase items-center bg-yellow-400 border-0 p-3 focus:outline-none hover:bg-yellow-500 rounded text-rose-700 font-semibold text-base mt-4 md:mt-0">
                  Book a car service
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </nav>
            <div className="flex items-center">
              <div className="inline-flex items-center cursor-pointer text-base mt-4 md:mt-0">
                <div className="relative text-gray-400 hover:text-gray-300">
                  <HeartIcon className='h-6'/>
                </div>
              </div>
              <Link to={`/shopping-cart`} className="inline-flex items-center border-0 py-1 px-3 focus:outline-nonerounded text-base mt-4 md:mt-0">
                <div className="relative text-gray-400 hover:text-gray-300">
                  <ShoppingCartIcon className='h-6 mr-1'/>
                  <div className="absolute -top-2 -right-2 bg-rose-600 rounded-full pl-1 pr-1 text-sm text-white">{cartItem.length}</div>
                </div>
              </Link>
            </div>
          </div>
        </header>

        <header className="bg-white shadow-md text-gray-600 body-font">
          <div className="container mx-auto flex flex-wrap p-2 flex-col md:flex-row items-center">
            <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
              <button className="inline-flex items-center border border-rose-700 focus:outline-none hover:bg-rose-600 rounded text-rose-700 font-semibold hover:text-white text-base mt-4 md:mt-0">
                <Menu as="div" className='relative'>
                  <Menu.Button className="inline-flex items-center p-3 focus:outline-none rounded text-rose-700 font-semibold hover:text-white text-base mt-4 md:mt-0">
                    <MenuIcon className="w-4 h-4 ml-1" /> Shop by category
                  </Menu.Button> 
                  <Menu.Items className="absolute z-40 focus:outline-none bg-white rounded-md shadow-lg border w-40">
                    {
                      (isSuccessCategories)?
                        categories.data.map((data)=>(
                          <Menu.Item>
                            <Link to={`/shop/${data.name}`} className='flex justify-start z-40 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-300  border-b border-gray-200'>
                            <div className="flex items-center justify-center text-gray-900 text-xs space-x-1">
                              <p className="">{data.name}</p>
                            </div>
                            </Link>
                          </Menu.Item>
                        ))
                      :null
                    }
                  </Menu.Items>
                </Menu>
              </button>
            </a>
            <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base space-x-6 justify-center">
              <button onClick={() => setOpen(true)} className="inline-flex uppercase items-center bg-yellow-400 border-0 p-3 focus:outline-none hover:bg-yellow-500 rounded text-rose-700 font-semibold text-base mt-4 md:mt-0">
                  Book a car service
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </nav>
            <div className="inline-flex items-center border-0 py-1 px-3 focus:outline-none rounded text-md mt-4 font-semibold uppercase md:mt-0">
              <Link to='/' className="mr-5 text-gray-900 font-semibold hover:text-rose-700 cursor-pointer">Home</Link>
              <Link to='/about/' className="mr-5 text-gray-900 font-semibold hover:text-rose-700 cursor-pointer">About</Link>
              <Link to='/shop/all' className="mr-5 text-gray-900 font-semibold hover:text-rose-700 cursor-pointer">Shop</Link>
              <Link to='/support/' className="mr-5 text-gray-900 font-semibold hover:text-rose-700 cursor-pointer">Support</Link>
            </div>
          </div>
        </header>
      </main>
  )
  }
  
  export default Home;

