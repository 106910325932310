import { Link, Outlet,  } from "react-router-dom";
import { CreditCardIcon, PhoneIcon, ReceiptRefundIcon, TruckIcon } from "@heroicons/react/solid";
import { useGetSettingsQuery } from "../Features/settingsSlice";


function Layout() {
  const phoneNumber = '+260770508958';
  const message  = 'hellow';

  const {
    data: settings,
    isLoading: isLoadingSettings,
    isSuccess: isSuccessSettings,
    isError: isErrorSettings,
    error: errorTSettings
  } = useGetSettingsQuery()

  const openWhatsAppChat = ()=>{
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`
    return url;
  }
  
  return (
    <main className="App bg-white">
        < Outlet />
        <div className="flex bg-gray-200 pt-5 pb-5 flex-wrap justify-center items-center">
            <div className="flex flex-wrap md:flex-nowrap md:w-1/4 justify-center items-center space-x-2 pb-1 pt-1 pl-6 pr-6 rounded-sm">
                <TruckIcon className="h-20 w-40 text-rose-600" />
                <div className="pl-6 md:block flex flex-wrap md:flex-nowrap justify-center items-center">
                    <h2 className="text-gray-800 font-bold text-lg cursor-pointer title-font">Delivery on request</h2>
                    <div className="leading-relaxed h-20 text-base text-gray-400 flex justify-center items-center">
                        Delivery of goods upon request to selected regions
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap md:flex-nowrap md:w-1/4 justify-center items-center space-x-2 pb-1 pt-1 pl-6 pr-6 rounded-sm">
                <CreditCardIcon className="h-20 w-40 text-rose-600" />
                <div className="pl-6 md:block flex flex-wrap md:flex-nowrap justify-center items-center">
                    <h2 className="text-gray-800 font-bold text-lg cursor-pointer title-font">Secure Payments</h2>
                    <div className="leading-relaxed h-20 text-base text-gray-400 flex justify-center items-center">
                    Your payment information is processed securely
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap md:flex-nowrap md:w-1/4 justify-center items-center space-x-2 pb-1 pt-1 pl-6 pr-6 rounded-sm">
                <PhoneIcon className="h-20 w-40 text-rose-600" />
                <div className="pl-6 md:block flex flex-wrap md:flex-nowrap justify-center items-center">
                    <h2 className="text-gray-800 font-bold text-lg cursor-pointer title-font">Top-Notch Support</h2>
                    <div className="leading-relaxed h-20 text-base text-gray-400 flex justify-center items-center">
                    Our live chat support is available Monday through Saturday
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap md:flex-nowrap md:w-1/4 justify-center items-center space-x-2 pb-1 pt-1 pl-6 pr-6 rounded-sm">
                <ReceiptRefundIcon className="h-20 w-40 text-rose-600" />
                <div className="pl-6 md:block flex flex-wrap md:flex-nowrap justify-center items-center">
                    <h2 className="text-gray-800 font-bold text-lg cursor-pointer title-font">Satisfied/Refunded</h2>
                    <div className="leading-relaxed h-20 text-base text-gray-400 flex justify-center items-center">
                    Guaranteed product quality and warranty
                    </div>
                </div>
            </div>
        </div>
        <footer class="text-gray-600 body-font">
          <div class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
            <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                  {  
                    (isSuccessSettings)?
                      <Link to="/">
                        <img 
                            className="object-fill w-60 h-20"
                            src={`https://api.autozedltd.com/uploads/`+settings.data.logo_image} 
                            alt="Image" 
                        />
                      </Link>
                    :null
                  }
                </a>
              <p class="mt-2 text-lg text-gray-400">Autozed  Limited endeavors to be the leading supplier and source for all Japenese  vehicle spares and accessories. And for other reputable car brands.</p>
            </div>
            <div className="flex flex-col w-full">
              <div class="flex-grow flex justify-center flex-wrap md:mt-0 mt-10 md:text-left text-center">
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                  <h2 class="title-font text-gray-400 font-bold tracking-widest text-sm mb-3 uppercase">Hotline</h2>
                  <h1 class="list-none font-bold text-lg text-gray-900">
                    +260 96 5878692
                  </h1>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                  <h2 class="title-font text-gray-400 font-bold tracking-widest text-sm mb-3 uppercase">TECHNICAL SUPPORT</h2>
                  <h1 class="list-none font-bold text-lg text-gray-900">
                    +260 96 5878692
                  </h1>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                  <h2 class="title-font text-gray-400 font-bold tracking-widest text-sm mb-3 uppercase">MON - FRI</h2>
                  <h1 class="list-none font-bold text-lg text-gray-900">
                    9:00 - 17:00
                  </h1>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 class="title-font text-rose-600 font-bold tracking-widest text-sm mb-3 uppercase"></h2>
                  <h1 class="list-none mb-10">
                  <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 space-x-1 justify-center sm:justify-start">
                    <div onClick={()=> {window.open('https://www.facebook.com/profile.php?id=100063636691699','_blank')}} rel="noopener noreferrer" class="text-white bg-gray-900 p-3 rounded-full hover:bg-white hover:text-orange-500">
                      <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                      </svg>
                    </div>
                    <div onClick={()=> {window.open('https://twitter.com/AUTOZED1695985','_blank')}} rel="noopener noreferrer" class="text-white bg-gray-900 p-3 rounded-full hover:bg-white hover:text-orange-500">
                      <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                      </svg>
                    </div>
                    <div onClick={()=> {window.open('https://www.instagram.com/autozedltd','_blank')}} rel="noopener noreferrer" class="text-white bg-gray-900 p-3 rounded-full hover:bg-white hover:text-orange-500">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                        <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                      </svg>
                    </div>
                  </span>
                  </h1>
                </div>
              </div>
              <div class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                  <h2 class="title-font text-rose-600 font-bold tracking-widest text-sm mb-3 uppercase">About Us</h2>
                  <nav class="list-none flex flex-col mb-10">
                    <Link to='/'>
                      <a class="text-gray-400 hover:text-rose-600 text-lg">Home</a>
                    </Link>
                    <Link to='/about'>
                      <a class="text-gray-400 hover:text-rose-600 text-lg">About</a>
                    </Link>
                    <Link to='/support'>
                      <a class="text-gray-400 hover:text-rose-600 text-lg">Contact</a>
                    </Link>
                  </nav>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 class="title-font text-rose-600 font-bold tracking-widest text-sm mb-3 uppercase">Customer service</h2>
                  <nav class="list-none flex flex-col mb-10">
                    <Link to='/privacy-policy'>
                      <a class="text-gray-400 hover:text-rose-600 text-lg">Privacy Policy</a>
                    </Link>
                    <Link to='/return-policy'>
                      <a class="text-gray-400 hover:text-rose-600 text-lg">Return Policy</a>
                    </Link>
                  </nav>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 class="title-font text-rose-600 font-bold tracking-widest text-sm mb-3 uppercase">Quick Links</h2>
                  <nav class="list-none flex flex-col mb-10">
                    <Link to='/shop/all'>
                      <a class="text-gray-400 hover:text-rose-600 text-lg">Shop</a>
                    </Link>
                    <Link to='/faq'>
                      <a class="text-gray-400 hover:text-rose-600 text-lg">FAQ</a>
                    </Link>
                  </nav>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                <div className="flex flex-wrap md:flex-nowrap justify-start items-start rounded-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-40 h-20 text-rose-600">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                  </svg>
                  <div className=" md:block flex flex-wrap md:flex-nowrap justify-center items-center">
                    <Link to={openWhatsAppChat()} target="_blank" className="text-yellow-300 font-bold text-lg cursor-pointer title-font">Feedback</Link>
                      <div className="leading-relaxed h-20 text-base text-gray-400 flex justify-center items-center">
                      `Your comments help us improve our website
                      </div>
                    <a href={openWhatsAppChat()} target="_blank" rel="noopener noreferrer" className="text-yellow-300 underline font-bold text-md cursor-pointer title-font">Send Us Your Feedback</a>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-100">
            <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
              <p class="text-gray-500 text-sm text-center sm:text-left">
                <img 
                    className="object-fill w-96 h-10"
                    src={`https://api.autozedltd.com/uploads/payment-logo-1.png`} 
                    alt="Image" 
                />
              </p>
              <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                <p class="text-gray-400 font-semibold text-sm text-center sm:text-left">
                  © 2023 Autozed Spares LTD . All rights reserved.
                </p>
              </span>
            </div>
          </div>
        </footer>
    </main>
  );
}

export default Layout;
