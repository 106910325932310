import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useAssignUserARoleMutation, useGetSystemRolesQuery, useGetUserRolesByUserIdQuery, useRemoveRoleFromUserMutation } from '../../Features/rolesSlice';
import { useGetUserProfileByIdQuery } from '../../Features/usersSlice';
import LoadingSpinner from '../LoadingSpinner';


function ManageUserRolesView() {

    const navigate = useNavigate();
    let { user_id } = useParams();

    const [input, setInput] = useState({
        "roles": []
    })

    const handleChange = (e)=>{
        const { value, checked } = e.target;
        const {roles} = input;
        if(checked){
            setInput({roles: [...roles, value]})
        }else{
            setInput({
                roles: roles.filter((e)=> e !== value)
            });
        }
    }

    const {
        data: user,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUserProfileByIdQuery(user_id)

    const {
        data: userRoles,
        isLoading: userRolesIsLoading,
        isSuccess: userRolesIsSuccess,
        isError: userRolesIsError,
        error: userRolesError
    } = useGetUserRolesByUserIdQuery(user_id)

    const {
        data: systemRoles,
        isLoading: systemRolesIsLoading,
        isSuccess: systemRolesIsSuccess,
        isError: systemRolesIsError,
        error: systemRolesError
    } = useGetSystemRolesQuery();

    const [assignUserARole] = useAssignUserARoleMutation();
    const [removeRoleFromUser] = useRemoveRoleFromUserMutation();

    const handleSubmit = async(e)=>{
        e.preventDefault();
        try {

            const reqBoby = {
                id: user_id,
                roles: input.roles
            }

            const resp = await assignUserARole(reqBoby).unwrap()
            swal("Success",resp.message)
            navigate(-1);
        } catch (err) {
            if(err){
                if(err.hasOwnProperty('success') == false){
                    swal("Error",err.data.message,'error')
                }
            }
        }          
    }

    const removeUserRole = async(e,id)=>{
        if(window.confirm("Are you sure you want to delete this role from this user?")){
            try {
            const reqBoby = {
                user_id: user_id,
                role_id: id
            }
            const resp = await removeRoleFromUser(reqBoby).unwrap();
            swal("Success",resp.message);
        } catch (err) {
            swal("Error",err.message,'error');
        }
          }
    }

    const back = ()=> navigate(-1);

        return (
            <div className="bg-gray-100 flex  flex-col">
                <div className="flex items-center justify-center bg-white p-1">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">manage user Roles</h1>
                </div>
                {
                (isSuccess)?
                <div className="">
                   <div className="md:flex justify-center mt- p-8">
                        <div className="rounded-md bg-gray-200 shadow-sm pr-0 pl-0">
                            <div className="rounded-sm p-4 shadow-md bg-gray-700 text-sm uppercase font-semibold text-white flex justify-center md:pr-64 md:pl-64">
                                {user.data.fname} {user.data.lname}'s role management
                            </div>
                                <div className="flex flex-col justify-center mt-4">
                                    <p className="p-2 font-semibold text-sm text-gray-500 uppercase flex justify-center">Assigned User Roles</p>
                                        {
                                            (userRolesIsSuccess)?
                                                (userRoles.hasOwnProperty('success') === true && userRoles.data.length > 0)?
                                                    <table className="w-full divide-y divide-gray-200">
                                                        <thead className="bg-gray-500 shadow-lg">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                            >
                                                                Role
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="flex justify-center px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                            >
                                                                Description
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                            >
                                                                
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="bg-gray-300 divide-y divide-gray-300">
                                                        { (userRoles)?userRoles.data.map(data => (
                                                            <tr key={data.id} className="hover:bg-gray-300">
                                                                <td className="px-6 py-1 whitespace-nowrap">
                                                                <div className="flex items-center">
                                                                    <div className="text-sm font-medium text-gray-900">{data.name}</div>
                                                                </div>
                                                                </td>
                                                                <td className="px-2 py-1 whitespace-nowrap text-sm ">
                                                                    <div className=''>{data.description}</div>
                                                                </td>
                                                                <td onClick={(e)=>removeUserRole(e,data.id, user.data.id)} className="flex justify-center mr-4 mt-1 hover:bg-gray-500 cursor-pointer items-center px-1 py-0 whitespace-nowrap text-sm bg-gray-400 rounded-md">
                                                                    <div className=''>Remove</div>
                                                                </td>
                                                            </tr>
                                                                ))
                                                                : ''
                                                            }
                                                        </tbody>
                                                    </table>
                                                : <div>{userRoles.message}</div>
                                            : userRolesIsLoading
                                                ? <div>Loading...</div>
                                                : userRolesIsError
                                                    ? <div>{userRolesError.data.error}</div>
                                                    : ''
                                        }
                                     
                                </div>
                                <form onSubmit={handleSubmit} >
                                    <div className="flex flex-col justify-center mt-4">
                                        <p className="p-2 font-semibold text-sm text-gray-500 uppercase flex justify-center border-b border-gray-200">System roles</p>
                                        <p className="p-2 text-sm text-gray-500 uppercase flex justify-center">Select roles/role to be assigned to the user by checking the role checkbox</p>
                                        {
                                            (systemRolesIsSuccess)?
                                                <table className="w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-500 shadow-lg">
                                                        <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                        >
                                                            Role
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="flex justify-center px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                        >
                                                            Description
                                                        </th>
                                                        </tr>
                                                    </thead>  
                                                    <tbody className="bg-gray-300 divide-y divide-gray-200">
                                                            { (systemRoles.hasOwnProperty('success') === true && systemRoles.data.length > 0)?
                                                                systemRoles.data.map(data => (
                                                                    <tr key={data.id} className="hover:bg-gray-400">
                                                                        <td className="px-6 py-1 whitespace-nowrap">
                                                                        <div className="flex items-center">
                                                                            <div className="flex items-center space-x-4 text-sm font-medium text-gray-900">
                                                                                <input 
                                                                                    type="checkbox" 
                                                                                    name="roles[]" 
                                                                                    value={data.id}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <label className=''>{data.name}</label>
                                                                            </div>
                                                                        </div>
                                                                        </td>
                                                                        <td className="px-2 py-1 whitespace-nowrap text-sm ">
                                                                            <div className=''>{data.description}</div>
                                                                        </td>
                                                                    </tr>
                                                                    ))
                                                                    : ''
                                                            }
                                                    </tbody>
                                                </table> 
                                            : systemRolesIsLoading
                                                ? <dv>Loading....</dv>
                                                : systemRolesIsError
                                                    ? <div>{systemRolesError.data.error}</div>
                                                    : ''
                                        }
                                    </div>    
                                    <div className="flex justify-center mt-1 space-x-4 border-t border-gray-300 p-4">
                                        <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                            <label onClick={back} className="flex justify-center space-x-2 items-center text-sm bg-gray-400 hover:bg-gray-500 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                Cancel
                                            </label>
                                        </div>
                                        <button type="submit" className="flex flex-col items-center  w-28 rounded-md text-sm bg-blue-400 hover:bg-blue-500 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28">                                  
                                            Save
                                        </button>                           
                                    </div>
                                </form>
                        </div>
                   </div>
                </div>
                : isLoading
                    ? <LoadingSpinner />
                    : isError
                        ? <div>{error.data.error}</div>
                        : ''
                }
            </div>
        )
}

export default ManageUserRolesView;
