

function Logo(props) {
        return (
          <div className="flex items-center justify-center py-2 px-2">
            <h1 className="flex text-md rounded-xl pl-2 pr-4">
              <p className="text-orange-400 text-md font-sans">Swift Ticket</p>
              {/* <p className="text-orange-500 text-xs font-semibold">TKT</p> */}
            </h1>
          </div>
        )
}

export default Logo;
