import { Link, useNavigate, useParams } from 'react-router-dom';
import { useClearSaleMutation } from '../../Features/SalesSlice';
import LoadingSpinner from '../LoadingSpinner';
import { useSearchParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useGetBookingByTransactionReferenceQuery, useToggleBookingStatusMutation, useUpdateServicePaymentStatusMutation, useUpdateTransportChargePaymentStatusMutation } from '../../Features/bookingsSlice';
import { Menu } from '@headlessui/react';
import Home from '../../HeaderLayouts/Home';
import { useEffect, useState } from 'react';


function BookingServiceChargeReceiptView({props}) {
    const navigate = useNavigate();
    const [transactionsStatus, setTransactionStatus] = useState(false)

    const searchParams = new URLSearchParams(useSearchParams().toString());
    // Get the parameter values from the URL
    const amount = searchParams.get('amount');
    const paymentReference = searchParams.get('paymentReference');
    const paymentStatus = searchParams.get('paymentStatus');
    const paymentChannel = searchParams.get('paymentChannel');
      
    const {
      data: booking,
      isLoading: isLoadingBooking,
      isSuccess: isSuccessBooking,
      isError: isErrorBooking,
      error: errorBooking
    } = useGetBookingByTransactionReferenceQuery(paymentReference)

    useEffect(()=>{
        const data = {
            status: (paymentStatus == 'S')? 1 : 0,
            transaction_reference: paymentReference
        }
        handleUpdatePaymentStatus(data)
        if(paymentStatus == 'S'){
            setTransactionStatus(true)
        }
        window.scrollTo(0, 0)
        return () =>{
        }
      },[]);

    const [updateServicePaymentStatus] = useUpdateServicePaymentStatusMutation();

    const handleUpdatePaymentStatus = async(data)=>{
        try {
            const resp = await updateServicePaymentStatus(data).unwrap()
            swal(resp.data.message);
        } catch (err) {
            swal(err.data.error);
        }           
    }

      const [clearSale, {isLoadingClearedSales}] = useClearSaleMutation()

      const handleClearSale = async(id) => {
        if(window.confirm("Are you sure you want clear this order?")){
            try {
              const resp = await clearSale(id).unwrap()
              swal("Success",resp.message,"success");
            } catch (err) {
                if(err.data.hasOwnProperty('errors') == true){
                    swal("Error",err.data.error,"error");
                  }else if (err.data.hasOwnProperty('error') == true){
                    swal("Error",err.data.error,"error");
                  }
            }
        }
      };

    const total = ()=>{
        let transport_charge = (booking.data.transport_charge)? parseInt(booking.data.transport_charge) : 0
        return transport_charge +  parseInt(booking.data.service_charge)
    }

    const paidAmount = ()=>{
        let transport_charge = (booking.data.transport_charge)? parseInt(booking.data.transport_charge) : 0
        return (booking.data.payment_status)? transport_charge +  parseInt(booking.data.service_charge) : (booking.data.transport_charge)? parseInt(booking.data.transport_charge) : '0'
    }

    const [toggleBookingStatus] = useToggleBookingStatusMutation()

    const makeCompletedStatus = async(id,status) => {
        if(window.confirm("Are you sure you want complete this booking?")){
            try {
              const resp = await toggleBookingStatus({id: id,status: status}).unwrap()
              swal("Success",resp.message,"success");
            } catch (err) {
              if(err.data.hasOwnProperty('errors') == true){
                  swal("Error",err.data.error,"error");
                }else if (err.data.hasOwnProperty('error') == true){
                  swal("Error",err.data.error,"error");
                }
            }
        }
      };

      const toggleStatus = async(id,status) => {
        try {
          const resp = await toggleBookingStatus({id: id,status: status}).unwrap()
          swal("Success",resp.message,"success");
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
      };

    const numberWithCommas = (x)=>{
        if(x !==null){
            const formatedNumber = x.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    });
            return formatedNumber;
        }
        return null;
    }

    return (
        <div class="w-full bg-gray-50 flex-grow">
            <Home />
            <div className='w-full flex items-center justify-center bg-gray-200 text-gray-400 p-6'>
                <h1 className="text-lg font-semibold uppercase">Booking Receipt</h1>
            </div>
                {
                    (transactionsStatus)?
                        (isSuccessBooking)?             
                        <div className='w-full md:flex md:justify-center p-6'>
                            <div className="w-full md:w-2/3 mt-4 bg-white p-4 rounded-md">
                                <div class="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
                                    <div>
                                    <h2 class="text-2xl font-semibold text-gray-800 dark:text-gray-200">Receipt</h2>
                                    </div>

                                    <div class="inline-flex gap-x-2">
                                    <a class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                        </svg>
                                        Receipt PDF
                                    </a>
                                    </div>
                                </div>

                                <div class="grid md:grid-cols-2 gap-3">
                                    <div>
                                        <div class="grid space-y-3">
                                            <dl class="grid sm:flex gap-x-3 text-xs">
                                                <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                    Billed to:
                                                </dt>
                                                <dd class="text-gray-800 dark:text-gray-200">
                                                    <a class="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="#">
                                                    {booking.data.name}
                                                    </a>
                                                </dd>
                                            </dl>

                                            <dl class="grid sm:flex gap-x-3 text-xs">
                                                <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                    Billing details:
                                                </dt>
                                                <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                    <span class="block font-semibold">{booking.data.name}</span>
                                                    <address class="not-italic font-normal">
                                                    {booking.data.address},<br/>
                                                    {booking.data.phone},<br/>
                                                    Zambia<br/>
                                                    </address>
                                                </dd>
                                            </dl>

                                            <dl class="grid sm:flex gap-x-3 text-xs">
                                                <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                    Booking Date:
                                                </dt>
                                                <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                    <span class="block font-semibold">{booking.data.date}</span>
                                                </dd>
                                            </dl>
                                            <dl class="grid sm:flex gap-x-3 text-xs">
                                                <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                    Working Location:
                                                </dt>
                                                <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                    <span class="block font-semibold">{booking.data.working_location}</span>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="grid space-y-3">
                                            <dl class="grid sm:flex gap-x-3 text-xs">
                                                <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                    Transaction ID:
                                                </dt>
                                                <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                    {booking.data.transaction_reference}
                                                </dd>
                                                </dl>

                                                <dl class="grid sm:flex gap-x-3 text-xs">
                                                <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                    Currency:
                                                </dt>
                                                <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                    ZMW - ZAMBIAN KWACHA
                                                </dd>
                                                </dl>

                                                <dl class="grid sm:flex gap-x-3 text-xs">
                                                <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                    Date:
                                                </dt>
                                                <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                    {booking.data.created_at}
                                                </dd>
                                                </dl>

                                                <dl class="grid sm:flex gap-x-3 text-xs">
                                                    <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                        Billing method:
                                                    </dt>
                                                    <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                        {booking.data.payment_method}
                                                    </dd>
                                                </dl>

                                                <dl class="grid sm:flex gap-x-3 text-xs">
                                                    <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                        Booking Token:
                                                    </dt>
                                                    <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                        {booking.data.token}
                                                    </dd>
                                                </dl>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-6 border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                                    <div class="hidden sm:grid sm:grid-cols-6">
                                    <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">Service</div>
                                    <div class="text-left text-xs font-medium text-gray-500 uppercase">Service Type</div>
                                    <div class="text-left text-xs font-medium text-gray-500 uppercase">Vehicle Make</div>
                                    <div class="text-left text-xs font-medium text-gray-500 uppercase">Vehicle Model</div>
                                    <div class="text-right text-xs font-medium text-gray-500 uppercase">Total Amount</div>
                                    </div>

                                    <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>               
                                        <div class="grid text-sm grid-cols-3 sm:grid-cols-6 gap-2">
                                            <div class="col-span-full sm:col-span-2">
                                                <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Service</h5>
                                                <p class="text-xs text-gray-800 dark:text-gray-200">Booking service</p>
                                            </div>
                                            <div>
                                                <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Service Type</h5>
                                                <p class="text-gray-800 text-xs dark:text-gray-200">{(booking.data.service_type == '1')? 'Full Servicing' :(booking.data.service_type == '2')? 'Partial Servicing' :(booking.data.service_type == '3')? 'Custom Servicing' : 'Invalid'}</p>
                                            </div>
                                            <div>
                                                <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Vehicle Make</h5>
                                                <p class="text-gray-800 text-xs dark:text-gray-200">{booking.data.brand_name}</p>
                                            </div>
                                            <div>
                                                <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Vehicle Model</h5>
                                                <p class="text-gray-800 text-xs dark:text-gray-200">{booking.data.brand_model}</p>
                                            </div>
                                            <div>
                                                <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Total Amount</h5>
                                                <p class="sm:text-right text-xs text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(booking.data.service_charge))}</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="mt-6 border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                                    <div class="hidden sm:grid sm:grid-cols-6">
                                    <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">Instructions</div>
                                    </div>

                                    <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>               
                                        <div class="grid text-sm grid-cols-3 sm:grid-cols-6 gap-2">
                                            <div class="col-span-full sm:col-span-2">
                                                <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Instructions</h5>
                                                <p class="text-gray-800 text-xs dark:text-gray-200">{booking.data.instructions}</p>
                                            </div>
                                            
                                    </div>
                                </div>

                                    <div class="mt-8 flex sm:justify-end">
                                        <div class="w-full max-w-2xl sm:text-right space-y-2">
                                            <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                                                <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                    <dt class="col-span-3 text-gray-500">Subtotal:</dt>
                                                    <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(booking.data.service_charge))}</dd>
                                                </dl>

                                                <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                    <dt class="col-span-3 text-gray-500">Transport:</dt>
                                                    <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{(booking.data.transport_charge)? numberWithCommas(parseInt(booking.data.transport_charge)) : '00.00' }</dd>
                                                </dl>

                                                <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                    <dt class="col-span-3 text-gray-500">Total:</dt>
                                                    <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(total())}</dd>
                                                </dl>

                                                <dl class="grid sm:grid-cols-5 font-semibold gap-x-3 text-xs">
                                                    <dt class="col-span-3 font-semibold text-gray-500">Amount paid:</dt>
                                                    <dd class="col-span-2 text-gray-800 dark:text-gray-200">K{numberWithCommas(paidAmount())}</dd>
                                                </dl>

                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        :(isLoadingBooking)?
                            <LoadingSpinner />
                        :(isErrorBooking)?
                            <div>
                                failed
                            </div>
                        :null
                    :
                        <div className='pl-20 pr-20'>
                          <div className='p-5 flex justify-center items-center bg-red-300 rounded-md'>Transactions failed</div>
                        </div>
                }
        </div>
    )
}

export default BookingServiceChargeReceiptView;
