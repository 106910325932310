import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { logOut } from '../Features/auth/authSlice';
import { useLogoutMutation } from '../Features/usersSlice';

function UnauthorizedView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [logout, {isLoading}] = useLogoutMutation();

    const logUserOut = async () =>{
      try {
        const resp = await logout().unwrap()
        dispatch(logOut())
        navigate("/login");
        return swal("UNAUTHORIZED ACCESS", 'Unauthorized access detected',"error");
    } catch (err) {
        if(err){
            swal("Error", err.message,"error")
            dispatch(logOut())
        }    
      }
    }

    useEffect(()=>{
        logUserOut();
        return () =>{
        }
    },[]);

  return (
    <div className='flex h-screen bg-white justify-center items-center'>
        <div className='text-red-500 text-lg'>UNAUTHORIZED ACCESS</div>
    </div>
  )
}

export default UnauthorizedView