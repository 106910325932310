import { apiSlice } from "../app/api/apiSlice";

export const brandsSliceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllBrands: builder.query({
            query: () => `/user/brands`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['brands']
        }),
        createBrand: builder.mutation({
            query: (input) => ({
                url: `user/create-brand`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['brands']
        }),
        editBrand: builder.mutation({
            query: (input) => ({
                url: `user/edit-brand/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['brands']
        }),
        editBrandImage: builder.mutation({
            query: (input) => ({
                url: `user/edit-brand-image/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['brands']
        }),
        getBrandById: builder.query({
            query: (id) => `/user/brand/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['brands']
        }),

        getBrandByIdMutation: builder.mutation({
            query: (id) => `/user/brand/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['brands']
        }),

        deleteBrand: builder.mutation({
            query: (id) => ({
                url: `user/delete-brand/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['brands']
        }),

        //Brand models
        getBrandModelsMutation: builder.mutation({
            query: (brand_id) => `/user/brand-models/${brand_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['brand-models']
        }),
        getBrandModels: builder.query({
            query: (brand_id) => `/user/brand-models/${brand_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['brand-models']
        }),
        createBrandModel: builder.mutation({
            query: (input) => ({
                url: `user/create-brand-model`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['brand-models']
        }),
        getBrandModelById: builder.query({
            query: (id) => `/user/brand-model/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['brand-models']
        }),
        getBrandModelByIdMutation: builder.mutation({
            query: (id) => `/user/brand-model/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['brand-models']
        }),
        editBrandModel: builder.mutation({
            query: (input) => ({
                url: `user/edit-brand-model/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['brand-models']
        }),
        deleteBrandModel: builder.mutation({
            query: (id) => ({
                url: `user/delete-brand-model/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['brand-models']
        }),
    })
})

export const {
    useGetAllBrandsQuery,
    useCreateBrandMutation,
    useEditBrandMutation,
    useDeleteBrandMutation,
    useGetBrandByIdMutationMutation,
    useEditBrandImageMutation,
    //brand models
    useCreateBrandModelMutation,
    useGetBrandModelsMutationMutation,
    useGetBrandModelByIdMutationMutation,
    useGetBrandModelsQuery,
    useEditBrandModelMutation,
    useDeleteBrandModelMutation,
} = brandsSliceApiSlice

