import { Link, useNavigate, useParams } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FileUploader } from "react-drag-drop-files";
import swal from 'sweetalert';
import LoadingSpinner from '../LoadingSpinner';
import {  useGetAllBrandsQuery } from '../../Features/brandsSlice';
import { useGetAllCategoriesQuery } from '../../Features/categoriesSlice';
import { useCreateProductMutation, useEditProductMutation, useGetProductByIdQuery } from '../../Features/productsSlice';
import { useEffect } from 'react';


function AdminUserEditProductsView({props}) {
  const navigate = useNavigate();
  const { product_id } = useParams();

  const [input, setInput] = useState({})
  const [extraFiles, setExtraFiles] = useState('')
  const [hasShippingStatus, setHasShippingStatus] = useState(false)
  const [isFeaturedStatus, setIsFeaturedStatus] = useState(false)
  const [isLatestStatus, setIsLatestStatus] = useState(false)
  const [isBestSellingStatus, setIsBestSellingStatus] = useState(false)

  const [file, setFile] = useState(null);
  const [manageInventory, setManageInventory] = useState(false);
  const [manageInventoryBackoder, setmanageInventoryBackoder] = useState(false);
  const [stockStatus, setStockStatus] = useState(false);
  const fileTypes = ["JPG", "PNG", "GIF"];

    
    const getBase64 = (file) => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
        
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
      });
    };

    const handleFileChange = async(file) => {
      const base64 = await getBase64(file[0])
      setFile(file);
      setInput({...input,  
        image: base64,
      })
    };

    const handleExtraFilesChange = async(files) => {
      let filesObj = [];
      if(files.length > 1){
        for(let x=0; x<files.length; x++){
          filesObj.push(await getBase64(files[x]))
        }
      }else{
        filesObj.push(await getBase64(files[0]))
      }
        setExtraFiles(filesObj)
    }


    const [errors, setErrors] = useState({
      name: '',
      price: '',
      old_price: '',
      quantity: '',
      brand: '',
      custome_brand: '',
      category: '',
      description: '',
      image: '',
      extra_images: '',
    })
      
    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
      }

      
    const {
      data: product,
      isLoading: isLoadingProducts,
      isSuccess: isSuccessProducts,
      isError: isErrorProducts,
      error: errorProducts
    } = useGetProductByIdQuery(product_id)


    const {
      data: brands,
      isLoading: isLoadingBrands,
      isSuccess: isSuccessBrands,
      isError: isErrorBrands,
      error: errorBrands
    } = useGetAllBrandsQuery()
    
    const {
      data: categories,
      isLoading: isLoadingCategories,
      isSuccess: isSuccessCategories,
      isError: isErrorCategories,
      error: errorCategories
    } = useGetAllCategoriesQuery()
    
    useEffect(()=>{
      if(isSuccessProducts && product.hasOwnProperty("data")){
          setInput(product.data['0'].product);
          (product.data['0'].product.is_featured == '1')? setIsFeaturedStatus(true) : setIsFeaturedStatus(false);
          (product.data['0'].product.is_latest == '1')? setIsLatestStatus(true) : setIsLatestStatus(false);
          (product.data['0'].product.is_best_selling == '1')? setIsBestSellingStatus(true) : setIsBestSellingStatus(false);

          (product.data['0'].product.has_inventory == '1')? setManageInventory(true) : setManageInventory(false);
          (product.data['0'].product.has_inventory_backoder == '1')? setmanageInventoryBackoder(true) : setmanageInventoryBackoder(false);
          (product.data['0'].product.stock_status)? setStockStatus(product.data['0'].product.stock_status) : setStockStatus(false);
          (product.data['0'].product.has_shipping)? setHasShippingStatus(product.data['0'].product.has_shipping) : setHasShippingStatus(false);
        }
      return () =>{
      }
    },[product, isFeaturedStatus, isLatestStatus, isBestSellingStatus]);
    
    const [editProduct, {isLoading, isSuccess, isError}] = useEditProductMutation()

    const handleSubmit = async(e)=>{
        e.preventDefault();
        let has_inventory = false;
        let has_inventory_backoder = 0;
        if(manageInventory){
          //inventory management
          if(input.quantity != ''){
            has_inventory_backoder = (manageInventoryBackoder)? 1 : 0;
            has_inventory = true;
          }else{
            return setErrors({...errors, quantity: "Required field"})
          }
        }else{
          //stock management 
          if(!stockStatus){
            return swal("Error","Select stock status","error");
          }
        }

        let data = {
          name: input.name,
          price: input.price,
          old_price: input.old_price,
          quantity: (input.quantity)? input.quantity : 0,
          brand: input.brand_id,
          custome_brand: input.custome_brand,
          category: input.category_id,
          description: input.description,
          image: (file)? input.image : false,
          extra_images: extraFiles,
          has_shipping: input.has_shipping,
          is_featured: input.is_featured,
          is_latest: input.is_latest,
          is_best_selling: input.is_best_selling,
          sku: input.sku,
          stock_status: (stockStatus)? stockStatus : 0,
          has_inventory: has_inventory,
          has_inventory_backoder: has_inventory_backoder,
          id: input.product_id,
        }
        // return console.log(data)
        try {
            const resp = await editProduct(data).unwrap()
            swal(resp.message);
            navigate(-1)
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors,  
                  name: err.data.errors.name,
                  price: err.data.errors.price,
                  old_price: err.data.errors.old_price,
                  quantity: err.data.errors.quantity,
                  brand: err.data.errors.brand,
                  category: err.data.errors.category,
                  description: err.data.errors.description,
                  image: err.data.errors.image,
                  custome_brand: err.data.errors.custome_brand,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const handleHasShippingChange = async(e)=>{
      const { value, checked } = e.target;
      (checked)? setInput({...input, has_shipping: 1}) : setInput({...input, has_shipping: 0})
      
    }

    const handleIsFeaturedChange = async(e)=>{
      const { value, checked } = e.target;
      (checked)? setInput({...input, is_featured: 1}) : setInput({...input, is_featured: 0})
      
    }

    const handleManageInventoryhange = async(e)=>{
      const { value, checked } = e.target;
      (checked)? setManageInventory(true) : setManageInventory(false)
      
    }

    const handleManageStockChange = async(e)=>{
      const { value, checked } = e.target;
      (checked)? setStockStatus(value) : setStockStatus(false)
      
    }

    const handleManageInventoryBackorderChange = async(e)=>{
      const { value, checked } = e.target;
      (checked)? setmanageInventoryBackoder(true) : setmanageInventoryBackoder(false)
      
    }

    const handleIsLatestChange = async(e)=>{
      const { value, checked } = e.target;
      (checked)? setInput({...input, is_latest: 1}) : setInput({...input, is_latest: 0})
      
    }

    const handleIsBestSellingChange = async(e)=>{
      const { value, checked } = e.target;
      (checked)? setInput({...input, is_best_selling: 1}) : setInput({...input, is_best_selling: 0})
      
    }

    const back = ()=> navigate(-1);

    return (
        <main className='w-full flex-grow'>
            <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                <h1 className="text-lg font-semibold">Edit Product</h1>
            </div>
            {
              (isSuccessBrands)?
                (isSuccessCategories)?
                        <div className='flex justify-center p-10'>
                          <div className="w-full lg:w-2/2 mt-1">
                            <div className="bg-white flex justify-center rounded-md p-4">
                            <form onSubmit={handleSubmit}>
                              <div class="space-y-12">
                                <div class="border-b border-gray-900/10 pb-12">
                                  <h2 class="text-base font-semibold leading-7 text-gray-900">Product Details</h2>
                                  <p class="mt-1 text-sm leading-6 text-gray-600">Add a product to you inventory.</p>

                                  <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
                                    <div class="sm:col-span-3">
                                      <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Product name</label>
                                      <div class="mt-2">
                                        <input 
                                          type="text" 
                                          name="name" 
                                          id="name" 
                                          value={input.name} 
                                          onChange={handleChange} 
                                          class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" 
                                        />
                                        <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                                      </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                      <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Sale Price</label>
                                      <div class="mt-2">
                                        <input 
                                          type="number" 
                                          name="price" 
                                          id="price" 
                                          value={input.price} 
                                          onChange={handleChange} 
                                          class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" 
                                        />
                                        <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.price}</p>
                                      </div>
                                    </div>

                                    <div class="sm:col-span-4">
                                      <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Regular price</label>
                                      <div class="mt-2">
                                        <input 
                                          type="number" 
                                          name="old_price" 
                                          id="old_price" 
                                          value={input.old_price} 
                                          onChange={handleChange} 
                                          class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.old_price}</p>
                                      </div>
                                    </div>

                                    <div class="sm:col-span-2">
                                      <label for="sku" class="block text-sm font-medium leading-6 text-gray-900">Product No.</label>
                                      <div class="mt-2">
                                        <input 
                                          type="text" 
                                          name="sku" 
                                          id="sku" 
                                          value={input.sku} 
                                          onChange={handleChange}  
                                          class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                                        <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.sku}</p>
                                      </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                      <label for="brand" class="block text-sm font-medium leading-6 text-gray-900">{"Vehicle Brand(Optional)"}</label>
                                      <div class="mt-2">
                                        <select id="brand_id" value={input.brand_id} onChange={handleChange} name="brand_id" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                          <option>Select brand</option>
                                          {
                                            (brands.data.length > 0)?
                                              brands.data.map(data => (
                                                <option value={data.id}>{data.name}</option>
                                              ))
                                            :null
                                          }
                                        </select>
                                      </div>
                                      <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.brand}</p>
                                    </div>
                                    <div class="sm:col-span-3">
                                      <label for="custome_brand" class="block text-sm font-medium leading-6 text-gray-900">{"Customer Brand(Optional)"}</label>
                                      <div class="mt-2">
                                        <input 
                                          type="text" 
                                          name="custome_brand" 
                                          id="custome_brand" 
                                          value={input.custome_brand} 
                                          onChange={handleChange} 
                                          class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" 
                                        />
                                        <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.custome_brand}</p>
                                      </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                      <label for="category" class="block text-sm font-medium leading-6 text-gray-900">Category</label>
                                      <div class="mt-2">
                                        <select id="category_id" value={input.category_id} onChange={handleChange} name="category_id" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                          <option>Select category</option>
                                          {
                                            (categories.data.length > 0)?
                                              categories.data.map(data => (
                                                <option value={data.id}>{data.name}</option>
                                              ))
                                            :null
                                          }
                                        </select>
                                      </div>
                                      <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.category}</p>
                                    </div>

                                    <div class="sm:col-span-3">
                                      <div class="relative flex gap-x-3">
                                        <div class="flex h-6 items-center">
                                          <input id="is_featured" defaultChecked={(hasShippingStatus == "1")? true:false} value={input.has_shipping} onChange={handleHasShippingChange} name="is_featured" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                        <div class="text-sm leading-6">
                                          <label for="is_featured" class="font-medium text-gray-900">Has Shipping</label>
                                          <p class="text-gray-400">Indicate wether shipping applies to product or not</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-span-full">
                                      <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                                      <div class="mt-2">
                                        <textarea id="description" value={input.description} onChange={handleChange} name="description" rows="3" placeholder='Write a few sentences about the product.' class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"></textarea>
                                      </div>
                                      <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.description}</p>
                                    </div>

                                    <div class="col-span-full">
                                      <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Cover photo</label>
                                      <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                        <div>
                                          <FileUploader
                                            multiple={true}
                                            handleChange={handleFileChange}
                                            name="file"
                                            types={fileTypes}
                                          />
                                          <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>
                                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.image}</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-span-full">
                                      <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Additional photos</label>
                                      <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                        <div>
                                          <FileUploader
                                            multiple={true}
                                            handleChange={handleExtraFilesChange}
                                            name="file"
                                            types={fileTypes}
                                          />
                                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.extra_images}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="border-b border-gray-900/10 pb-6">
                                  <h2 class="text-base font-semibold leading-7 text-gray-900">Inventory Management</h2>
                                  <div class="mt-2 space-y-6">
                                    <fieldset>
                                      <div class="mt-6 space-y-6">
                                        <div class="relative flex gap-x-3">
                                          <div class="flex h-6 items-center">
                                            <input id="is_featured" defaultChecked={manageInventory} value={manageInventory} onChange={handleManageInventoryhange} name="is_featured" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                          </div>
                                          <div class="text-sm leading-6">
                                            <label for="is_featured" class="font-medium text-gray-900">Stock Management</label>
                                            <div className={`${(manageInventory)? '' : 'hidden'}`}>
                                              <p class="text-gray-500 pt-3">
                                                <div class="sm:col-span-3 flex items-center">
                                                  <label for="quantity" class="block text-sm font-medium leading-6 text-gray-900">{"Quantity"}:</label>
                                                  <div class="ml-4">
                                                    <input 
                                                      type="number" 
                                                      name="quantity" 
                                                      id="quantity" 
                                                      value={input.quantity} 
                                                      onChange={handleChange} 
                                                      class="block w-full rounded-md border-0 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" 
                                                    />
                                                    <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.quantity}</p>
                                                  </div>
                                                </div>
                                              </p>
                                              <p class="text-gray-500 pt-3">
                                                <div class="sm:col-span-3 flex items-center">
                                                  <label for="quantity" class="block text-sm font-medium leading-6 text-gray-900">{"Allow backorders?"}</label>
                                                  <div class="ml-4">
                                                    <div class="relative flex gap-x-3">
                                                      <div class="flex h-6 items-center">
                                                        <input id="is_featured" defaultChecked={manageInventory} value={input.is_featured} onChange={handleManageInventoryBackorderChange} name="is_featured" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={`${(!manageInventory)? '' : 'hidden'}`}>
                                          <div class="relative flex gap-x-3">
                                            <div class="flex h-6 items-center">
                                              Stock Status
                                            </div>
                                            <div className='flex flex-col'>
                                              <div class="text-sm leading-6 flex items-center space-x-2">
                                                <label for="is_latest" class="font-medium text-gray-900">
                                                <input id="is_latest" defaultChecked={(stockStatus == "1")? true:false} value="1" onChange={handleManageStockChange} name="is_latest" type="radio" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                </label>
                                                <p class="text-gray-500">In stock</p>
                                              </div>
                                              <div class="text-sm leading-6 flex items-center space-x-2">
                                                <label for="is_latest" class="font-medium text-gray-900">
                                                <input id="is_latest" defaultChecked={(stockStatus == "2")? true:false} value="2" onChange={handleManageStockChange} name="is_latest" type="radio" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                </label>
                                                <p class="text-gray-500">Out of stock</p>
                                              </div>
                                              <div class="text-sm leading-6 flex items-center space-x-2">
                                                <label for="is_latest" class="font-medium text-gray-900">
                                                <input id="is_latest" defaultChecked={(stockStatus == "3")? true:false} value="3" onChange={handleManageStockChange} name="is_latest" type="radio" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                </label>
                                                <p class="text-gray-500">On backorders</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>

                                <div class="border-b border-gray-900/10 pb-12">
                                  <h2 class="text-base font-semibold leading-7 text-gray-900">Additional Information</h2>
                                  <p class="mt-1 text-sm leading-6 text-gray-600">This section allows you to control were the product can appear on your website</p>

                                  <div class="mt-10 space-y-10">
                                    <fieldset>
                                      <legend class="text-sm font-semibold leading-6 text-gray-900">Options</legend>
                                      <div class="mt-6 space-y-6">
                                        <div class="relative flex gap-x-3">
                                          <div class="flex h-6 items-center">
                                            <input id="is_featured" defaultChecked={isFeaturedStatus} value={input.is_featured} onChange={handleIsFeaturedChange} name="is_featured" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                          </div>
                                          <div class="text-sm leading-6">
                                            <label for="is_featured" class="font-medium text-gray-900">Featured</label>
                                            <p class="text-gray-500">Your product will appear in the featured products section.</p>
                                          </div>
                                        </div>
                                        <div class="relative flex gap-x-3">
                                          <div class="flex h-6 items-center">
                                            <input id="is_latest" defaultChecked={isLatestStatus} value={input.is_latest} onChange={handleIsLatestChange} name="is_latest" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                          </div>
                                          <div class="text-sm leading-6">
                                            <label for="is_latest" class="font-medium text-gray-900">Latest</label>
                                            <p class="text-gray-500">Your product will appear in the latest products section.</p>
                                          </div>
                                        </div>
                                        <div class="relative flex gap-x-3">
                                          <div class="flex h-6 items-center">
                                            <input id="is_best_selling" defaultChecked={isBestSellingStatus} value={input.is_best_selling} onChange={handleIsBestSellingChange} name="is_best_selling" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                          </div>
                                          <div class="text-sm leading-6">
                                            <label for="is_best_selling" class="font-medium text-gray-900">Best Selling</label>
                                            <p class="text-gray-500">Your product will appear in the best selling products section.</p>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>
                              </div>

                              <div class="mt-6 flex items-center justify-end gap-x-6">
                                <button onClick={back} type="button" class="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                                <button type="submit" class="rounded-md bg-rose-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                  {
                                    (isLoading)?
                                    <p>Wait...</p>
                                    : <p>Save</p>
                                  }
                                </button>
                              </div>
                            </form>
                            </div>
                          </div>
                        </div>
                    :(isLoadingBrands)?
                    <LoadingSpinner />
                  :(isErrorBrands)?
                    <div>
                      failed
                    </div>
                  :null
                :(isLoadingBrands)?
                  <LoadingSpinner />
                :(isErrorBrands)?
                  <div>
                    failed
                  </div>
              :null
            }
        </main>
    )
}

export default AdminUserEditProductsView;
