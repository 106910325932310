import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react'
import { useGetAllBookingsMutationMutation, useGetApprovedBookingsMutation, useGetBookingByIdMutationMutation, useGetCancelledBookingsMutation, useGetCompletedBookingsMutation, useGetOnGoingBookingsMutation, useGetPendingBookingsQuery, useGetReceitedBookingsMutation, useSearchBookingsMutation, useToggleBookingStatusMutation, useUpdateServicePaymentStatusMutation, useUpdateTransportChargePaymentStatusMutation } from '../../Features/bookingsSlice';
import { Menu } from '@headlessui/react';
import LoadingSpinner from '../LoadingSpinner';
import BookingsTableHelperView from './BookingsTableHelperView';
import EmptyBookingsTableHelperView from './EmptyBookingsTableHelperView';
import { useClearSaleMutation, useNextPageMutation, useUpdatePaymentStatusLookUpMutation } from '../../Features/SalesSlice';
import swal from 'sweetalert';
import { Dialog, Transition } from '@headlessui/react'
import { useLookUpPaymentMutation } from '../../Features/lookUpPaymentSlice';
import { XIcon } from '@heroicons/react/outline';


function AdminUserBookingsView({props}) {
    const navigate = useNavigate();
    const [openTab, setOpenTab] = useState(1);

    const [allBookings, setAllBookings] = useState(null);
    const [completedBookings, setCompletedBookings] = useState(null);
    const [approvedBookings, setApprovedBookings] = useState(null);
    const [cancelledBookings, setCancelledBookings] = useState(null);
    const [receitedBookings, setReceitedBookings] = useState(null);
    const [searchData, setSearchData] = useState(null);
    const [open, setOpen] = useState(false)
    const [booking, setBooking] = useState(null);
    const cancelButtonRef = useRef(null)

    const [page, setPage] = useState(1);
    const pagination = 10;

    const [input, setInput] = useState({
      search: '',
    })

    const [errors, setErrors] = useState({
      search: '',
    })

    const handleChange = (e)=>{
      setInput({...input, [e.target.name]: e.target.value})
    }

    const {
      data: pendingBookings,
      isLoading: isLoadingPendingBookings,
      isSuccess: isSuccessPendingBookings,
      isError: isErrorPendingBookings,
      error: errorPendingBookings,
      refetch
    } = useGetPendingBookingsQuery({pagination: pagination, page: page})

    const [nextPage] = useNextPageMutation()

    const handleNextPage = async(url) => {
      try {
        if(openTab == '1'){
          setPage(page + 1)
          refetch()
      }else if(openTab == '2'){
        const resp = await nextPage(url).unwrap()
        approvedBookings(resp)
      }else if(openTab == '3'){
        const resp = await nextPage(url).unwrap()
        setCompletedBookings(resp)
      }else if(openTab == '4'){
        const resp = await nextPage(url).unwrap()
        setCancelledBookings(resp)
      }else if(openTab == '5'){
        const resp = await nextPage(url).unwrap()
        setAllBookings(resp)
      }else if(openTab == '6'){
        const resp = await nextPage(url).unwrap()
        setReceitedBookings(resp)
      }
      } catch (err) {
        if(err.data.hasOwnProperty('errors') == true){
          swal("Error",err.data.error,"error");
        }else if (err.data.hasOwnProperty('error') == true){
          swal("Error",err.data.error,"error");
        }
      }
    };

    const handlePrevPage = async(url) => {
      try {
        if(openTab == '1'){
          setPage(page - 1)
          refetch()
      }else if(openTab == '2'){
        const resp = await nextPage(url).unwrap()
        approvedBookings(resp)
      }else if(openTab == '3'){
        const resp = await nextPage(url).unwrap()
        setCompletedBookings(resp)
      }else if(openTab == '4'){
        const resp = await nextPage(url).unwrap()
        setCancelledBookings(resp)
      }else if(openTab == '5'){
        const resp = await nextPage(url).unwrap()
        setAllBookings(resp)
      }else if(openTab == '6'){
        const resp = await nextPage(url).unwrap()
        setReceitedBookings(resp)
      }
      } catch (err) {
        if(err.data.hasOwnProperty('errors') == true){
          swal("Error",err.data.error,"error");
        }else if (err.data.hasOwnProperty('error') == true){
          swal("Error",err.data.error,"error");
        }
      }
    };

    const [getBookingByIdMutation] = useGetBookingByIdMutationMutation()

    const handleViewBooking = async(id)=>{
        try {
            const resp = await getBookingByIdMutation(id).unwrap()
            setBooking(resp)
            setOpen(true)
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
    }
    
    const handleTabClick = (tab) => {
      setOpenTab(tab);
      setInput({...input, search: ''})
      setSearchData(null)
    };

    const [searchBookings] = useSearchBookingsMutation()

      const handleSearchBookings = async(e) => {
        e.preventDefault();
        try {
          setSearchData(0)
          const resp = await searchBookings(input).unwrap()
          setSearchData(resp)
          setAllBookings(null)
          setOpenTab(5);
        } catch (error) {
          setSearchData(null)
          setAllBookings(null)
          setOpenTab(5);
        }
      };

    const [getAllBookingsMutation] = useGetAllBookingsMutationMutation()

    const handleAllBookingsTabClick = async(tab) => {
      try {
        setAllBookings(0)
        const resp = await getAllBookingsMutation(pagination).unwrap()
        setInput({...input, search: ''})
        setAllBookings(resp)
        setOpenTab(tab);
      } catch (error) {
        setAllBookings(null)
        setInput({...input, search: ''})
        setOpenTab(tab);
      }
    };

    const [getCompletedBookings] = useGetCompletedBookingsMutation()

    const handleCompletedBookingsTabClick = async(tab) => {
      try {
        setCompletedBookings(0)
        const resp = await getCompletedBookings(pagination).unwrap()
        setCompletedBookings(resp)
        setInput({...input, search: ''})
        setOpenTab(tab);
      } catch (error) {
        setCompletedBookings(null)
        setInput({...input, search: ''})
        setOpenTab(tab);
      }
    };

    const [getApprovedBookings] = useGetApprovedBookingsMutation()

    const handleApprovedBookingsTabClick = async(tab) => {
      try {
        setApprovedBookings(0)
        const resp = await getApprovedBookings(pagination).unwrap()
        setInput({...input, search: ''})
        setApprovedBookings(resp)
        setOpenTab(tab);
      } catch (error) {
        setApprovedBookings(null)
        setInput({...input, search: ''})
        setOpenTab(tab);
      }
    };

    const [getCancelledBookings] = useGetCancelledBookingsMutation()

    const handleCancelledBookingsTabClick = async(tab) => {
      try {
        setCancelledBookings(0)
        const resp = await getCancelledBookings(pagination).unwrap()
        setInput({...input, search: ''})
        setCancelledBookings(resp)
        setOpenTab(tab);
      } catch (error) {
        setCancelledBookings(null)
        setInput({...input, search: ''})
        setOpenTab(tab);
      }
    }

    const [getReceitedBookings] = useGetReceitedBookingsMutation()

    const handleReceitedBookingsTabClick = async(tab) => {
      try {
        setReceitedBookings(0)
        const resp = await getReceitedBookings(pagination).unwrap()
        setInput({...input, search: ''})
        setReceitedBookings(resp)
        setOpenTab(tab);
      } catch (error) {
        setReceitedBookings(null)
        setInput({...input, search: ''})
        setOpenTab(tab);
      }
    }

    const handleCloseModal = async()=>{
      setOpen(false)
    }

    ///

    const [clearSale, {isLoadingClearedSales}] = useClearSaleMutation()

    const handleClearSale = async(id) => {
      if(window.confirm("Are you sure you want clear this order?")){
          try {
            const resp = await clearSale(id).unwrap()
            swal("Success",resp.message,"success");
          } catch (err) {
              if(err.data.hasOwnProperty('errors') == true){
                  swal("Error",err.data.error,"error");
                }else if (err.data.hasOwnProperty('error') == true){
                  swal("Error",err.data.error,"error");
                }
          }
      }
    };

  const total = ()=>{
      let transport_charge = (booking.data.transport_charge)? parseInt(booking.data.transport_charge) : 0
      return transport_charge +  parseInt(booking.data.service_charge)
  }

  const paidAmount = ()=>{
      let transport_charge = (booking.data.transport_charge)? parseInt(booking.data.transport_charge) : 0
      return (booking.data.payment_status)? transport_charge +  parseInt(booking.data.service_charge) : (booking.data.transport_charge)? parseInt(booking.data.transport_charge) : '0'
  }

  const [toggleBookingStatus] = useToggleBookingStatusMutation()

  const makeCompletedStatus = async(id,status) => {
      if(window.confirm("Are you sure you want complete this booking?")){
          try {
            const resp = await toggleBookingStatus({id: id,status: status}).unwrap()
            const refreshResp = await getBookingByIdMutation(id).unwrap()
            setBooking(refreshResp)
            if(openTab == '1'){
              refetch()
            }else if(approvedBookings){
              const resp = await getApprovedBookings(pagination).unwrap()
              setApprovedBookings(resp)
            }else if(receitedBookings){
              const resp = await getReceitedBookings(pagination).unwrap()
              setReceitedBookings(resp)
            }else if(allBookings){
              const resp = await getAllBookingsMutation(pagination).unwrap()
              setAllBookings(resp)
            }else if(searchData){
              const resp = await searchBookings(input).unwrap()
              setSearchData(resp)
            }
            swal("Success",resp.message,"success");
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                swal("Error",err.data.error,"error");
              }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
              }
          }
      }
    };

    const toggleStatus = async(id,status) => {
      try {
        const resp = await toggleBookingStatus({id: id,status: status}).unwrap()
        const refreshResp = await getBookingByIdMutation(id).unwrap()
        setBooking(refreshResp)
        if(openTab == '1'){
          refetch()
        }else if(allBookings){
          const resp = await getAllBookingsMutation(pagination).unwrap()
          setAllBookings(resp)
        }else if(searchData){
          const resp = await searchBookings(input).unwrap()
          setSearchData(resp)
        }
        swal("Success",resp.message,"success");
      } catch (err) {
        if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
      }
    };
  
    
    //update service payment lookup
    const [lookUpPaymentSlice] = useLookUpPaymentMutation()

    const [updateServicePaymentStatus] = useUpdateServicePaymentStatusMutation();

    const handlePaymentLookUp = async(id, transaction_reference) => {
      if(window.confirm("Are you sure you want to look up this transaction?")){
          try {
          //     const data = {
          //         paymentReference: 'CMS398762022',
          //         systemId: "PBS-Super Merchant",
          //         password: "qq!q/Db[pSTn@NbcdS9S"
          // }
          //     const response = await lookUpPaymentSlice(data);

              // if(response.data.status == 0){
                  const postData = {
                      status: 1,
                      transaction_reference: transaction_reference
                  }
                    const resp = await updateServicePaymentStatus(postData).unwrap()
                    const refreshResp = await getBookingByIdMutation(id).unwrap()
                    setBooking(refreshResp)
                    if(openTab == '1'){
                      refetch()
                    }else if(allBookings){
                      const resp = await getAllBookingsMutation(pagination).unwrap()
                      setAllBookings(resp)
                    }else if(searchData){
                      const resp = await searchBookings(input).unwrap()
                      setSearchData(resp)
                    }
                      return swal(resp.message);
              // }
              // return swal("Payment lookup failed");
          } catch (err) {
              return swal("Success",err,"success");
          }
      }
    };

    //lookup transport charge payment status
    const [updateTransportChargePaymentStatus] = useUpdateTransportChargePaymentStatusMutation();

    const handleTransportChargePaymentLookUp = async(id, transaction_reference) => {
      if(window.confirm("Are you sure you want to look up this transaction?")){
          try {
              const data = {
                  paymentReference: 'CMS398762022',
                  systemId: "PBS-Super Merchant",
                  password: "qq!q/Db[pSTn@NbcdS9S"
          }
              // const response = await lookUpPaymentSlice(data);

              // if(response.data.status == 0){
                  const postData = {
                      status: 1,
                      transaction_reference: transaction_reference
                  }
                    const resp = await updateTransportChargePaymentStatus(postData).unwrap()
                    const refreshResp = await getBookingByIdMutation(id).unwrap()
                    setBooking(refreshResp)

                  if(openTab == '1'){
                      refetch()
                  }else if(allBookings){
                    const resp = await getAllBookingsMutation(pagination).unwrap()
                    setAllBookings(resp)
                  }else if(searchData){
                    const resp = await searchBookings(input).unwrap()
                    setSearchData(resp)
                  }
                    return swal(resp.message);
              // }
              // return swal("Payment lookup failed");
          } catch (err) {
              return swal("Success",err,"success");
          }
      }
    };

  const numberWithCommas = (x)=>{
      if(x !==null){
          const formatedNumber = x.toLocaleString('en-US', {
                                  style: 'decimal',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                  });
          return formatedNumber;
      }
      return null;
  }

    return (
            <div className="w-full h-screen overflow-x-hidden border-t flex flex-col">

            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative md:w-3/4 transform overflowy-y-scroll rounded-lg bg-white text-left shadow-xl transition-all ">
                      <div class="w-full flex flex-col">
                          <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                              <h1 className="text-lg font-semibold uppercase">Booking Invoice</h1>
                          </div>
                              {
                                  (booking)?             
                                      <div className='w-full p-6'>
                                          <div className="w-full mt-4 bg-white p-4 rounded-md">
                                              <div class="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
                                                  <div>
                                                  <h2 class="text-2xl font-semibold text-gray-800 dark:text-gray-200">Invoice</h2>
                                                  </div>

                                                  <div class="inline-flex gap-x-2">
                                                  <a class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                                                      <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                      </svg>
                                                      Invoice PDF
                                                  </a>
                                                      {
                                                          (booking.data.status == '0')?
                                                          <Menu as="div" className=''>
                                                              <Menu.Button className="">
                                                                  {
                                                                      (booking.data.status == '0')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-green-400 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Pending
                                                                          </div>
                                                                      :(booking.data.status == '1')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Ongoing
                                                                          </div>
                                                                      :(booking.data.status == '2')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Completed
                                                                          </div>
                                                                      :(booking.data.status == '3')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Cancelled
                                                                          </div>
                                                                      :null
                                                                  }
                                                              </Menu.Button> 
                                                              <Menu.Items className="absolute focus:outline-none right-24 bg-white rounded-md shadow-lg w-32">
                                                                  {
                                                                      (booking.data.status != '1' && booking.data.status != '2' && booking.data.payment_status != '2' && booking.data.payment_status != '3')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => toggleStatus(booking.data.booking_id, '1')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Approve</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                                  {
                                                                      (booking.data.status != '2' && booking.data.payment_status != '2' && booking.data.payment_status != '3')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => makeCompletedStatus(booking.data.booking_id, '2')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Complete</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                                  {
                                                                      (booking.data.status != '2' && booking.data.payment_status != '3')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => toggleStatus(booking.data.booking_id, '3')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Cancel</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                              </Menu.Items>
                                                          </Menu>
                                                          :(booking.data.status == '1')?
                                                          <Menu as="div" className=''>
                                                              <Menu.Button className="">
                                                                  {
                                                                      (booking.data.status == '0')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-green-400 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Pending
                                                                          </div>
                                                                      :(booking.data.status == '1')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Ongoing
                                                                          </div>
                                                                      :(booking.data.status == '2')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Completed
                                                                          </div>
                                                                      :(booking.data.status == '3')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Cancelled
                                                                          </div>
                                                                      :null
                                                                  }
                                                              </Menu.Button> 
                                                              <Menu.Items className="absolute focus:outline-none right-24 bg-white rounded-md shadow-lg w-32">
                                                                  {
                                                                      (booking.data.status != '1' && booking.data.status != '2')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => toggleStatus(booking.data.booking_id, '1')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Approve</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                                  {
                                                                      (booking.data.status != '2')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => makeCompletedStatus(booking.data.booking_id, '2')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Complete</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                                  {
                                                                      (booking.data.status != '2')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => toggleStatus(booking.data.booking_id, '3')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Cancel</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                              </Menu.Items>
                                                          </Menu>
                                                          :(booking.data.status == '2')?
                                                              <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                  Completed
                                                              </div>
                                                          :(booking.data.status == '3')?
                                                              <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                  Cancelled
                                                              </div>
                                                          :null
                                                      }
                                                      {
                                                          (booking.data.payment_status == 1)?
                                                              <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-green-400 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                  Paid
                                                              </div>
                                                          :(booking.data.payment_status == 0)?
                                                              (booking.data.transport_charge_payment_status == 3)?
                                                                  <div onClick={() => handleTransportChargePaymentLookUp(booking.data.booking_id, booking.data.transaction_reference)} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-yellow-400 text-white hover:bg-yellow-500 focus:outline-none focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                      Lookup
                                                                  </div>
                                                              :(booking.data.transport_charge_payment_status == 2)?
                                                                  <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-600 text-white focus:outline-none focus:ring-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                      Failed
                                                                  </div>
                                                            :
                                                                <div onClick={(e) => makeCompletedStatus(booking.data.booking_id, '2')} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-500 text-white focus:outline-none transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                    Unpaid
                                                                    </div>
                                                          :(booking.data.payment_status == 2)?
                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-600 text-white focus:outline-none focus:ring-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                              Failed
                                                          </div>
                                                          :(booking.data.payment_status == 3)?
                                                          <div onClick={() => handlePaymentLookUp(booking.data.booking_id, booking.data.transaction_reference)} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-yellow-400 text-white hover:bg-yellow-500 focus:outline-none focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                              Lookup
                                                          </div>
                                                          :null
                                                      }
                                                  </div>
                                              </div>

                                              <div class="grid md:grid-cols-2 gap-3">
                                                  <div>
                                                      <div class="grid space-y-3">
                                                          <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Billed to:
                                                              </dt>
                                                              <dd class="text-gray-800 dark:text-gray-200">
                                                                  <a class="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="#">
                                                                  {booking.data.name}
                                                                  </a>
                                                              </dd>
                                                          </dl>

                                                          <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Billing details:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  <span class="block font-semibold">{booking.data.name}</span>
                                                                  <address class="not-italic font-normal">
                                                                  {booking.data.address},<br/>
                                                                  {booking.data.phone},<br/>
                                                                  Zambia<br/>
                                                                  </address>
                                                              </dd>
                                                          </dl>

                                                          <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Booking Date:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  <span class="block font-semibold">{booking.data.date}</span>
                                                              </dd>
                                                          </dl>
                                                          <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Working Location:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  <span class="block font-semibold">{booking.data.working_location}</span>
                                                              </dd>
                                                          </dl>
                                                      </div>
                                                  </div>

                                                  <div>
                                                      <div class="grid space-y-3">
                                                          <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Transaction ID:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  {booking.data.transaction_reference}
                                                              </dd>
                                                              </dl>

                                                              <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Currency:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  ZMW - ZAMBIAN KWACHA
                                                              </dd>
                                                              </dl>

                                                              <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Date:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  {booking.data.created_at}
                                                              </dd>
                                                              </dl>

                                                              <dl class="grid sm:flex gap-x-3 text-xs">
                                                                  <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                      Billing method:
                                                                  </dt>
                                                                  <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                      {booking.data.payment_method}
                                                                  </dd>
                                                              </dl>

                                                              <dl class="grid sm:flex gap-x-3 text-xs">
                                                                  <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                      Booking Token:
                                                                  </dt>
                                                                  <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                      {booking.data.token}
                                                                  </dd>
                                                              </dl>
                                                      </div>
                                                  </div>
                                              </div>

                                              <div class="mt-6 border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                                                  <div class="hidden sm:grid sm:grid-cols-6">
                                                  <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">Service</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Service Type</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Vehicle Make</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Vehicle Model</div>
                                                  <div class="text-right text-xs font-medium text-gray-500 uppercase">Total Amount</div>
                                                  </div>

                                                  <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>               
                                                      <div class="grid text-sm grid-cols-3 sm:grid-cols-6 gap-2">
                                                          <div class="col-span-full sm:col-span-2">
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Service</h5>
                                                              <p class="text-xs text-gray-800 dark:text-gray-200">Booking service</p>
                                                          </div>
                                                          <div>
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Service Type</h5>
                                                              <p class="text-gray-800 text-xs dark:text-gray-200">{(booking.data.service_type == '1')? 'Full Servicing' :(booking.data.service_type == '2')? 'Partial Servicing' :(booking.data.service_type == '3')? 'Custom Servicing' : 'Invalid'}</p>
                                                          </div>
                                                          <div>
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Vehicle Make</h5>
                                                              <p class="text-gray-800 text-xs dark:text-gray-200">{booking.data.brand_name}</p>
                                                          </div>
                                                          <div>
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Vehicle Model</h5>
                                                              <p class="text-gray-800 text-xs dark:text-gray-200">{booking.data.brand_model}</p>
                                                          </div>
                                                          <div>
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Total Amount</h5>
                                                              <p class="sm:text-right text-xs text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(booking.data.service_charge))}</p>
                                                          </div>
                                                  </div>
                                              </div>
                                              <div class="mt-6 border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                                                  <div class="hidden sm:grid sm:grid-cols-6">
                                                  <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">Instructions</div>
                                                  </div>

                                                  <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>               
                                                      <div class="grid text-sm grid-cols-3 sm:grid-cols-6 gap-2">
                                                          <div class="col-span-full sm:col-span-2">
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Instructions</h5>
                                                              <p class="text-gray-800 text-xs dark:text-gray-200">{booking.data.instructions}</p>
                                                          </div>
                                                          
                                                  </div>
                                              </div>

                                                  <div class="mt-8 flex sm:justify-end">
                                                      <div class="w-full max-w-2xl sm:text-right space-y-2">
                                                          <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                                                              <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                  <dt class="col-span-3 text-gray-500">Subtotal:</dt>
                                                                  <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(booking.data.service_charge))}</dd>
                                                              </dl>

                                                              <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                  <dt class="col-span-3 text-gray-500">Transport:</dt>
                                                                  <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{(booking.data.transport_charge)? numberWithCommas(parseInt(booking.data.transport_charge)) : '00.00' }</dd>
                                                              </dl>

                                                              <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                  <dt class="col-span-3 text-gray-500">Total:</dt>
                                                                  <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(total())}</dd>
                                                              </dl>

                                                              <dl class="grid sm:grid-cols-5 font-semibold gap-x-3 text-xs">
                                                                  <dt class="col-span-3 font-semibold text-gray-500">Amount paid:</dt>
                                                                  <dd class="col-span-2 text-gray-800 dark:text-gray-200">K{numberWithCommas(paidAmount())}</dd>
                                                              </dl>

                                                          </div>
                                                      </div>
                                                  </div>
                                          </div>
                                      </div>
                                  :null
                              }
                      </div>
                      <div className="flex justify-center items-center pb-16">
                        <button onClick={handleCloseModal} className='px-4 py-2 bg-red-600 hover:bg-red-500 rounded-md text-white'>Close</button>
                      </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <main className="w-full flex-grow">
            <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                <h1 className="text-lg font-semibold">Bookings</h1>
            </div>
            <div className='flex justify-center items-center mt-2'>
              <form onSubmit={handleSearchBookings} autoComplete='off'>
                <div>
                  <div class="relative w-96 outline-none">
                    <input 
                    type="search" 
                    id="search-dropdown" 
                    name="search" 
                    class="block w-full px-5 py-2 outline-none text-gray-700 border-none focus:ring-rose-600 bg-gray-100 rounded" 
                    placeholder="Search booking..." 
                    value={input.search} 
                    onChange={handleChange}
                    required />
                    <button type="submit" class="absolute top-0 right-0 px-5 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-r-lg border border-gray-100 hover:bg-gray-200">
                        <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        <span class="sr-only">Search</span>
                    </button>
                  </div>
                  <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.search}</p>
                </div>
              </form>
            </div>
              <div className="w-full mt-4 p-2">
                <div className='flex justify-between items-center'>
                  <Link to="/admin/booking-locations" class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800" href="#">
                      Manage Locations
                  </Link>
                  <div className="flex justify-end items-center">
                    <ul className="flex border-b">
                      <li className="-mb-px mr-1" onClick={() => handleTabClick(1)}>
                        <a
                          className={`${
                            openTab === 1
                              ? 'border-l border-t border-r rounded-t text-gray-900 font-semibold'
                              : 'text-gray-500 hover:text-gray-500'
                          } bg-white inline-block py-2 px-4 font-semibold text-xs`}
                          href="#"
                        >
                          Pending
                        </a>
                      </li>
                      <li className="-mb-px mr-1" onClick={() => handleApprovedBookingsTabClick(2)}>
                        <a
                          className={`${
                            openTab === 2
                              ? 'border-l border-t border-r rounded-t text-gray-900 font-semibold'
                              : 'text-gray-500 hover:text-gray-500'
                          } bg-white inline-block py-2 px-4 font-semibold text-xs`}
                          href="#"
                        >
                          On going
                        </a>
                      </li>
                      <li className="mr-1" onClick={() => handleCompletedBookingsTabClick(3)}>
                        <a
                          className={`${
                            openTab === 3
                              ? 'border-l border-t border-r rounded-t text-gray-900 font-semibold'
                              : 'text-gray-500 hover:text-gray-500'
                          } bg-white inline-block py-2 px-4 font-semibold text-xs`}
                          href="#"
                        >
                          Completed
                        </a>
                      </li>
                      <li className="mr-1" onClick={() => handleCancelledBookingsTabClick(4)}>
                        <a
                          className={`${
                            openTab === 4
                              ? 'border-l border-t border-r rounded-t text-gray-900 font-semibold'
                              : 'text-gray-500 hover:text-gray-500'
                          } bg-white inline-block py-2 px-4 font-semibold text-xs`}
                          href="#"
                        >
                          Cancelled
                        </a>
                      </li>
                      <li className="mr-1" onClick={() => handleReceitedBookingsTabClick(6)}>
                        <a
                          className={`${
                            openTab === 6
                              ? 'border-l border-t border-r rounded-t text-gray-900 font-semibold'
                              : 'text-gray-500 hover:text-gray-500'
                          } bg-white inline-block py-2 px-4 font-semibold text-xs`}
                          href="#"
                        >
                          Receited
                        </a>
                      </li>
                      <li className="mr-1" onClick={() => handleAllBookingsTabClick(5)}>
                        <a
                          className={`${
                            openTab === 5
                            ? 'border-l border-t border-r rounded-t text-gray-900 font-semibold'
                            : 'text-gray-500 hover:text-gray-500'
                        } bg-white inline-block py-2 px-4 font-semibold text-xs`}
                          href="#"
                        >
                          All
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="bg-white p-6">
                  <div
                    id="tab-1"
                    className={openTab === 1 ? '' : 'hidden'}
                  >
                     {
                        (isSuccessPendingBookings)?
                        <div class="w-full mt-3">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Pending Bookings
                            </p>
                            <BookingsTableHelperView data={pendingBookings.data} prev={handlePrevPage} next={handleNextPage} view={handleViewBooking} isAll={false}/>
                        </div>
                        :(isLoadingPendingBookings)?
                            <LoadingSpinner />
                        :(isErrorPendingBookings)?
                          <div class="w-full mt-3">
                                <p class="text-md pb-3 flex items-center">
                                    <i class="fas fa-list mr-3"></i> Pending Bookings
                                </p>
                                <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                    <i class=""></i> No pending bookings found
                                </p>
                                <EmptyBookingsTableHelperView />
                            </div>
                        :null
                    }
                  </div>
                  <div
                    id="tab-2"
                    className={openTab === 2 ? '' : 'hidden'}
                  >
                    {
                        (approvedBookings)?
                        <div class="w-full mt-3">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Approved Bookings
                            </p>
                            <BookingsTableHelperView data={approvedBookings.data} prev={handlePrevPage} next={handleNextPage} view={handleViewBooking} isAll={false}/>
                        </div>
                        :(approvedBookings == "0")?
                            <LoadingSpinner />
                        :(!approvedBookings)?
                          <div class="w-full mt-3">
                                <p class="text-md pb-3 flex items-center">
                                    <i class="fas fa-list mr-3"></i> Approved Bookings
                                </p>
                                <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                    <i class=""></i> No approved bookings found
                                </p>
                                <EmptyBookingsTableHelperView />
                            </div>
                        :null
                    }
                  </div>
                  <div
                    id="tab-3"
                    className={openTab === 3 ? '' : 'hidden'}
                  >
                     {
                        (completedBookings)?
                        <div class="w-full mt-3">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Completed Bookings
                            </p>
                            <BookingsTableHelperView data={completedBookings.data} prev={handlePrevPage} next={handleNextPage} view={handleViewBooking} isAll={false}/>
                        </div>
                        :(completedBookings == "0")?
                            <LoadingSpinner />
                        :(!completedBookings)?
                          <div class="w-full mt-3">
                                <p class="text-md pb-3 flex items-center">
                                    <i class="fas fa-list mr-3"></i> Completed Bookings
                                </p>
                                <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                    <i class=""></i> No completed bookings found
                                </p>
                                <EmptyBookingsTableHelperView />
                            </div>
                        :null
                    }
                  </div>
                  <div
                    id="tab-3"
                    className={openTab === 4 ? '' : 'hidden'}
                  >
                    {
                        (cancelledBookings)?
                        <div class="w-full mt-3">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Cancelled Bookings
                            </p>
                            <BookingsTableHelperView data={cancelledBookings.data} prev={handlePrevPage} next={handleNextPage} view={handleViewBooking} isAll={false}/>
                        </div>
                        :(cancelledBookings == "0")?
                            <LoadingSpinner />
                        :(!cancelledBookings)?
                          <div class="w-full mt-3">
                                <p class="text-md pb-3 flex items-center">
                                    <i class="fas fa-list mr-3"></i> Cancelled Bookings
                                </p>
                                <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                    <i class=""></i> No cancelled bookings found
                                </p>
                                <EmptyBookingsTableHelperView />
                            </div>
                        :null
                    }
                  </div>
                  <div
                    id="tab-3"
                    className={openTab === 6 ? '' : 'hidden'}
                  >
                    {
                        (receitedBookings)?
                        <div class="w-full mt-3">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Receited Bookings
                            </p>
                            <BookingsTableHelperView data={receitedBookings.data} prev={handlePrevPage} next={handleNextPage} view={handleViewBooking} isAll={false}/>
                        </div>
                        :(receitedBookings == "0")?
                            <LoadingSpinner />
                        :(!receitedBookings)?
                          <div class="w-full mt-3">
                                <p class="text-md pb-3 flex items-center">
                                    <i class="fas fa-list mr-3"></i> Receited Bookings
                                </p>
                                <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                    <i class=""></i> No receited bookings found
                                </p>
                                <EmptyBookingsTableHelperView />
                            </div>
                        :null
                    }
                  </div>
                  <div
                    id="tab-3"
                    className={openTab === 5 ? '' : 'hidden'}
                  >
                    {
                        (searchData)?
                          <div class="w-full mt-1">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Search Results
                            </p>
                            <BookingsTableHelperView data={searchData} view={handleViewBooking} isAll={true} />
                          </div>
                        :(!searchData && allBookings)?
                          <div class="w-full mt-1">
                              <p class="text-md pb-3 flex items-center">
                                  <i class="fas fa-list mr-3"></i> All Bookings
                              </p>
                              <BookingsTableHelperView data={allBookings.data} prev={handlePrevPage} next={handleNextPage} view={handleViewBooking} isAll={true} />
                          </div>
                        :(!searchData == '0')?
                          <LoadingSpinner />
                        :(allBookings == '0')?
                          <LoadingSpinner />
                        :(!allBookings && input.search == '')?
                          <div class="w-full mt-1">
                                <p class="text-md pb-3 flex items-center">
                                    <i class="fas fa-list mr-3"></i> All Bookings
                                </p>
                                <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                    <i class=""></i> No bookings found
                                </p>
                                <EmptyBookingsTableHelperView />
                            </div>
                        :(!searchData)?
                          <div class="w-full mt-1">
                              <p class="text-md pb-3 flex items-center">
                                  <i class="fas fa-list mr-3"></i> Search Results
                              </p>
                              <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                  <i class=""></i> No search found
                              </p>
                              <EmptyBookingsTableHelperView />
                          </div>
                        :null
                    }
                  </div>
                </div>
              </div>
            </main>
          </div>
    )
}

export default AdminUserBookingsView;
