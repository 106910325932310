import { apiSlice } from "../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: ({paginate, search, page}) => (page == "")? `/user/users/${search}/${paginate}` : `user/users/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            // providesTags: ['User']
        }),
        getAgentUsers: builder.query({
            query: ({paginate, search, page}) => (page == "")? `/user/agent-users/${search}/${paginate}` : `user/agent-users/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['AgentUser']
        }),
        agentUserRegister: builder.mutation({
            query: input => ({
                url: '/user/agent-user-register',
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['ClientSalesUser']
        }),
        mobileAgentUserRegister: builder.mutation({
            query: input => ({
                url: '/user/mobile-agent-user-register',
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['ClientSalesUser']
        }),
        clientManagerUserRegister: builder.mutation({
            query: input => ({
                url: '/user/register-client-manager',
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['ClientManagerUser']
        }),
        clientDataAnalystManagerUserRegister: builder.mutation({
            query: input => ({
                url: '/user/register-client-data-analyst-manager',
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['ClientAnalystManagerUser']
        }),
        clientAccountingUserRegister: builder.mutation({
            query: input => ({
                url: '/user/register-client-accounting-user',
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['ClientAccountingUser']
        }),
        clientMarketingUserRegister: builder.mutation({
            query: input => ({
                url: '/user/register-client-marketing-user',
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['ClientMarketingUser']
        }),
        getUserProfileById: builder.query({
            query: (id) => `/user/view-user-profile/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['User']
        }),
        getAgentUserProfileById: builder.query({
            query: (id) => `/user/view-agent-user-profile/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['AgentUser']
        }),
        editUserPassword: builder.mutation({
            query: (input) => ({
                url: `user/edit-passowrd`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['User']
        }),
        editUserProfile: builder.mutation({
            query: (input) => ({
                url: `user/user-edit/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['User']
        }),
        editAgentUserProfile: builder.mutation({
            query: (input) => ({
                url: `user/agent-user-edit/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['ClientSalesUser']
        }),
        getClientManagerusersWithoutPagination: builder.query({
            query: (client_id) => `/user/client-manager-users-no-pagination/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientSalesUser']
        }),
        getClientUnpaginatedManagerUserSalesUsers: builder.query({
            query: (admin_user_id) => `/user/client-manager-sales-users/${admin_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientSalesUser']
        }),
        getAllClientUnpaginatedManagerUserSalesUsers: builder.query({
            query: (client_id) => `/user/all-client-manager-sales-users/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientSalesUser']
        }),
        getAllClientUnpaginatedMobileSalesUsers: builder.query({
            query: (client_id) => `/user/client-unpaginated-mobile-sales-users/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientSalesUser']
        }),
        editClientManagerUser: builder.mutation({
            query: (input) => ({
                url: `user/edit-client-manager-profile/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['ClientManagerUser']
        }),
        editClientDataAnalystManagerUser: builder.mutation({
            query: (input) => ({
                url: `user/edit-client-data-analyst-manager-user-profile/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['ClientAnalystManagerUser']
        }),
        editClientAccountingUser: builder.mutation({
            query: (input) => ({
                url: `user/edit-client-accounting-user-profile/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['ClientAccountingUser']
        }),
        editClientMarketingUser: builder.mutation({
            query: (input) => ({
                url: `user/edit-client-marketing-user-profile/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['ClientMarketingUser']
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `user/delete-user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User']
        }),
        deleteClientManagerUser: builder.mutation({
            query: (id) => ({
                url: `user/delete-client-user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ClientManagerUser']
        }),
        deleteClientAccountingUser: builder.mutation({
            query: (id) => ({
                url: `user/delete-client-user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ClientAccountingUser']
        }),
        deleteClientMarketingUser: builder.mutation({
            query: (id) => ({
                url: `user/delete-client-user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ClientMarketingUser']
        }),
        deleteAgentUser: builder.mutation({
            query: (id) => ({
                url: `user/delete-agent-user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AgentUser']
        }),
        refreshToken: builder.mutation({
            query: (input) => ({
                url: `user/refresh`,
                method: 'POST',
                body: { ...input }
            }),

        }),
        logout: builder.mutation({
            query: () => ({
                url: `user/logout`,
                method: 'POST',
            }),
            invalidatesTags: ['User']
        }),
    })
})

export const {
    useGetUsersQuery,
    useGetAgentUsersQuery,
    useAgentUserRegisterMutation,
    useGetUserProfileByIdQuery,
    useRefreshTokenMutation,
    useGetClientManagerusersWithoutPaginationQuery,
    useClientAccountingUserRegisterMutation,
    useDeleteClientMarketingUserMutation,
    useDeleteClientManagerUserMutation,
    useGetClientUnpaginatedManagerUserSalesUsersQuery,
    useGetAllClientUnpaginatedManagerUserSalesUsersQuery,
    useClientMarketingUserRegisterMutation,
    useMobileAgentUserRegisterMutation,
    useEditClientDataAnalystManagerUserMutation,
    useClientDataAnalystManagerUserRegisterMutation,
    useEditClientAccountingUserMutation,
    useGetAllClientUnpaginatedMobileSalesUsersQuery,
    useEditClientMarketingUserMutation,
    useDeleteClientAccountingUserMutation,
    useGetAgentUserProfileByIdQuery,
    useClientManagerUserRegisterMutation,
    useDeleteUserMutation,
    useDeleteAgentUserMutation,
    useEditClientManagerUserMutation,
    useEditUserProfileMutation,
    useEditAgentUserProfileMutation,
    useLogoutMutation,
    useEditUserPasswordMutation,
} = usersApiSlice

