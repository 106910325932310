import {  PencilIcon, UserCircleIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../../Features/auth/authSlice';

import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import swal from 'sweetalert';
import { useEditUserPasswordMutation, useEditUserProfileMutation } from '../../Features/usersSlice';

function UserProfileView(props) {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);

    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const [openEditModal, setOpenEditModal] = useState(false)

    const [input, setInput] = useState({
        password: '',
        id: user.id,
        new_password: '',
        fname: '',
        lname: '',
        email: '',
        phone: '',
      })
  
      const [errors, setErrors] = useState({
        password: '',
        new_password: '',
        fname: '',
        lname: '',
        email: '',
        phone: '',
      })
        
      const handleChange = (e)=>{
          setInput({...input, [e.target.name]: e.target.value})
      }

      const [editUserPassword, {isLoading, isSuccess, isError}] = useEditUserPasswordMutation()

      const handleSubmit = async(e)=>{
          e.preventDefault();
          try {
              const resp = await editUserPassword(input).unwrap()
              handleCloseModal()
              swal("Success",resp.message,"success");
          } catch (err) {
              if(err.data.hasOwnProperty('errors') == true){
                  setErrors({...errors,  
                    password: err.data.errors.password,
                    new_password: err.data.errors.new_password,
                  })
              }else if (err.data.hasOwnProperty('error') == true){
                  swal("Error",err.data.error,"error");
              }
          }           
      }

      const [editUserProfile] = useEditUserProfileMutation()

      const handleSubmitEditUserProfile = async(e)=>{
          e.preventDefault();
          try {
              const resp = await editUserProfile(input).unwrap()
              handleCloseEditModal()
              swal("Success",resp.message,"success");
          } catch (err) {
              if(err.data.hasOwnProperty('errors') == true){
                  setErrors({...errors,  
                    fname: err.data.errors.fname,
                    lname: err.data.errors.lname,
                    email: err.data.errors.email,
                    phone: err.data.errors.phone,
                  })
              }else if (err.data.hasOwnProperty('error') == true){
                  swal("Error",err.data.error,"error");
              }
          }           
      }

    //   const [getCategoryByIdMutation, {isLoading: isLoadingGetCategoryById, isSuccess: isSuccessGetCategoryById}] = useGetCategoryByIdMutationMutation()

    const handleEdit = async()=>{
    setInput({...input,  
        fname: user.fname,
        lname: user.lname,
        phone: user.phone,
        email: user.email,
    })
    setOpenEditModal(true)
    }

    const handleCloseModal = (e)=>{
    setInput({...input,  
        password: '',
        new_password: '',
        fname: '',
        lname: '',
        email: '',
        phone: '',
    })

    setErrors({...errors,  
        password: '',
        new_password: '',
        fname: '',
        lname: '',
        email: '',
        phone: '',
    })
    setOpen(false)
    }

    const handleCloseEditModal = (e)=>{
    setInput({...input,  
        password: '',
        new_password: '',
        fname: '',
        lname: '',
        email: '',
        phone: '',
    })

    setErrors({...errors,  
        password: '',
        new_password: '',
        fname: '',
        lname: '',
        email: '',
        phone: '',
    })
    setOpenEditModal(false)
    }


    const back = ()=> navigate(-1);

        return (
            <div className="w-full flex-grow p-6">
                <Transition.Root show={openEditModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseEditModal}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200 uppercase">
                                    Edit Details
                                </p>
                                <form autoComplete='off'>
                                <div class="pr-6 pl-6 pt-6">
                                    <label className="block text-sm text-gray-600" for="fname">First Name</label>
                                    <input 
                                    class="w-full px-5 py-2 outline-none border-none bg-gray-200 rounded"
                                    type="text"
                                    placeholder=''
                                    name="fname" 
                                    id="fname" 
                                    value={input.fname}
                                    onChange={handleChange}
                                />
                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.fname}</p>
                                </div>

                                <div class="pr-6 pl-6 pt-4">
                                    <label className="block text-sm text-gray-600" for="lname">Last Name</label>
                                    <input 
                                    class="w-full px-5 py-2 outline-none border-none bg-gray-200 rounded"
                                    type="text"
                                    placeholder=''
                                    name="lname" 
                                    id="lname" 
                                    value={input.lname}
                                    onChange={handleChange}
                                />
                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.lname}</p>
                                </div>

                                <div class="pr-6 pl-6 pt-4">
                                    <label className="block text-sm text-gray-600" for="phone">Phone Number</label>
                                    <input 
                                    class="w-full px-5 py-2 outline-none border-none bg-gray-200 rounded"
                                    type="number"
                                    placeholder=''
                                    name="phone" 
                                    id="phone" 
                                    value={input.phone}
                                    onChange={handleChange}
                                />
                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.phone}</p>
                                </div>

                                <div class="pr-6 pl-6 pt-4 pb-5">
                                    <label className="block text-sm text-gray-600" for="email">Email Address</label>
                                    <input 
                                    class="w-full px-5 py-2 outline-none border-none bg-gray-200 rounded"
                                    type="email"
                                    placeholder=''
                                    name="email" 
                                    id="email" 
                                    value={input.email}
                                    onChange={handleChange}
                                />
                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.email}</p>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-700 sm:ml-3 sm:w-auto"
                                    onClick={handleSubmitEditUserProfile}
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    onClick={handleCloseEditModal}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                                </div>
                                </form>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
                    </Transition.Root>

                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseModal}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200 uppercase">
                                    Change Password
                                </p>
                                <form autoComplete='off'>
                                <div class="p-6">
                                    <label className="block text-sm text-gray-600" for="password">Current Password</label>
                                    <input 
                                    class="w-full px-5 py-2 outline-none border-none bg-gray-200 rounded"
                                    type="password"
                                    placeholder=''
                                    name="password" 
                                    id="password" 
                                    value={input.password}
                                    onChange={handleChange}
                                />
                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.password}</p>
                                </div>
                                <div class="pr-6 pl-6 pb-5">
                                    <label className="block text-sm text-gray-600" for="new_password">New Password</label>
                                    <input 
                                    class="w-full px-5 py-2 outline-none border-none bg-gray-200 rounded"
                                    type="password"
                                    placeholder=''
                                    name="new_password" 
                                    id="new_password" 
                                    value={input.new_password}
                                    onChange={handleChange}
                                />
                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.new_password}</p>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-700 sm:ml-3 sm:w-auto"
                                    onClick={handleSubmit}
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    onClick={handleCloseModal}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                                </div>
                                </form>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
                    </Transition.Root>

                <div className='flex items-center justify-between pb-2'>
                    <h1 className="text-lg text-gray-800 uppercase">User Profile</h1>
                    <button onClick={handleEdit} class="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-rose-600 bg-none px-8 py-1 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-rose-700">
                        <PencilIcon className='h-4 mr-1'/>
                        <div className='text-sm'>Edit</div>
                    </button>
                </div>
                <div className="">
                   <div className="md:flex justify-center mt- p-8">
                        <div className="bg-gray-200 shadow-sm pr-0 pl-0 pt-2 pb-0 rounded-md">
                            <div className="flex justify-center md:pr-60 md:pl-60">
                                <UserCircleIcon className="h-20 text-gray-500" />
                            </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-bold text-xs">Name</p>
                                    <p className="mr-20 p-2 text-xs uppercase">{user.fname} {user.lname}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-bold text-xs">Email</p>
                                    <p className="mr-20 p-2 text-xs">{user.email}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-bold text-xs">Phone No.</p>
                                    <p className="mr-20 p-2 text-xs">{user.phone}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-semibold text-sm">ID</p>
                                    <p className="mr-20 p-2 text-sm">{user.user_id}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-bold text-xs">Gender</p>
                                    <p className="mr-20 p-2 text-xs">{user.gender}</p>
                                </div>
                                <div onClick={() => setOpen(true)} className="flex justify-center cursor-pointer mt-4 bg-rose-500 hover:bg-rose-600 border-t border-gray-300 p-4">
                                    <div className="flex flex-col">
                                        <label className="text-xs text-gray-100 cursor-pointer">Change password</label>
                                    </div>                           
                                 </div>
                        </div>
                   </div>
                </div>
            </div>
        )
}

export default UserProfileView;
