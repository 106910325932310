import { createSlice } from "@reduxjs/toolkit"

const clientObjectSlice = createSlice({
    name: 'clientObject',
    initialState: { clientObj: null },
    reducers: {
        setClientObjec: (state, action) => {
            const { client } = action.payload
            state.clientObj = client
        },
        deleteClientObject: (state, action) => {
            state.clientObj = null
        }
    },
})

export const { setClientObject, deleteClientObject } = clientObjectSlice.actions

export default clientObjectSlice.reducer

export const selectCurrentClientObject = (state) => state.clientObject.clientObj

