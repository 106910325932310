import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import swal from 'sweetalert';
import LoadingSpinner from '../LoadingSpinner';
import {useDeleteBrandMutation, } from '../../Features/brandsSlice';
import { useGetAdminAllProductsQuery, useGetProductByIdMutationMutation, useNextPageMutation, useSeachAdminProductsMutation, useSeachProductsMutation, useToggleProductStatusMutation } from '../../Features/productsSlice';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { PencilIcon, PlusIcon, XIcon } from '@heroicons/react/solid';


function AdminUserProductsView({props}) {
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState(null);
    const [page, setPage] = useState(1);
    const pagination = 12;

    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const [product, setProduct] = useState(null);
    const [activeImage, setActiveImage] = useState(false)

    const [input, setInput] = useState({
      search: '',
      pagination: pagination,
    })

    const [errors, setErrors] = useState({
      search: '',
    })

    const handleChange = (e)=>{
      setInput({...input, [e.target.name]: e.target.value})
    }
      
    const {
      data: products,
      isLoading: isLoadingProducts,
      isSuccess: isSuccessProducts,
      isError: isErrorProducts,
      error: errorProducts,
      refetch
    } = useGetAdminAllProductsQuery({pagination: pagination, page: page})

    const [seachAdminProducts] = useSeachAdminProductsMutation()

    const handleSearchData = async(e) => {
      e.preventDefault();
      try {
        setSearchData(0)
        const resp = await seachAdminProducts({input: input, page: 1}).unwrap()
        setSearchData(resp)
      } catch (err) {
        swal("Search not found");
        setSearchData(null)
      }
    };

    const [nextPage] = useNextPageMutation()

    const handleNextPage = async() => {
      try {
        if(!searchData){
            setPage(page + 1)
            refetch()
        }else{
          const resp = await nextPage(searchData.data.next_page_url).unwrap()
          setSearchData(resp)
        }
    }catch (err) {
        swal("Error",err.data.error,"error");
    }
    };

    const handlePrevPage = async() => {
      try {
        if(!searchData){
            setPage(page - 1)
            refetch()
        }else{
          const resp = await nextPage(searchData.data.prev_page_url).unwrap()
          setSearchData(resp)
        }
    }catch (err) {
        swal("Error",err.data.error,"error");
    }
    };

    const [deleteBrand, {isLoading: isLoadingDelete, isSuccess: isSuccessDelete}] = useDeleteBrandMutation()

    const handleDelete = async(e, id)=>{
      if(window.confirm("Are you sure you want to delete this item?")){
        try {
            const resp = await deleteBrand(id).unwrap()
            swal("Success",resp.message,"success");
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
      }
    }

    const trancateText = (str)=> {
      return str.length > 40 ? str.substring(0, 40) + "..." : str;
  }

  const numberWithCommas = (x)=>{
    if(x !==null){
      const formatedNumber = x.toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              });
      return formatedNumber;
    }
    return null;
}

const [getProductByIdMutation] = useGetProductByIdMutationMutation()

    const handleViewProduct = async(id)=>{
        try {
            const resp = await getProductByIdMutation(id).unwrap()
            setProduct(resp)
            setActiveImage(resp.data['0'].product.image);
            setOpen(true)
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
    }

    const [toggleProductStatus] = useToggleProductStatusMutation()

    const handleToggleProductStatus = async(id)=>{
      if(window.confirm("Are you sure you want to toggle product status?")){
        try {
            const resp = await toggleProductStatus(id).unwrap()
            swal("Success",resp.message,"success");
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
      }
    }

    const handleCloseModal = ()=>{
      setOpen(false)
    }

    return (
        <main className='w-full flex-grow'>

            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative md:w-3/4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                        {
                            (product)?
                                (product.data['1'])?
                                <div className='flex justify-center'>
                                  <div className="w-full lg:w-2/2 mt-2">
                                    <div className="bg-white ">
                                    <section class="py-6"> 
                                      <div class="container mx-auto px-4">
                                        <nav class="flex">
                                          <ol role="list" class="flex items-center">
              
                                            <li class="text-left">
                                              <div class="flex items-center">
                                                <span class="mx-2 text-gray-400"></span>
                                                <div onClick={handleCloseModal} class="-m-1 text-gray-400 cursor-pointer hover:text-gray-700 hover:bg-gray-200 rounded-full p-3">
                                                  <XIcon className='h-4'/>
                                                </div>
                                              </div>
                                            </li>
                                          </ol>
                                        </nav>
              
                                        <div class="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                                          <div class="lg:col-span-3 lg:row-end-1">
                                            <div class="lg:flex lg:items-start">
                                              <div class="lg:order-2 lg:ml-5">
                                                <div class="max-w-xl overflow-hidden rounded-lg">
                                                  <img class="h-full w-full max-w-full object-contain" src={`${(activeImage)? 'https://demoapi.autozedltd.com/uploads/'+activeImage : 'https://demoapi.autozedltd.com/uploads/'+product.data['0'].product.image}`} alt="" />
                                                </div>
                                              </div>
              
                                              <div class="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                                                <div class="flex flex-row items-start lg:flex-col">
                                                  {
                                                    (product.data['1'].product_images)?
                                                    product.data['1'].product_images.map(data => (
                                                        <button onClick={(e) => setActiveImage(data.image_name)} type="button" class={`${(activeImage == data.image_name)? 'border-2 border-gray-400' : ''} flex-0 aspect-square mb-3 h-20 w-28 overflow-hidden rounded-lg text-center`}>
                                                          <img class="h-full w-full object-contain" src={`https://demoapi.autozedltd.com/uploads/`+data.image_name} alt="" />
                                                        </button>
                                                      ))
                                                    :null
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
              
                                          <div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                                            <h1 class="text-gray-800 title-font text-lg mt-3">{product.data['0'].product.name}</h1>
              
                                            <div class="mt-5 flex items-center border-b pb-4">
                                              <div class="flex items-center">
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                              </div>
                                              <p class="ml-2 text-sm font-medium text-gray-500">4 Reviews</p>
                                            </div>
              
                                            <h2 class="mt-3 text-xs uppercase text-gray-500">Product Information</h2>
                                            <div class="mt-3 flex select-none items-center gap-1">
                                              <div className="flex flex-col text-xs gap-y-2">
                                                <div className="flex items-center gap-x-6 justify-start">
                                                  <p className="mr-4 text-gray-600">Brand:</p>
                                                  <p className="text-gray-700">{(product.data['0'].product.brand != null)? product.data['0'].product.brand :(product.data['0'].product.custome_brand != null)? product.data['0'].product.custome_brand : 'None'}</p>
                                                </div>
                                                <div className="flex items-center gap-x-6 justify-start">
                                                  <p className="mr-4 text-gray-600">Category:</p>
                                                  <p className="text-gray-700">{product.data['0'].product.category}</p>
                                                </div>
                                                <div className="flex items-center gap-x-6 justify-start">
                                                  <p className="mr-4 text-gray-600">Product No:</p>
                                                  <p className="text-gray-700">{(product.data['0'].product.sku)? product.data['0'].product.sku : '---'}</p>
                                                </div>
                                                <div className="flex items-center gap-x-6 justify-start">
                                                  <p className="mr-4 text-gray-600">Quantity:</p>
                                                  <p className="text-gray-700">{(product.data['0'].product.has_inventory)? (product.data['0'].product.quantity < 1)? product.data['0'].product.quantity+ ' (On backorders)' : product.data['0'].product.quantity :(product.data['0'].product.stock_status == '1')? 'In stock' :(product.data['0'].product.stock_status == '2')? 'Out of stock' :(product.data['0'].product.stock_status == '3')? 'On backorders' : null}</p>
                                                </div>
                                              </div>
                                            </div>

                                            <h2 class="mt-4 text-xs text-gray-500">Product Description</h2>
                                            <div class="mt-3 flow-root text-xs text-gray-700">
                                              {product.data['0'].product.description}
                                            </div>

                                            <h2 class="mt-8 text-base text-gray-900"></h2>
                                            <div class="mt-3 flex select-none flex-wrap items-center gap-1">
                                              <label class="">
                                                <input type="radio" name="subscription" value="4 Months" class="peer sr-only" />
                                                <p onClick={(e) => handleToggleProductStatus(product.data['0'].product.product_id)} class={`${(product.data['0'].product.status == 0)? 'bg-green-500 hover:bg-green-600' : 'bg-gray-400 hover:bg-gray-500'} text-white rounded-lg border border-gray-300 cursor-pointer px-6 py-2`}>
                                                  {(product.data['0'].status == 0)? 'Active' : 'Disabled'}
                                                </p>
                                              </label>
                                              <label class="">
                                                <input type="radio" name="subscription" value="8 Months" class="peer sr-only" checked />
                                                <p class=" bg-red-500 hover:bg-red-600 rounded-lg border border-gray-100 cursor-pointer text-white px-6 py-2">Delete</p>
                                              </label>
                                            </div>
              
                                            <div class="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                                              <div class="flex space-x-2 items-end">
                                                <h1 class="text-2xl text-red-500">ZK{numberWithCommas(parseInt(product.data['0'].product.price))}</h1>
                                                <span class="text-lg text-gray-500 line-through">K{numberWithCommas(parseInt(product.data['0'].product.old_price))}</span>
                                              </div>
              
                                              <Link to={`/admin/edit-product/${product.data['0'].product.product_id}`} class="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-rose-600 bg-none px-8 py-1 text-center text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-rose-700">
                                                <PencilIcon className='h-4 mr-1'/>
                                                <div>Edit</div>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    </div>
                                  </div>
                                </div>
                                :<div className='text-sm '>Add to cart</div>
                            :<div className='text-sm '>Add </div>
                            }
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

          <div className='flex items-center justify-between'>
            <div className="w-full flex-grow">
                <div className='w-full flex items-center justify-center bg-gray-900 text-white p-4'>
                    <h1 className="text-lg font-semibold">Products</h1>
                </div>
            </div>
            </div>
            <div className='flex justify-center items-center mt-4'>
            <form onSubmit={handleSearchData} autoComplete='off'>
                <div>
                  <div class="relative w-96 outline-none">
                    <input 
                    type="search" 
                    id="search-dropdown" 
                    name="search" 
                    class="block w-full px-5 py-2 outline-none text-gray-700 border-none focus:ring-rose-600 bg-gray-100 rounded" 
                    placeholder="Search..." 
                    value={input.search} 
                    onChange={handleChange}
                    required />
                    <button type="submit" class="absolute top-0 right-0 px-5 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-r-lg border border-gray-100 hover:bg-gray-200">
                        <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        <span class="sr-only">Search</span>
                    </button>
                  </div>
                  <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.search}</p>
                </div>
              </form>
            </div>
            {
              
              (isSuccessProducts)?
                    (searchData)?
                      <div className='flex justify-center'>
                        <div className="w-full lg:w-2/2 mt-4 p-4 rounded-md">
                          <div className="bg-white p-4 rounded-md">
                            <Link
                              to="/admin/create-products"
                              type="button"
                              class="py-2 px-1 sm:ml-4 inline-flex justify-center items-center gap-1 rounded-md border font-medium bg-rose-600 text-white shadow-xs align-middle hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                            >
                              <PlusIcon className='h-5 text-white'/>
                              Add Product
                            </Link>
                          <section class="text-gray-600 body-font">
                            <div class="container px-5 py-3 mx-auto">
                              <div class="flex flex-wrap">
                              {
                                (searchData.data.data.length > 0)?
                                searchData.data.data.map(data => (
                                  <div class={`border-2 bg-white border-gray-200 hover:shadow-xl round-md border-opacity-60 lg:w-1/3 md:w-1/2 w-full`}
                                      >
                                        <a class={`block relative h-48 rounded overflow-hidden`}
                                          
                                        >
                                          <img 
                                              className="object-contain h-60 w-full rounded-t-md shodow-lg group-hover:opacity-50"
                                              src={`https://demoapi.autozedltd.com/uploads/`+data.image} 
                                              alt="Image" 
                                          />
                                        </a>
                                        <div class="pl-2 mt-4">
                                            <h2 class="flex items-center space-x-2 text-gray-600 title-font text-lg font-medium">
                                              <div className='text-md text-red-500'>ZK {numberWithCommas(parseInt(data.price))}</div>
                                              <div className='text-xs line-through'> ZK{numberWithCommas(parseInt(data.old_price))}</div>
                                            </h2>
                                            <h2 class="text-gray-800 title-font text-md mt-3">{trancateText(data.name)}</h2>
                                            <h2 class="text-gray-400 font-semibold text-xs mt-2">{(data.brand != null)? data.brand :(data.custome_brand != null)? data.custome_brand : 'None'}</h2>
                                            <h2 class="text-gray-400 title-font text-xs font-medium">{data.category}</h2>
                                            <div class="flex items-center">
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <p class="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">4.95 out of 5</p>
                                            </div>
                                            <p class="text-gray-500 title-font text-md font-sm pt-2 pb-2 h-12">{trancateText(data.description)}</p>
                                        </div>
                                        <div className="cursor-pointer">
                                          <div onClick={(e)=>handleViewProduct(data.product_id)} className="inline-flex w-full justify-center bg-rose-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-600 mt-3 uppercase">View Details</div>
                                        </div>
                                      </div>
                                ))
                                :null
                              }
                              </div>
                              {
                                (searchData)?
                                  <div className='w-full flex justify-center items-center space-x-3 pt-10 pr-16'>
                                      {
                                          (searchData.data.prev_page_url)?
                                              <ChevronLeftIcon onClick={handlePrevPage} className='h-8 rounded-md text-gray-500 cursor-pointer hover:text-gray-400'/>
                                          :
                                              <ChevronLeftIcon className='h-8 rounded-md text-gray-300 cursor-pointer'/>
                                      }
                                      <div className='text-gray-500'>{searchData.data.current_page} / {searchData.data.last_page}</div>
                                      {
                                          (searchData.data.last_page > searchData.data.current_page)?
                                              <ChevronRightIcon onClick={handleNextPage} className='h-8 rounded-md text-gray-500 cursor-pointer hover:text-gray-400' />
                                              :
                                              <ChevronRightIcon className='h-8 rounded-md text-gray-300 cursor-pointer' />
                                      }
                                  </div>
                                :null
                              }
                            </div>
                          </section>
                          </div>
                        </div>
                      </div>
                    :(!searchData && products)?
                      <div className='flex justify-center'>
                            <div className="w-full lg:w-2/2 mt-4 p-4 rounded-md">
                              <div className="bg-white p-1 rounded-md">
                                <Link
                                  to="/admin/create-products"
                                  type="button"
                                  class="py-2 px-1 sm:ml-4 inline-flex justify-center items-center gap-1 rounded-md border font-medium bg-rose-600 text-white shadow-sm align-middle hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-xs dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                                >
                                  <PlusIcon className='h-5 text-white'/>
                                  Add Product
                                </Link>
                              <section class="text-gray-600 body-font">
                                <div class="container px-5 py-3 mx-auto">
                                  <div class="flex flex-wrap">
                                  {
                                    (products.data.data.length > 0)?
                                    products.data.data.map(data => (
                                      <div class={`border-2 bg-white border-gray-200 hover:shadow-xl round-md border-opacity-60 lg:w-1/3 md:w-1/2 w-full`}
                                      >
                                        <a class={`block relative h-48 rounded overflow-hidden`}
                                        >
                                          <img 
                                              className="object-contain min-h-60 h-60 w-full rounded-t-md shodow-lg group-hover:opacity-50"
                                              src={`https://demoapi.autozedltd.com/uploads/`+data.image} 
                                              alt="Image" 
                                          />
                                        </a>
                                        <div class="pl-2 mt-4">
                                            <h2 class="flex items-center space-x-2 text-gray-600 title-font text-lg font-medium">
                                              <div className='text-md text-red-500'>ZK {numberWithCommas(parseInt(data.price))}</div>
                                              <div className='text-xs line-through'> ZK{numberWithCommas(parseInt(data.old_price))}</div>
                                            </h2>
                                            <h2 class="text-gray-800 title-font text-md mt-3">{trancateText(data.name)}</h2>
                                            <h2 class="text-gray-400 font-semibold text-xs mt-2">{(data.brand != null)? data.brand :(data.custome_brand != null)? data.custome_brand : 'None'}</h2>
                                            <h2 class="text-gray-400 title-font text-xs font-medium">{data.category}</h2>
                                            <div class="flex items-center">
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <p class="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">4.95 out of 5</p>
                                            </div>
                                            <p class="text-gray-500 title-font text-md font-medium pt-2 pb-2 h-12">{trancateText(data.description)}</p>
                                        </div>
                                        <div className="cursor-pointer">
                                        <div onClick={(e)=>handleViewProduct(data.product_id)} className="inline-flex w-full justify-center bg-rose-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-600 mt-3 uppercase">View Details</div>
                                        </div>
                                      </div>
                                    ))
                                    :null
                                  }
                                  </div>
                                  
                                  <div className='w-full flex justify-center items-center space-x-3 pt-10 pr-16'>
                                      {
                                          (products.data.prev_page_url)?
                                              <ChevronLeftIcon onClick={handlePrevPage} className='h-8 rounded-md text-gray-500 cursor-pointer hover:text-gray-400'/>
                                          :
                                              <ChevronLeftIcon className='h-8 rounded-md text-gray-300 cursor-pointer'/>
                                      }
                                      <div className='text-gray-500'>{products.data.current_page} / {products.data.last_page}</div>
                                      {
                                          (products.data.last_page > products.data.current_page)?
                                              <ChevronRightIcon onClick={handleNextPage} className='h-8 rounded-md text-gray-500 cursor-pointer hover:text-gray-400' />
                                              :
                                              <ChevronRightIcon className='h-8 rounded-md text-gray-300 cursor-pointer' />
                                      }
                                  </div>
                                </div>
                              </section>
                              </div>
                            </div>
                          </div>
                    :(!searchData == '0')?
                    <div>
                    failed
                  </div>
                    :null
                :(isLoadingProducts)?
                  <LoadingSpinner />
                :(isErrorProducts)?
                  <div>
                    <div className='flex justify-center'>
                        <div className="w-full lg:w-2/2 mt-4 p-4 rounded-md">
                          <div className="bg-white p-4 rounded-md">
                            <Link
                              to="/admin/create-products"
                              type="button"
                              class="py-2 px-4 sm:ml-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                            >
                              Add Product
                            </Link>
                          </div>
                        </div>
                    </div>
                    failed
                  </div>
              :null
            }
        </main>
    )
}

export default AdminUserProductsView;
