import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react'
import { useGetAllProductsQuery } from '../../Features/productsSlice';
import { useClearSaleMutation, useGetActiveSalesQuery, useGetAllSalesMutationMutation, useGetClearedSalesMutationMutation, useGetSalesByIdMutationMutation, useNextPageMutation, useSearchSalesMutation, useUpdatePaymentStatusLookUpMutation } from '../../Features/SalesSlice';
import LoadingSpinner from '../LoadingSpinner';
import OrdersTableHelperView from './OrdersTableHelperView';
import EmptyOrdersTableHelperView from './EmptyOrdersTableHelperView';
import swal from 'sweetalert';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { useLookUpPaymentMutation } from '../../Features/lookUpPaymentSlice';


function AdminUserOrdersView({props}) {
    const navigate = useNavigate();
    const [openTab, setOpenTab] = useState(1);
    const [clearedSales, setClearedSales] = useState(null);
    const [allSales, setAllSales] = useState(null);
    const [searchData, setSearchData] = useState(null);
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false)
    const [sale, setSale] = useState(null);
    const cancelButtonRef = useRef(null)
    const pagination = 10;

    const [input, setInput] = useState({
      search: '',
    })

    const [errors, setErrors] = useState({
      search: '',
    })

    const handleChange = (e)=>{
      setInput({...input, [e.target.name]: e.target.value})
  }
      
    const {
      data: activeSales,
      isLoading: isLoadingActiveSales,
      isSuccess: isSuccessActiveSales,
      isError: isErrorActiveSales,
      error: errorActiveSales,
      refetch
    } = useGetActiveSalesQuery({pagination: pagination, page: page})

    
      const handleTabClick = (tab) => {
        setOpenTab(tab);
        setInput({...input, search: ''})
        setSearchData(null)
      };

      const [nextPage] = useNextPageMutation()

      const handleNextPage = async(url) => {
        try {
        //   setAllSales(0)
        if(openTab == '1'){
          setPage(page + 1)
          refetch()
        }else if(openTab == '2'){
          const resp = await nextPage(url).unwrap()
          setClearedSales(resp)
        }else if(openTab == '3'){
          const resp = await nextPage(url).unwrap()
          setAllSales(resp)
        }
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
        }
      };

      const handlePrevPage = async(url) => {
        try {
        if(openTab == '1'){
          setPage(page - 1)
          refetch()
        }else if(openTab == '2'){
          const resp = await nextPage(url).unwrap()
          setClearedSales(resp)
        }else if(openTab == '3'){
          const resp = await nextPage(url).unwrap()
          setAllSales(resp)
        }
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
        }
      };


      const [searchSales] = useSearchSalesMutation()

      const handleSearchSales = async(e) => {
        e.preventDefault();
        try {
          setSearchData(0)
          const resp = await searchSales(input).unwrap()
          setAllSales(null)
          setSearchData(resp)
          setOpenTab(3);
        } catch (error) {
          setAllSales(null)
          setSearchData(null)
          setOpenTab(3);
        }
      };

      const [getClearedSalesMutation] = useGetClearedSalesMutationMutation()

      const handleClearedSalesTabClick = async(tab) => {
        try {
          setClearedSales(0)
          const resp = await getClearedSalesMutation(pagination).unwrap()
          setInput({...input, search: ''})
          setClearedSales(resp)
          setSearchData(null)
          setOpenTab(tab);
        } catch (error) {
          setClearedSales(null)
          setInput({...input, search: ''})
          setOpenTab(tab);
        }
      };

      const [getAllSalesMutation] = useGetAllSalesMutationMutation()

      const handleAllSalesTabClick = async(tab) => {
        try {
          setAllSales(0)
          const resp = await getAllSalesMutation(pagination).unwrap()
          setInput({...input, search: ''})
          setAllSales(resp)
          setSearchData(null)
          setOpenTab(tab);
        } catch (error) {
          setAllSales(null)
          setInput({...input, search: ''})
          setOpenTab(tab);
        }
      };

    const [getSalesByIdMutation] = useGetSalesByIdMutationMutation()

    const handleViewOrder = async(id)=>{
        try {
            const resp = await getSalesByIdMutation(id).unwrap()
            setSale(resp)
            setOpen(true)
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
    }

      const handleCloseModal = ()=>{
        setOpen(false)
      }

      const numberWithCommas = (x)=>{
        if(x !==null){
            const formatedNumber = x.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    });
            return formatedNumber;
        }
        return null;
    }

    //

    const [clearSale, {isLoadingClearedSales}] = useClearSaleMutation()

      const handleClearSale = async(id) => {
        if(window.confirm("Are you sure you want clear this order?")){
            try {
              const resp = await clearSale(id).unwrap()

              const refreshResp = await getSalesByIdMutation(id).unwrap()
              setSale(refreshResp)

              if(openTab == '1'){
                  refetch()
              }else if(allSales){
                const resp = await getAllSalesMutation(pagination).unwrap();
                setAllSales(resp)
              }else if(searchData){
                const resp = await searchSales(input).unwrap();
                setSearchData(resp)
              }
              swal("Success",resp.message,"success");
            } catch (err) {
                if(err.data.hasOwnProperty('errors') == true){
                    swal("Error",err.data.error,"error");
                  }else if (err.data.hasOwnProperty('error') == true){
                    swal("Error",err.data.error,"error");
                  }
            }
        }
      };

      const [updatePaymentStatusLookUp] = useUpdatePaymentStatusLookUpMutation()
      const [lookUpPaymentSlice] = useLookUpPaymentMutation()

      const handlePaymentLookUp = async(id, transaction_reference) => {
        if(window.confirm("Are you sure you want to look up this transaction?")){
            try {
                const data = {
                    paymentReference: 'CMS398762022',
                    systemId: "PBS-Super Merchant",
                    password: "qq!q/Db[pSTn@NbcdS9S"
            }
                // const response = await lookUpPaymentSlice(data);

                // if(response.data.status == 0){
                    const postData = {
                        status: 1,
                        id: id
                    }
                      const resp = await updatePaymentStatusLookUp(postData).unwrap()

                      const refreshResp = await getSalesByIdMutation(id).unwrap()
                      setSale(refreshResp)

                      if(openTab == '1'){
                        refetch()
                      }else if(allSales){
                        const resp = await getAllSalesMutation(pagination).unwrap();
                        setAllSales(resp)
                      }else if(searchData){
                        const resp = await searchSales(input).unwrap();
                        setSearchData(resp)
                      }
                      return swal("Success",resp.message,"success");
                // }
                // return swal("Payment lookup failed");
            } catch (err) {
                return swal("Success",err,"success");
            }
        }
      };

    return (
            <div className="w-full h-screen overflow-x-hidden border-t flex flex-col">

            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative md:w-3/4 transform overflow-y-scroll bg-white text-left shadow-xl transition-all ">
                      <div class="w-full flex flex-col">
                          <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                              <h1 className="text-lg font-semibold uppercase">Order Invoice</h1>
                          </div>
                              {
                                  (sale)?             
                                      <div className='w-full p-6'>
                                          <div className="w-full mt-4 bg-white p-4 rounded-md">
                                              <div class="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
                                                  <div>
                                                  <h2 class="text-xl font-semibold text-gray-800 dark:text-gray-200">Invoice</h2>
                                                  </div>

                                                  <div class="inline-flex gap-x-2">
                                                  <a class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                                                      <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                      </svg>
                                                      Invoice PDF
                                                  </a>
                                                      {
                                                          (sale.data['0'].sale_info)?
                                                              (sale.data['0'].sale_info.status == '0')?
                                                                  <div onClick={() => handleClearSale(sale.data['0'].sale_info.sale_id)} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-green-400 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                      Clear
                                                                  </div>
                                                              :(sale.data['0'].sale_info.status == '1')?
                                                                  <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-500 text-white focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                      Cleared
                                                                  </div>
                                                              :(sale.data['0'].sale_info.status == '2')?
                                                              <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-600 text-white focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                  Failed
                                                              </div>
                                                              :(sale.data['0'].sale_info.status == '3')?
                                                              <div onClick={() => handlePaymentLookUp(sale.data['0'].sale_info.sale_id, sale.data['0'].sale_info.transaction_reference)} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-yellow-400 text-white focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                  Lookup
                                                              </div>
                                                              :null
                                                          :null
                                                      }
                                                  </div>
                                              </div>

                                              {
                                                  (sale.data['0'].sale_info)?
                                                      <div class="grid md:grid-cols-2 gap-3">
                                                          <div>
                                                              <div class="grid space-y-3">
                                                                  <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Billed to:
                                                                      </dt>
                                                                      <dd class="text-gray-800 dark:text-gray-200">
                                                                          <a class="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="#">
                                                                          {sale.data['0'].sale_info.fname} {sale.data['0'].sale_info.lname}
                                                                          </a>
                                                                      </dd>
                                                                  </dl>

                                                                  <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Billing details:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          <span class="block font-semibold">{sale.data['0'].sale_info.fname} {sale.data['0'].sale_info.lname}</span>
                                                                          <address class="not-italic font-normal">
                                                                          {sale.data['0'].sale_info.location_name},<br/>
                                                                          {sale.data['0'].sale_info.phone},<br/>
                                                                          Zambia<br/>
                                                                          </address>
                                                                      </dd>
                                                                  </dl>

                                                                  <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Shipping details:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          <span class="block font-semibold">{sale.data['0'].sale_info.fname} {sale.data['0'].sale_info.lname}</span>
                                                                          <address class="not-italic font-normal">
                                                                          {sale.data['0'].sale_info.location_name},<br/>
                                                                          {sale.data['0'].sale_info.phone},<br/>
                                                                          Zambia<br/>
                                                                          </address>
                                                                      </dd>
                                                                  </dl>
                                                              </div>
                                                          </div>

                                                          <div>
                                                              <div class="grid space-y-3">
                                                                  <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Transaction ID:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          {sale.data['0'].sale_info.transaction_reference}
                                                                      </dd>
                                                                      </dl>

                                                                      <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Currency:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          ZMW - ZAMBIAN KWACHA
                                                                      </dd>
                                                                      </dl>

                                                                      <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Date:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          {sale.data['0'].sale_info.created_at}
                                                                      </dd>
                                                                      </dl>

                                                                      <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Billing method:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          {sale.data['0'].sale_info.payment_method}
                                                                      </dd>
                                                                  </dl>

                                                                  <dl class="grid sm:flex gap-x-3 text-xs">
                                                                  <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                      Order number:
                                                                  </dt>
                                                                  <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                      {sale.data['0'].sale_info.token}
                                                                  </dd>
                                                              </dl>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  :null
                                              }

                                              <div class="mt-6 border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                                                  <div class="hidden sm:grid sm:grid-cols-6">
                                                  <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">Item</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Qty</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Price</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Shipping</div>
                                                  <div class="text-right text-xs font-medium text-gray-500 uppercase">Total Amount</div>
                                                  </div>

                                                  <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>
                                                      {
                                                          (sale.data['1'].products_info)?
                                                              sale.data['1'].products_info.map(data => (                
                                                                  <div class="text-sm grid grid-cols-3 sm:grid-cols-6 gap-2">
                                                                      <div class="col-span-full sm:col-span-2">
                                                                          <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Item</h5>
                                                                          <p class="font-medium text-xs text-gray-800 dark:text-gray-200">{data.product_name}</p>
                                                                      </div>
                                                                      <div>
                                                                          <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Qty</h5>
                                                                          <p class="text-gray-800 text-xs dark:text-gray-200">{(data.is_backstock)? (data.backorder_quantity > 1)? data.product_quantity+ ' ('+data.backorder_quantity+' backorders)' : data.product_quantity+ ' ('+data.backorder_quantity+' backorder)' : data.product_quantity}</p>
                                                                      </div>
                                                                      <div>
                                                                          <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Price</h5>
                                                                          <p class="text-gray-800 text-xs dark:text-gray-200">K{data.sale_price}</p>
                                                                      </div>
                                                                      <div>
                                                                          <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Shipping</h5>
                                                                          <p class="text-gray-800 text-xs dark:text-gray-200">{(data.has_shipping)? <CheckIcon className='h-4' /> : <XIcon className='h-4'/>}</p>
                                                                      </div>
                                                                      <div>
                                                                          <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Total Amount</h5>
                                                                          <p class="sm:text-right text-xs text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(data.total_price))}</p>
                                                                      </div>
                                                                  </div>
                                                              ))
                                                          :null
                                                      }
                                              </div>

                                              {
                                                  (sale.data['0'].sale_info)?
                                                      <div class="mt-8 flex sm:justify-end">
                                                          <div class="w-full max-w-2xl sm:text-right space-y-2">
                                                              <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                                                                  <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                      <dt class="col-span-3 text-gray-500">Subtotal:</dt>
                                                                      <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{(numberWithCommas(parseInt(sale.data['0'].sale_info.total_charge) - parseInt(sale.data['0'].sale_info.shipping_charge)))}</dd>
                                                                  </dl>

                                                                  <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                      <dt class="col-span-3 text-gray-500">Shipping:</dt>
                                                                      <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(sale.data['0'].sale_info.shipping_charge))}</dd>
                                                                  </dl>

                                                                  <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                      <dt class="col-span-3 text-gray-500">Discount:</dt>
                                                                      <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{(sale.data['0'].sale_info.discount)? numberWithCommas(parseInt(sale.data['0'].sale_info.discount)) : '00.00'}</dd>
                                                                  </dl>

                                                                  <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                      <dt class="col-span-3 text-gray-500">Total:</dt>
                                                                      <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(sale.data['0'].sale_info.total_charge))}</dd>
                                                                  </dl>

                                                                  <dl class="grid sm:grid-cols-5 font-semibold gap-x-3 text-xs">
                                                                      <dt class="col-span-3 font-bold text-gray-500">Amount paid:</dt>
                                                                      <dd class="col-span-2 font-bold text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(sale.data['0'].sale_info.total_charge))}</dd>
                                                                  </dl>

                                                              </div>
                                                          </div>
                                                      </div>
                                                  :null
                                              }

                                          </div>
                                      </div>
                                  :null
                              }
                      </div>
                      <div className="flex justify-center items-center pb-16">
                        <button onClick={handleCloseModal} className='px-4 py-2 bg-red-600 hover:bg-red-500 rounded-md text-white'>Close</button>
                      </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <main className="w-full flex-grow">
            <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                <h1 className="text-md font-semibold">Orders</h1>
            </div>
            <div className='flex justify-center items-center mt-2'>
              <form onSubmit={handleSearchSales} autoComplete='off'>
                <div>
                  <div class="relative w-96 outline-none">
                    <input 
                    type="search" 
                    id="search-dropdown" 
                    name="search" 
                    class="block w-full px-5 py-2 outline-none text-gray-700 border-none focus:ring-rose-600 bg-gray-100 rounded" 
                    placeholder="Search order..." 
                    value={input.search} 
                    onChange={handleChange}
                    required />
                    <button type="submit" class="absolute top-0 right-0 px-5 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-r-lg border border-gray-100 hover:bg-gray-200">
                        <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        <span class="sr-only">Search</span>
                    </button>
                  </div>
                  <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.search}</p>
                </div>
              </form>
            </div>
              <div className="w-full p-2">
                <div className="flex justify-end items-center">
                  <ul className="flex border-b">
                    <li className="-mb-px mr-1" onClick={() => handleTabClick(1)}>
                      <a
                        className={`${
                          openTab === 1
                            ? 'border-l border-t border-r rounded-t text-gray-900 font-semibold'
                            : 'text-gray-500 hover:text-gray-500'
                        } bg-white inline-block py-2 px-4 font-semibold text-xs`}
                      >
                        Active
                      </a>
                    </li>
                    <li className="mr-1" onClick={() => handleClearedSalesTabClick(2)}>
                      <a
                        className={`${
                          openTab === 2
                            ? 'border-l border-t border-r rounded-t text-gray-900 font-semibold'
                            : 'text-gray-500 hover:text-gray-500'
                        } bg-white inline-block py-2 px-4 font-semibold text-xs`}
                        href="#"
                      >
                        Cleared
                      </a>
                    </li>
                    <li className="mr-1" onClick={() => handleAllSalesTabClick(3)}>
                      <a
                        className={`${
                          openTab === 3
                          ? 'border-l border-t border-r rounded-t text-gray-900 font-semibold'
                          : 'text-gray-500 hover:text-gray-500'
                      } bg-white inline-block py-2 px-4 font-semibold text-xs`}
                        href="#"
                      >
                        All
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="bg-white p-6">
                  <div
                    id="tab-1"
                    className={openTab === 1 ? '' : 'hidden'}
                  >
                    {
                        (isSuccessActiveSales)?
                        <div class="w-full mt-1">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Active Orders
                            </p>
                            <OrdersTableHelperView data={activeSales.data} prev={handlePrevPage} next={handleNextPage} view={handleViewOrder} isAll={false} />
                        </div>
                        :(isLoadingActiveSales)?
                            <LoadingSpinner />
                        :(isErrorActiveSales)?
                          <div class="w-full mt-1">
                                <p class="text-md pb-3 flex items-center">
                                    <i class="fas fa-list mr-3"></i> Active Orders
                                </p>
                                <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                    <i class=""></i> No active Orders found
                                </p>
                                <EmptyOrdersTableHelperView />
                            </div>
                        :null
                    }
                  </div>
                  <div
                    id="tab-2"
                    className={openTab === 2 ? '' : 'hidden'}
                  >
                    {
                        (clearedSales)?
                        <div class="w-full mt-1">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Cleared Orders
                            </p>
                            <OrdersTableHelperView data={clearedSales.data} prev={handlePrevPage} next={handleNextPage} view={handleViewOrder} isAll={false} />
                        </div>
                        :(clearedSales == '0')?
                            <LoadingSpinner />
                        :(!clearedSales)?
                          <div class="w-full mt-1">
                                <p class="text-md pb-3 flex items-center">
                                    <i class="fas fa-list mr-3"></i> Cleared Orders
                                </p>
                                <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                    <i class=""></i> No cleared Orders found
                                </p>
                                <EmptyOrdersTableHelperView />
                            </div>
                        :null
                    }
                  </div>
                  <div
                    id="tab-3"
                    className={openTab === 3 ? '' : 'hidden'}
                  >
                    {
                        (searchData)?
                          <div class="w-full mt-1">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Search Results
                            </p>
                            <OrdersTableHelperView data={searchData} isAll={false} view={handleViewOrder} />
                          </div>
                        :(!searchData && allSales)?
                          <div class="w-full mt-1">
                              <p class="text-md pb-3 flex items-center">
                                  <i class="fas fa-list mr-3"></i> All Orders
                              </p>
                              <OrdersTableHelperView data={allSales.data} prev={handlePrevPage} next={handleNextPage} view={handleViewOrder} isAll={true} />
                          </div>
                        :(!searchData == '0')?
                          <LoadingSpinner />
                        :(allSales == '0')?
                          <LoadingSpinner />
                        :(!allSales && input.search == '')?
                          <div class="w-full mt-1">
                                <p class="text-md pb-3 flex items-center">
                                    <i class="fas fa-list mr-3"></i> All Orders
                                </p>
                                <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                    <i class=""></i> No Orders found
                                </p>
                                <EmptyOrdersTableHelperView />
                            </div>
                        :(!searchData)?
                          <div class="w-full mt-1">
                              <p class="text-md pb-3 flex items-center">
                                  <i class="fas fa-list mr-3"></i> Search Results
                              </p>
                              <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                  <i class=""></i> No search found
                              </p>
                              <EmptyOrdersTableHelperView />
                          </div>
                        :null
                    }
                  </div>
                </div>
              </div>
            </main>
          </div>
    )
}

export default AdminUserOrdersView;
