import { apiSlice } from "../app/api/apiSlice"


export const lookUpPaymentSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        lookUpPayment: builder.mutation({
            query: (input) => ({
                // mode: 'no-cors',  
                url: `http://95.179.223.128:4200/pbs/Payments/Api/V1/Transaction/GetStatus`,
                method: 'POST',
                headers: {  
                    'Content-Type': 'application/json',
                    'auth_token': 'VUd3nEKzuuCdjyDnB2ashcejD7gH48fMQ4pCcCQ4CPz8Cen55MpUcbBAjnuKBTWdW2gGEtmVQsBtsbA69HqwnFk6REuC3mkWFSCEEEyGhF5zBw',
                },
                body: input 
            }),
        }),
    })
})

export const {
    useLookUpPaymentMutation,
} = lookUpPaymentSlice

