import { apiSlice } from "../app/api/apiSlice";

export const frontEndImagesSliceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        uploadNewImage: builder.mutation({
            query: (input) => ({
                url: `user/upload-image`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Images']
        }),
        toggleImageStatus: builder.mutation({
            query: (id) => `/user/toggle-image-status/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            invalidatesTags: ['Images']
        }),
        getAllImages: builder.query({
            query: () => `/user/images`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Images']
        }),
        getAllImagesForFrontEnd: builder.query({
            query: () => `/user/front-end-images`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Images']
        }),
        getImageById: builder.query({
            query: (id) => `/user/image/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Images']
        }),
        deleteImage: builder.mutation({
            query: (id) => ({
                url: `user/delete-image/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Images']
        }),
        editFrontEndImage: builder.mutation({
            query: (input) => ({
                url: `user/front-end-image-edit/${input.id}`,
                method: 'PUT',
                body: {...input}
            }),
            invalidatesTags: ['Images']
        }),
    })
})

export const {
    useGetAllImagesQuery,
    useUploadNewImageMutation,
    useGetImageByIdQuery,
    useDeleteImageMutation,
    useToggleImageStatusMutation,
    useEditFrontEndImageMutation,
    useGetAllImagesForFrontEndQuery
} = frontEndImagesSliceApiSlice

