import { useSelector } from "react-redux";
import { useLocation,Navigate, Outlet } from "react-router-dom";
import { selectCurrentRoles, selectCurrentUser, selectCurrentToken} from "../Features/auth/authSlice";


const RequireAuth = ({allowedRoles})=>{
    const  token  = useSelector(selectCurrentToken);
    const  user  = useSelector(selectCurrentUser);
    const  roles  = useSelector(selectCurrentRoles);
    const location = useLocation();
    
    return (
        (roles != null && token != null)?
            (roles.find(role =>allowedRoles?.includes(role)))? 
            <Outlet />
                :user
                    ? < Navigate to="/authentication/unauthorized" state={{from: location }} replace />
                    : < Navigate to="/login" state={{from: location }} replace />
        : < Navigate to="/login" state={{from: location }} replace />
        
    )

}

export default RequireAuth;