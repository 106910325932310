import { apiSlice } from "../app/api/apiSlice";

export const categoriesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllCategories: builder.query({
            query: () => `/user/categories`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['categories']
        }),
        createCategory: builder.mutation({
            query: (input) => ({
                url: `user/create-category`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['categories']
        }),
        editCategory: builder.mutation({
            query: (input) => ({
                url: `user/edit-category/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['categories']
        }),
        getCategoryById: builder.query({
            query: (id) => `/user/time/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['categories']
        }),

        getCategoryByIdMutation: builder.mutation({
            query: (id) => `/user/category/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['categories']
        }),

        deleteCategory: builder.mutation({
            query: (id) => ({
                url: `user/delete-category/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['categories']
        }),
    })
})

export const {
    useCreateCategoryMutation,
    useEditCategoryMutation,
    useGetAllCategoriesQuery,
    useDeleteCategoryMutation,
    useGetCategoryByIdMutationMutation,
} = categoriesApiSlice

