import { apiSlice } from "../app/api/apiSlice";

export const bookingLocationsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllBookinLocations: builder.query({
            query: () => `/user/booking-locations`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['booking-locations']
        }),
        createBookingLocation: builder.mutation({
            query: (input) => ({
                url: `user/create-booking-location`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['booking-locations']
        }),
        editBookinLocation: builder.mutation({
            query: (input) => ({
                url: `user/edit-booking-location/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['booking-locations']
        }),
        getBookinLocationById: builder.query({
            query: (id) => `/user/booking-location/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['booking-locations']
        }),

        getBookinLocationByIdMutation: builder.mutation({
            query: (id) => `/user/booking-location/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['booking-locations']
        }),

        deleteBookinLocation: builder.mutation({
            query: (id) => ({
                url: `user/delete-booking-location/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['booking-locations']
        }),
    })
})

export const {
    useCreateBookingLocationMutation,
    useEditBookinLocationMutation,
    useGetAllBookinLocationsQuery,
    useDeleteBookinLocationMutation,
    useGetBookinLocationByIdMutationMutation,
} = bookingLocationsSlice

