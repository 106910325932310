import { configureStore } from "@reduxjs/toolkit"
import authReducer from '../Features/auth/authSlice'
import clientReducer from '../Features/clientObjectSlice'
import { apiSlice } from "./api/apiSlice"
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const persistConfig = {
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(persistConfig, authReducer)

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: persistedReducer,
        clientObject: clientReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})

export const persistor = persistStore(store)