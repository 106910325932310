
import { useEffect } from 'react';
import Home from '../../HeaderLayouts/Home';

function RefundPrivacyPlicyView({props}) {

  useEffect(()=>{
    window.scrollTo(0, 0)
    return () =>{
    }
  },[]);

    return (
        <main className='w-full bg-white flex-grow'>
            <Home />
            <div className='flex items-center justify-between'>
            <div className="w-full flex-grow">
                <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                    <h1 className="text-lg font-semibold">Refund And Returns Policy</h1>
                </div>
            </div>
            </div>
            <div className='md:p-6'>
            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Overview</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  Our refund and returns policy lasts 30 days. If 30 days have passed since your purchase, we can’t offer you a full refund or exchange.<br/><br/>

                  To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
                  </p>
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Refunds</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.<br/><br/>

                  If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
                  </p>
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Exchanges</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an email at email address and send your item to: physical address.
                  </p> 
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Shipping returns</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  To return your product, you should mail your product to: help@autozedltd.com.<br/><br/>

                  You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.<br/><br/>

                  Depending on where you live, the time it may take for your exchanged product to reach you may vary.<br/><br/>

                  If you are returning more expensive items, you may consider using a trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.
                  </p> 
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Need help?</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  Contact us at help@autozedltd.com for questions related to refunds and returns.
                  </p> 
                </span>
              </div>
            </div>

            </div>
        </main>
    )
}

export default RefundPrivacyPlicyView;
