import { apiSlice } from "../app/api/apiSlice";

export const dataAnalysisReportingSliceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClientLast28daysTicketSalesReport: builder.query({
            query: (client_id) => `user/client-last-28-days-sales/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientLast28daysRevenueReport: builder.query({
            query: (client_id) => `user/client-last-28-days-revenue/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientTeamLast28daysTicketSalesReport: builder.query({
            query: (user_id) => `user/client-team-last-28-days-sales/${user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientSalesAndTransactionsReportReview: builder.query({
            query: (client_id) => `user/client-revenue-report-review/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientTotalSalesAndTransactionsReportSumarry: builder.query({
            query: (client_id) => `user/client-sales-report-summary/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientSalesReportReview: builder.query({
            query: (client_id) => `user/client-sales-report-review/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientRouteSalesAndTransactionsReportReview: builder.query({
            query: ({client_id, client_route_id}) => `user/client-route-revenue-report-review/${client_id}/${client_route_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientRouteSalesRevenueReportReview: builder.query({
            query: ({client_id, client_route_id}) => `user/client-route-sales-report-review/${client_id}/${client_route_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientAgentUserDailySalesDates: builder.query({
            query: ({client_id, agent_user_id}) => `user/client-agent-user-sales-dates/${client_id}/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientAgentUserDailySales: builder.query({
            query: ({client_id, agent_user_id, date}) => `user/client-agent-user-sales/${client_id}/${agent_user_id}/${date}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientSalesUserRevenueSummaryReport: builder.query({
            query: ({client_id, agent_user_id}) => `user/client-sales-user-revenue-summary-report/${client_id}/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientSalesUserRevenueSummary: builder.mutation({
            query: ({client_id, agent_user_id}) => `user/client-sales-user-revenue-summary-report/${client_id}/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientSalesUserSummaryReport: builder.mutation({
            query: ({client_id, agent_user_id}) => `user/client-sales-user-last-28-summary-report/${client_id}/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientSalesUserSalesSummaryReport: builder.query({
            query: ({client_id, agent_user_id}) => `user/client-sales-user-sales-report-summary/${client_id}/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientSalesUserSalesReport: builder.mutation({
            query: ({client_id, agent_user_id}) => `user/client-sales-user-sales-report-summary/${client_id}/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientManagerTeamRevenueReportReview: builder.query({
            query: (agent_user_id) => `user/client-manager-team-revenue-report/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20,
            providesTags: ['ClientManagerTeamRevenueReport']
        }),
        getClientManagerTeamRevenueReport: builder.mutation({
            query: (agent_user_id) => `user/client-manager-team-revenue-report/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20,
            providesTags: ['ClientManagerTeamRevenueReport']
        }),
        getClientManagerTeamLast28SummaryReport: builder.mutation({
            query: (admin_user_id) => `user/client-manager-team-last-28-summary-report/${admin_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20,
            providesTags: ['ClientManagerTeamRevenueReport']
        }),
        getClientManagerTeamSalesReportReview: builder.query({
            query: (user_id) => `user/client-manager-team-sales-report/${user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20,
            providesTags: ['ClientManagerTeamSalesReport']
        }),
        getClientManagerTeamSalesReport: builder.mutation({
            query: (user_id) => `user/client-manager-team-sales-report/${user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20,
            providesTags: ['ClientManagerTeamSalesReport']
        }),
        getTeamRevenueReportReview: builder.mutation({
            query: (agent_user_id) => `user/client-manager-team-revenue-report/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20,
            providesTags: ['ClientManagerTeamRevenueReport']
        }),
        getClientStationManagerTotalTeamSalesReportSummary: builder.query({
            query: (agent_user_id) => `user/client-manager-total-team-sales-summary-report/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20,
            providesTags: ['ClientManagerTeamRevenueReport']
        }),
        getTeamSalesReportReview: builder.mutation({
            query: (agent_user_id) => `user/client-manager-team-sales-report/${agent_user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20,
            providesTags: ['ClientManagerTeamSalesReport']
        }),
        getClientSalesStatisticsReport: builder.query({
            query: ({client_id,from,to}) => `user/client-customer-location-statistics/${client_id}/${from}/${to}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientCustomerLocationDataByRegionIds: builder.mutation({
            query: (input) => ({
                url: `user/client-customer-location-by-region-ids-statistics`,
                method: 'POST',
                body: { ...input }
            }),
        }),
        getClientCustomerMotiveDataByPurposeIds: builder.mutation({
            query: (input) => ({
                url: `user/client-customer-motive-by-purpose-ids-statistics`,
                method: 'POST',
                body: { ...input }
            }),
        }),
        getClientCustomerTravelRelationshipDataByRegionAndPurposeIds: builder.mutation({
            query: (input) => ({
                url: `user/client-customer-travel-relationship-by-region-and-purpose-ids-statistics`,
                method: 'POST',
                body: { ...input }
            }),
        }),
        getClientCustomerLocationDataPhoneNumbersByRegionIds: builder.mutation({
            query: (input) => ({
                url: `user/client-customer-location-by-region-ids-phone-numbers`,
                method: 'POST',
                body: { ...input }
            }),
        }),
        getClientCustomerMotiveDataPhoneNumbersByPurposesIds: builder.mutation({
            query: (input) => ({
                url: `user/client-customer-motive-by-purpose-ids-phone-numbers`,
                method: 'POST',
                body: { ...input }
            }),
        }),
        getClientCustomerTravelRelationshipDataPhoneNumbersByRegionPurposesIds: builder.mutation({
            query: (input) => ({
                url: `user/client-customer-travel-relationship-by-region-and-purpose-ids-phone-numbers`,
                method: 'POST',
                body: { ...input }
            }),
        }),
        getClientCustomerTravelRelashionshipStatisticsReport: builder.query({
            query: ({client_id,from,to}) => `user/client-customer-travel-relashionship-statistics-report/${client_id}/${from}/${to}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientCustomerTravelRelashionshipMotives: builder.mutation({
            query: ({client_id,region_id,from,to}) => `user/client-customer-travel-relashionship-motives/${client_id}/${region_id}/${from}/${to}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientCustomerTravelRelashionshipRegions: builder.query({
            query: ({client_id,from,to}) => `user/client-customer-travel-relashionship-regions/${client_id}/${from}/${to}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientCustomerMotiveStatisticalReport: builder.query({
            query: ({client_id,from,to}) => `user/client-customer-motive-report/${client_id}/${from}/${to}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientCurrentMonthTransactionsStatement: builder.query({
            query: (client_id) => `user/current-month-transaction-statement/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),

        getClientMonthlyTransactionsStatementsDates: builder.query({
            query: (client_id) => `user/month-transaction-statement-dates/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
        getClientSelectedMonthTransactionsStatement: builder.mutation({
            query: ({client_id, date}) => `user/client-sales-transactions-statement/${date}/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
        }),
    })
})

export const {
    useGetClientLast28daysTicketSalesReportQuery,
    useGetClientSalesAndTransactionsReportReviewQuery,
    useGetClientCurrentMonthTransactionsStatementQuery,
    useGetClientRouteSalesAndTransactionsReportReviewQuery,
    useGetClientCustomerMotiveDataPhoneNumbersByPurposesIdsMutation,
    useGetClientCustomerMotiveDataByPurposeIdsMutation,
    useGetClientAgentUserDailySalesDatesQuery,
    useGetClientSalesReportReviewQuery,
    useGetClientLast28daysRevenueReportQuery,
    useGetClientSalesUserRevenueSummaryMutation,
    useGetClientCustomerLocationDataByRegionIdsMutation,
    useGetClientManagerTeamLast28SummaryReportMutation,
    useGetClientTeamLast28daysTicketSalesReportQuery,
    useGetClientCustomerLocationDataPhoneNumbersByRegionIdsMutation,
    useGetClientCustomerTravelRelationshipDataByRegionAndPurposeIdsMutation,
    useGetClientCustomerTravelRelationshipDataPhoneNumbersByRegionPurposesIdsMutation,
    useGetTeamRevenueReportReviewMutation,
    useGetClientSalesUserSummaryReportMutation,
    useGetClientManagerTeamSalesReportMutation,
    useGetClientTotalSalesAndTransactionsReportSumarryQuery,
    useGetClientStationManagerTotalTeamSalesReportSummaryQuery,
    useGetClientSalesUserSalesSummaryReportQuery,
    useGetClientManagerTeamSalesReportReviewQuery,
    useGetClientCustomerMotiveStatisticalReportQuery,
    useGetClientSalesUserSalesReportMutation,
    useGetTeamSalesReportReviewMutation,
    useGetClientRouteSalesRevenueReportReviewQuery,
    useGetClientManagerTeamRevenueReportMutation,
    useGetClientCustomerTravelRelashionshipRegionsQuery,
    useGetClientSalesUserRevenueSummaryReportQuery,
    useGetClientCustomerTravelRelashionshipMotivesMutation,
    useGetClientManagerTeamRevenueReportReviewQuery,
    useGetClientCustomerTravelRelashionshipStatisticsReportQuery,
    useGetClientAgentUserDailySalesQuery,
    useGetClientMonthlyTransactionsStatementsDatesQuery,
    useGetClientSelectedMonthTransactionsStatementMutation,
    useGetClientSalesStatisticsReportQuery,
} = dataAnalysisReportingSliceApiSlice

