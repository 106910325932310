import { apiSlice } from "../app/api/apiSlice";

export const rolesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClientRoles: builder.query({
            query: () => '/user/client-roles',
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Client_Roles']
        }),
        assignUserAClientRole: builder.mutation({
            query: ({id, role}) => ({
                url: `user/assing-user-client-role/${id}`,
                method: 'POST',
                body: { role }
            }),
            invalidatesTags: ['Client_Roles']
        }),
        removeClientRoleFromUser: builder.mutation({
            query: ({role_id, user_id}) => ({
                url: `user/unregister-user-from-client-role/${user_id}/${role_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Client_Roles']
        }),
        getClientRoleById: builder.query({
            query: (id) => `/user/get-single-role/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Client_Roles']
        }),
        getUserClientRoleByUserId: builder.query({
            query: (id) => `/user/user-client-role/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Client_Roles']
        }),
    })
})

export const {
    useGetClientRolesQuery,
    useGetUserClientRoleByUserIdQuery,
    useAssignUserAClientRoleMutation,
    useRemoveClientRoleFromUserMutation,
} = rolesApiSlice

