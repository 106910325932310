import { ArrowCircleDownIcon, CheckIcon, XIcon,  } from '@heroicons/react/outline';
import { PencilAltIcon } from '@heroicons/react/solid';
import { Link, useNavigate } from 'react-router-dom';
import { useClearSaleMutation, useGetRecentSalesQuery, useGetSalesByIdMutationMutation, useUpdatePaymentStatusLookUpMutation } from '../../Features/SalesSlice';
import LoadingSpinner from '../LoadingSpinner';
import { useGetBookingByIdMutationMutation, useGetRecentBookingsQuery, useToggleBookingStatusMutation, useUpdateServicePaymentStatusMutation, useUpdateTransportChargePaymentStatusMutation } from '../../Features/bookingsSlice';
import { Menu } from '@headlessui/react';
import EmptyOrdersTableHelperView from './EmptyOrdersTableHelperView';
import OrdersTableHelperView from './OrdersTableHelperView';
import EmptyBookingsTableHelperView from './EmptyBookingsTableHelperView';
import BookingsTableHelperView from './BookingsTableHelperView';
import { useLookUpPaymentMutation } from '../../Features/lookUpPaymentSlice';
import { useRef, useState, Fragment} from 'react';
import { Dialog, Transition } from '@headlessui/react'
import swal from 'sweetalert';

function AdminUserIndex({props}) {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false)
    const [openBooking, setOpenBooking] = useState(false)
    const [sale, setSale] = useState(null);
    const [booking, setBooking] = useState(null);
    const cancelButtonRef = useRef(null)

    const {
        data: recentSales,
        isLoading: isLoadingRecentSales,
        isSuccess: isSuccessRecentSales,
        isError: isErrorRecentSales,
        error: errorRecentSales,
        refetch: refetchRecentSales
      } = useGetRecentSalesQuery()

    const {
    data: recentBookings,
    isLoading: isLoadingRecentBookings,
    isSuccess: isSuccessRecentBookings,
    isError: isErrorRecentBookings,
    error: errorRecentBookings,
    refetch: refetchRecentBookings
    } = useGetRecentBookingsQuery()

    const numberWithCommas = (x)=>{
        if(x !==null){
            const formatedNumber = x.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    });
            return formatedNumber;
        }
        return null;
    }
      //Sales or orders
    const [getSalesByIdMutation] = useGetSalesByIdMutationMutation()

    const handleViewOrder = async(id)=>{
        try {
            const resp = await getSalesByIdMutation(id).unwrap()
            setSale(resp)
            setOpen(true)
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
    }

    const [clearSale, {isLoadingClearedSales}] = useClearSaleMutation()

      const handleClearSale = async(id) => {
        if(window.confirm("Are you sure you want clear this order?")){
            try {
              const resp = await clearSale(id).unwrap()

              const refetchResp = await getSalesByIdMutation(id).unwrap()
              setSale(refetchResp)
              refetchRecentSales()

              swal("Success",resp.message,"success");
            } catch (err) {
                if(err.data.hasOwnProperty('errors') == true){
                    swal("Error",err.data.error,"error");
                  }else if (err.data.hasOwnProperty('error') == true){
                    swal("Error",err.data.error,"error");
                  }
            }
        }
      };

      const [updatePaymentStatusLookUp] = useUpdatePaymentStatusLookUpMutation()
      const [lookUpPaymentSlice] = useLookUpPaymentMutation()

      const handlePaymentLookUp = async(id, transaction_reference) => {
        if(window.confirm("Are you sure you want to look up this transaction?")){
            try {
                const data = {
                    paymentReference: 'CMS398762022',
                    systemId: "PBS-Super Merchant",
                    password: "qq!q/Db[pSTn@NbcdS9S"
            }
                // const response = await lookUpPaymentSlice(data);

                // if(response.data.status == 0){
                    const postData = {
                        status: 1,
                        id: id
                    }
                      const resp = await updatePaymentStatusLookUp(postData).unwrap()

                      const refetchResp = await getSalesByIdMutation(id).unwrap()
                      setSale(refetchResp)
                      refetchRecentSales()
                      return swal("Success",resp.message,"success");
                // }
                // return swal("Payment lookup failed");
            } catch (err) {
                return swal("Success",err,"success");
            }
        }
      };

      //booking 

    const [getBookingByIdMutation] = useGetBookingByIdMutationMutation()

    const handleViewBooking = async(id)=>{
        try {
            const resp = await getBookingByIdMutation(id).unwrap()
            setBooking(resp)
            setOpenBooking(true)
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
    }

      const total = ()=>{
        let transport_charge = (booking.data.transport_charge)? parseInt(booking.data.transport_charge) : 0
        return transport_charge +  parseInt(booking.data.service_charge)
    }
  
    const paidAmount = ()=>{
        let transport_charge = (booking.data.transport_charge)? parseInt(booking.data.transport_charge) : 0
        return (booking.data.payment_status)? transport_charge +  parseInt(booking.data.service_charge) : (booking.data.transport_charge)? parseInt(booking.data.transport_charge) : '0'
    }
  
    const [toggleBookingStatus] = useToggleBookingStatusMutation()

    const makeCompletedStatus = async(id,status) => {
        if(window.confirm("Are you sure you want complete this booking?")){
            try {
              const resp = await toggleBookingStatus({id: id,status: status}).unwrap()
              const refreshResp = await getBookingByIdMutation(id).unwrap()
              setBooking(refreshResp)
              refetchRecentBookings()
              swal("Success",resp.message,"success");
            } catch (err) {
              if(err.data.hasOwnProperty('errors') == true){
                  swal("Error",err.data.error,"error");
                }else if (err.data.hasOwnProperty('error') == true){
                  swal("Error",err.data.error,"error");
                }
            }
        }
      };
  
      const toggleStatus = async(id,status) => {
        try {
          const resp = await toggleBookingStatus({id: id,status: status}).unwrap()
          const refreshResp = await getBookingByIdMutation(id).unwrap()
          setBooking(refreshResp)
          refetchRecentBookings()
          swal("Success",resp.message,"success");
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
      };

      const [updateServicePaymentStatus] = useUpdateServicePaymentStatusMutation();

      const handleBookingServicePaymentLookUp = async(id, transaction_reference) => {
        if(window.confirm("Are you sure you want to look up this transaction?")){
            try {
            //     const data = {
            //         paymentReference: 'CMS398762022',
            //         systemId: "PBS-Super Merchant",
            //         password: "qq!q/Db[pSTn@NbcdS9S"
            // }
            //     const response = await lookUpPaymentSlice(data);
  
                // if(response.data.status == 0){
                    const postData = {
                        status: 1,
                        transaction_reference: transaction_reference
                    }
                      const resp = await updateServicePaymentStatus(postData).unwrap()
                      const refreshResp = await getBookingByIdMutation(id).unwrap()
                      setBooking(refreshResp)

                        refetchRecentBookings()
                        return swal(resp.message);
                // }
                // return swal("Payment lookup failed");
            } catch (err) {
                return swal("Success",err,"success");
            }
        }
      };
  
      //lookup transport charge payment status
      const [updateTransportChargePaymentStatus] = useUpdateTransportChargePaymentStatusMutation();
  
      const handleTransportChargePaymentLookUp = async(id, transaction_reference) => {
        if(window.confirm("Are you sure you want to look up this transaction?")){
            try {
                const data = {
                    paymentReference: 'CMS398762022',
                    systemId: "PBS-Super Merchant",
                    password: "qq!q/Db[pSTn@NbcdS9S"
            }
                // const response = await lookUpPaymentSlice(data);
  
                // if(response.data.status == 0){
                    const postData = {
                        status: 1,
                        transaction_reference: transaction_reference
                    }
                      const resp = await updateTransportChargePaymentStatus(postData).unwrap()
                      const refreshResp = await getBookingByIdMutation(id).unwrap()
                      setBooking(refreshResp)
  
                      refetchRecentBookings()
                      return swal(resp.message);
                // }
                // return swal("Payment lookup failed");
            } catch (err) {
                return swal("Success",err,"success");
            }
        }
      };

      const handleCloseModal = ()=>{
        setOpen(false)
        setOpenBooking(false)
      }

    return (
        <div class="w-full h-screen overflow-x-hidden border-t flex flex-col">
            
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative md:w-3/4 transform overflow-y-scroll bg-white text-left shadow-xl transition-all ">
                      <div class="w-full flex flex-col">
                          <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                              <h1 className="text-lg font-semibold uppercase">Order Invoice</h1>
                          </div>
                              {
                                  (sale)?             
                                      <div className='w-full p-6'>
                                          <div className="w-full mt-4 bg-white p-4 rounded-md">
                                              <div class="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
                                                  <div>
                                                  <h2 class="text-xl font-semibold text-gray-800 dark:text-gray-200">Invoice</h2>
                                                  </div>

                                                  <div class="inline-flex gap-x-2">
                                                  <a class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                                                      <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                      </svg>
                                                      Invoice PDF
                                                  </a>
                                                      {
                                                          (sale.data['0'].sale_info)?
                                                              (sale.data['0'].sale_info.status == '0')?
                                                                  <div onClick={() => handleClearSale(sale.data['0'].sale_info.sale_id)} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-green-400 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                      Clear
                                                                  </div>
                                                              :(sale.data['0'].sale_info.status == '1')?
                                                                  <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-500 text-white focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                      Cleared
                                                                  </div>
                                                              :(sale.data['0'].sale_info.status == '2')?
                                                              <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-600 text-white focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                  Failed
                                                              </div>
                                                              :(sale.data['0'].sale_info.status == '3')?
                                                              <div onClick={() => handlePaymentLookUp(sale.data['0'].sale_info.sale_id, sale.data['0'].sale_info.transaction_reference)} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-yellow-400 text-white focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                  Lookup
                                                              </div>
                                                              :null
                                                          :null
                                                      }
                                                  </div>
                                              </div>

                                              {
                                                  (sale.data['0'].sale_info)?
                                                      <div class="grid md:grid-cols-2 gap-3">
                                                          <div>
                                                              <div class="grid space-y-3">
                                                                  <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Billed to:
                                                                      </dt>
                                                                      <dd class="text-gray-800 dark:text-gray-200">
                                                                          <a class="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="#">
                                                                          {sale.data['0'].sale_info.fname} {sale.data['0'].sale_info.lname}
                                                                          </a>
                                                                      </dd>
                                                                  </dl>

                                                                  <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Billing details:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          <span class="block font-semibold">{sale.data['0'].sale_info.fname} {sale.data['0'].sale_info.lname}</span>
                                                                          <address class="not-italic font-normal">
                                                                          {sale.data['0'].sale_info.location_name},<br/>
                                                                          {sale.data['0'].sale_info.phone},<br/>
                                                                          Zambia<br/>
                                                                          </address>
                                                                      </dd>
                                                                  </dl>

                                                                  <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Shipping details:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          <span class="block font-semibold">{sale.data['0'].sale_info.fname} {sale.data['0'].sale_info.lname}</span>
                                                                          <address class="not-italic font-normal">
                                                                          {sale.data['0'].sale_info.location_name},<br/>
                                                                          {sale.data['0'].sale_info.phone},<br/>
                                                                          Zambia<br/>
                                                                          </address>
                                                                      </dd>
                                                                  </dl>
                                                              </div>
                                                          </div>

                                                          <div>
                                                              <div class="grid space-y-3">
                                                                  <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Transaction ID:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          {sale.data['0'].sale_info.transaction_reference}
                                                                      </dd>
                                                                      </dl>

                                                                      <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Currency:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          ZMW - ZAMBIAN KWACHA
                                                                      </dd>
                                                                      </dl>

                                                                      <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Date:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          {sale.data['0'].sale_info.created_at}
                                                                      </dd>
                                                                      </dl>

                                                                      <dl class="grid sm:flex gap-x-3 text-xs">
                                                                      <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                          Billing method:
                                                                      </dt>
                                                                      <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                          {sale.data['0'].sale_info.payment_method}
                                                                      </dd>
                                                                  </dl>

                                                                  <dl class="grid sm:flex gap-x-3 text-xs">
                                                                  <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                      Order number:
                                                                  </dt>
                                                                  <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                      {sale.data['0'].sale_info.token}
                                                                  </dd>
                                                              </dl>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  :null
                                              }

                                              <div class="mt-6 border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                                                  <div class="hidden sm:grid sm:grid-cols-6">
                                                  <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">Item</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Qty</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Price</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Shipping</div>
                                                  <div class="text-right text-xs font-medium text-gray-500 uppercase">Total Amount</div>
                                                  </div>

                                                  <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>
                                                      {
                                                          (sale.data['1'].products_info)?
                                                              sale.data['1'].products_info.map(data => (                
                                                                  <div class="text-sm grid grid-cols-3 sm:grid-cols-6 gap-2">
                                                                      <div class="col-span-full sm:col-span-2">
                                                                          <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Item</h5>
                                                                          <p class="font-medium text-xs text-gray-800 dark:text-gray-200">{data.product_name}</p>
                                                                      </div>
                                                                      <div>
                                                                          <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Qty</h5>
                                                                          <p class="text-gray-800 text-xs dark:text-gray-200">{(data.is_backstock)? (data.backorder_quantity > 1)? data.product_quantity+ ' ('+data.backorder_quantity+' backorders)' : data.product_quantity+ ' ('+data.backorder_quantity+' backorder)' : data.product_quantity}</p>
                                                                      </div>
                                                                      <div>
                                                                          <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Price</h5>
                                                                          <p class="text-gray-800 text-xs dark:text-gray-200">K{data.sale_price}</p>
                                                                      </div>
                                                                      <div>
                                                                          <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Shipping</h5>
                                                                          <p class="text-gray-800 text-xs dark:text-gray-200">{(data.has_shipping)? <CheckIcon className='h-4' /> : <XIcon className='h-4'/>}</p>
                                                                      </div>
                                                                      <div>
                                                                          <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Total Amount</h5>
                                                                          <p class="sm:text-right text-xs text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(data.total_price))}</p>
                                                                      </div>
                                                                  </div>
                                                              ))
                                                          :null
                                                      }
                                              </div>

                                              {
                                                  (sale.data['0'].sale_info)?
                                                      <div class="mt-8 flex sm:justify-end">
                                                          <div class="w-full max-w-2xl sm:text-right space-y-2">
                                                              <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                                                                  <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                      <dt class="col-span-3 text-gray-500">Subtotal:</dt>
                                                                      <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{(numberWithCommas(parseInt(sale.data['0'].sale_info.total_charge) - parseInt(sale.data['0'].sale_info.shipping_charge)))}</dd>
                                                                  </dl>

                                                                  <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                      <dt class="col-span-3 text-gray-500">Shipping:</dt>
                                                                      <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(sale.data['0'].sale_info.shipping_charge))}</dd>
                                                                  </dl>

                                                                  <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                      <dt class="col-span-3 text-gray-500">Discount:</dt>
                                                                      <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{(sale.data['0'].sale_info.discount)? numberWithCommas(parseInt(sale.data['0'].sale_info.discount)) : '00.00'}</dd>
                                                                  </dl>

                                                                  <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                      <dt class="col-span-3 text-gray-500">Total:</dt>
                                                                      <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(sale.data['0'].sale_info.total_charge))}</dd>
                                                                  </dl>

                                                                  <dl class="grid sm:grid-cols-5 font-semibold gap-x-3 text-xs">
                                                                      <dt class="col-span-3 font-bold text-gray-500">Amount paid:</dt>
                                                                      <dd class="col-span-2 font-bold text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(sale.data['0'].sale_info.total_charge))}</dd>
                                                                  </dl>

                                                              </div>
                                                          </div>
                                                      </div>
                                                  :null
                                              }

                                          </div>
                                      </div>
                                  :null
                              }
                      </div>
                      <div className="flex justify-center items-center pb-16">
                        <button onClick={handleCloseModal} className='px-4 py-2 bg-red-600 hover:bg-red-500 rounded-md text-white'>Close</button>
                      </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <Transition.Root show={openBooking} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative md:w-3/4 transform overflowy-y-scroll rounded-lg bg-white text-left shadow-xl transition-all ">
                      <div class="w-full flex flex-col">
                          <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                              <h1 className="text-lg font-semibold uppercase">Booking Invoice</h1>
                          </div>
                              {
                                  (booking)?             
                                      <div className='w-full p-6'>
                                          <div className="w-full mt-4 bg-white p-4 rounded-md">
                                              <div class="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
                                                  <div>
                                                  <h2 class="text-2xl font-semibold text-gray-800 dark:text-gray-200">Invoice</h2>
                                                  </div>

                                                  <div class="inline-flex gap-x-2">
                                                  <a class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                                                      <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                      </svg>
                                                      Invoice PDF
                                                  </a>
                                                  {
                                                          (booking.data.status == '0')?
                                                          <Menu as="div" className=''>
                                                              <Menu.Button className="">
                                                                  {
                                                                      (booking.data.status == '0')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-green-400 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Pending
                                                                          </div>
                                                                      :(booking.data.status == '1')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Ongoing
                                                                          </div>
                                                                      :(booking.data.status == '2')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Completed
                                                                          </div>
                                                                      :(booking.data.status == '3')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Cancelled
                                                                          </div>
                                                                      :null
                                                                  }
                                                              </Menu.Button> 
                                                              <Menu.Items className="absolute focus:outline-none right-24 bg-white rounded-md shadow-lg w-32">
                                                                  {
                                                                      (booking.data.status != '1' && booking.data.status != '2' && booking.data.payment_status != '2' && booking.data.payment_status != '3')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => toggleStatus(booking.data.booking_id, '1')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Approve</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                                  {
                                                                      (booking.data.status != '2' && booking.data.payment_status != '2' && booking.data.payment_status != '3')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => makeCompletedStatus(booking.data.booking_id, '2')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Complete</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                                  {
                                                                      (booking.data.status != '2' && booking.data.payment_status != '3')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => toggleStatus(booking.data.booking_id, '3')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Cancel</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                              </Menu.Items>
                                                          </Menu>
                                                          :(booking.data.status == '1')?
                                                          <Menu as="div" className=''>
                                                              <Menu.Button className="">
                                                                  {
                                                                      (booking.data.status == '0')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-green-400 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Pending
                                                                          </div>
                                                                      :(booking.data.status == '1')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Ongoing
                                                                          </div>
                                                                      :(booking.data.status == '2')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Completed
                                                                          </div>
                                                                      :(booking.data.status == '3')?
                                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                              Cancelled
                                                                          </div>
                                                                      :null
                                                                  }
                                                              </Menu.Button> 
                                                              <Menu.Items className="absolute focus:outline-none right-24 bg-white rounded-md shadow-lg w-32">
                                                                  {
                                                                      (booking.data.status != '1' && booking.data.status != '2')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => toggleStatus(booking.data.booking_id, '1')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Approve</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                                  {
                                                                      (booking.data.status != '2')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => makeCompletedStatus(booking.data.booking_id, '2')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Complete</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                                  {
                                                                      (booking.data.status != '2')?
                                                                          <Menu.Item>
                                                                              <div onClick={(e) => toggleStatus(booking.data.booking_id, '3')} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                                                  <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                                                      <p className="">Cancel</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Menu.Item>
                                                                      :null
                                                                  }
                                                              </Menu.Items>
                                                          </Menu>
                                                          :(booking.data.status == '2')?
                                                              <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                  Completed
                                                              </div>
                                                          :(booking.data.status == '3')?
                                                              <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-orange-200 text-orange-900 focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                  Cancelled
                                                              </div>
                                                          :null
                                                      }
                                                      {
                                                          (booking.data.payment_status == 1)?
                                                              <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-green-400 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                  Paid
                                                              </div>
                                                          :(booking.data.payment_status == 0)?
                                                              (booking.data.transport_charge_payment_status == 3)?
                                                                  <div onClick={() => handleTransportChargePaymentLookUp(booking.data.booking_id, booking.data.transaction_reference)} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-yellow-400 text-white hover:bg-yellow-500 focus:outline-none focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                      Lookup
                                                                  </div>
                                                              :(booking.data.transport_charge_payment_status == 2)?
                                                                  <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-600 text-white focus:outline-none focus:ring-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                      Failed
                                                                  </div>
                                                            :
                                                                <div onClick={(e) => makeCompletedStatus(booking.data.booking_id, '2')} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-500 text-white focus:outline-none transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                                    Unpaid
                                                                    </div>
                                                          :(booking.data.payment_status == 2)?
                                                          <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-600 text-white focus:outline-none focus:ring-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                              Failed
                                                          </div>
                                                          :(booking.data.payment_status == 3)?
                                                          <div onClick={() => handleBookingServicePaymentLookUp(booking.data.booking_id, booking.data.transaction_reference)} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-yellow-400 text-white hover:bg-yellow-500 focus:outline-none focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                              Lookup
                                                          </div>
                                                          :null
                                                      }
                                                  </div>
                                              </div>

                                              <div class="grid md:grid-cols-2 gap-3">
                                                  <div>
                                                      <div class="grid space-y-3">
                                                          <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Billed to:
                                                              </dt>
                                                              <dd class="text-gray-800 dark:text-gray-200">
                                                                  <a class="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="#">
                                                                  {booking.data.name}
                                                                  </a>
                                                              </dd>
                                                          </dl>

                                                          <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Billing details:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  <span class="block font-semibold">{booking.data.name}</span>
                                                                  <address class="not-italic font-normal">
                                                                  {booking.data.address},<br/>
                                                                  {booking.data.phone},<br/>
                                                                  Zambia<br/>
                                                                  </address>
                                                              </dd>
                                                          </dl>

                                                          <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Booking Date:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  <span class="block font-semibold">{booking.data.date}</span>
                                                              </dd>
                                                          </dl>
                                                          <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Working Location:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  <span class="block font-semibold">{booking.data.working_location}</span>
                                                              </dd>
                                                          </dl>
                                                      </div>
                                                  </div>

                                                  <div>
                                                      <div class="grid space-y-3">
                                                          <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Transaction ID:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  {booking.data.transaction_reference}
                                                              </dd>
                                                              </dl>

                                                              <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Currency:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  ZMW - ZAMBIAN KWACHA
                                                              </dd>
                                                              </dl>

                                                              <dl class="grid sm:flex gap-x-3 text-xs">
                                                              <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                  Date:
                                                              </dt>
                                                              <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                  {booking.data.created_at}
                                                              </dd>
                                                              </dl>

                                                              <dl class="grid sm:flex gap-x-3 text-xs">
                                                                  <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                      Billing method:
                                                                  </dt>
                                                                  <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                      {booking.data.payment_method}
                                                                  </dd>
                                                              </dl>

                                                              <dl class="grid sm:flex gap-x-3 text-xs">
                                                                  <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                                      Booking Token:
                                                                  </dt>
                                                                  <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                                      {booking.data.token}
                                                                  </dd>
                                                              </dl>
                                                      </div>
                                                  </div>
                                              </div>

                                              <div class="mt-6 border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                                                  <div class="hidden sm:grid sm:grid-cols-6">
                                                  <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">Service</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Service Type</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Vehicle Make</div>
                                                  <div class="text-left text-xs font-medium text-gray-500 uppercase">Vehicle Model</div>
                                                  <div class="text-right text-xs font-medium text-gray-500 uppercase">Total Amount</div>
                                                  </div>

                                                  <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>               
                                                      <div class="grid text-sm grid-cols-3 sm:grid-cols-6 gap-2">
                                                          <div class="col-span-full sm:col-span-2">
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Service</h5>
                                                              <p class="text-xs text-gray-800 dark:text-gray-200">Booking service</p>
                                                          </div>
                                                          <div>
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Service Type</h5>
                                                              <p class="text-gray-800 text-xs dark:text-gray-200">{(booking.data.service_type == '1')? 'Full Servicing' :(booking.data.service_type == '2')? 'Partial Servicing' :(booking.data.service_type == '3')? 'Custom Servicing' : 'Invalid'}</p>
                                                          </div>
                                                          <div>
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Vehicle Make</h5>
                                                              <p class="text-gray-800 text-xs dark:text-gray-200">{booking.data.brand_name}</p>
                                                          </div>
                                                          <div>
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Vehicle Model</h5>
                                                              <p class="text-gray-800 text-xs dark:text-gray-200">{booking.data.brand_model}</p>
                                                          </div>
                                                          <div>
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Total Amount</h5>
                                                              <p class="sm:text-right text-xs text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(booking.data.service_charge))}</p>
                                                          </div>
                                                  </div>
                                              </div>
                                              <div class="mt-6 border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                                                  <div class="hidden sm:grid sm:grid-cols-6">
                                                  <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">Instructions</div>
                                                  </div>

                                                  <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>               
                                                      <div class="grid text-sm grid-cols-3 sm:grid-cols-6 gap-2">
                                                          <div class="col-span-full sm:col-span-2">
                                                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Instructions</h5>
                                                              <p class="text-gray-800 text-xs dark:text-gray-200">{booking.data.instructions}</p>
                                                          </div>
                                                          
                                                  </div>
                                              </div>

                                                  <div class="mt-8 flex sm:justify-end">
                                                      <div class="w-full max-w-2xl sm:text-right space-y-2">
                                                          <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                                                              <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                  <dt class="col-span-3 text-gray-500">Subtotal:</dt>
                                                                  <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(booking.data.service_charge))}</dd>
                                                              </dl>

                                                              <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                  <dt class="col-span-3 text-gray-500">Transport:</dt>
                                                                  <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{(booking.data.transport_charge)? numberWithCommas(parseInt(booking.data.transport_charge)) : '00.00' }</dd>
                                                              </dl>

                                                              <dl class="grid sm:grid-cols-5 gap-x-3 text-xs">
                                                                  <dt class="col-span-3 text-gray-500">Total:</dt>
                                                                  <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(total())}</dd>
                                                              </dl>

                                                              <dl class="grid sm:grid-cols-5 font-semibold gap-x-3 text-xs">
                                                                  <dt class="col-span-3 font-semibold text-gray-500">Amount paid:</dt>
                                                                  <dd class="col-span-2 text-gray-800 dark:text-gray-200">K{numberWithCommas(paidAmount())}</dd>
                                                              </dl>

                                                          </div>
                                                      </div>
                                                  </div>
                                          </div>
                                      </div>
                                  :null
                              }
                      </div>
                      <div className="flex justify-center items-center pb-16">
                        <button onClick={handleCloseModal} className='px-4 py-2 bg-red-600 hover:bg-red-500 rounded-md text-white'>Close</button>
                      </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <main class="w-full flex-grow p-6">
                <h1 class="text-xl text-black pb-6">Dashboard</h1>
                {
                    (isSuccessRecentSales)?
                    <div class="w-full mt-3">
                        <p class="text-md pb-3 flex items-center">
                            <i class="fas fa-list mr-3"></i> Recent Orders
                        </p>
                        <OrdersTableHelperView data={recentSales} isDashboard={true} view={handleViewOrder} isAll={false} />
                    </div>
                    :(isLoadingRecentSales)?
                        <LoadingSpinner />
                    :(isErrorRecentSales)?
                        <div class="w-full mt-1">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Recent Orders
                            </p>
                            <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                <i class=""></i> No recent Orders found
                            </p>
                            <EmptyOrdersTableHelperView />
                        </div>
                    :null
                }

                {
                    (isSuccessRecentBookings)?
                    <div class="w-full mt-3">
                        <p class="text-md pb-3 flex items-center">
                            <i class="fas fa-list mr-3"></i> Recent Bookings
                        </p>
                        <BookingsTableHelperView data={recentBookings} isDashboard={true} view={handleViewBooking} isAll={true} />
                    </div>
                    :(isLoadingRecentBookings)?
                        <LoadingSpinner />
                    :(isErrorRecentBookings)?
                        <div class="w-full mt-1">
                            <p class="text-md pb-3 flex items-center">
                                <i class="fas fa-list mr-3"></i> Recent Bookings
                            </p>
                            <p class="text-md bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                                <i class=""></i> No recent bookings found
                            </p>
                            <EmptyBookingsTableHelperView />
                        </div>
                    :null
                }

            </main>
        </div>
    )
}

export default AdminUserIndex;
