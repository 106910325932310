import { Link, useNavigate, useParams } from 'react-router-dom';
import { useClearSaleMutation, useGetActiveSalesQuery, useGetAllSalesMutationMutation, useGetClearedSalesMutationMutation, useGetSalesByIdQuery, useUpdatePaymentStatusLookUpMutation } from '../../Features/SalesSlice';
import LoadingSpinner from '../LoadingSpinner';
import swal from 'sweetalert';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { useLookUpPaymentMutation } from '../../Features/lookUpPaymentSlice';


function AdminUserOrderView({props}) {
    const navigate = useNavigate();
    const { sale_id } = useParams();
      
    const {
      data: sale,
      isLoading: isLoadingSale,
      isSuccess: isSuccessSale,
      isError: isErrorSale,
      error: errorSale
    } = useGetSalesByIdQuery(sale_id)

      const [clearSale, {isLoadingClearedSales}] = useClearSaleMutation()

      const handleClearSale = async(id) => {
        if(window.confirm("Are you sure you want clear this order?")){
            try {
              const resp = await clearSale(id).unwrap()
              swal("Success",resp.message,"success");
            } catch (err) {
                if(err.data.hasOwnProperty('errors') == true){
                    swal("Error",err.data.error,"error");
                  }else if (err.data.hasOwnProperty('error') == true){
                    swal("Error",err.data.error,"error");
                  }
            }
        }
      };

      const [updatePaymentStatusLookUp] = useUpdatePaymentStatusLookUpMutation()
      const [lookUpPaymentSlice] = useLookUpPaymentMutation()

      const handlePaymentLookUp = async(id, transaction_reference) => {
        if(window.confirm("Are you sure you want to look up this transaction?")){
            try {
                const data = {
                    paymentReference: 'CMS398762022',
                    systemId: "PBS-Super Merchant",
                    password: "qq!q/Db[pSTn@NbcdS9S"
            }
                const response = await lookUpPaymentSlice(data);

                if(response.data.status == 0){
                    const postData = {
                        status: 1,
                        id: id
                    }
                      const resp = await updatePaymentStatusLookUp(postData).unwrap()
                      return swal("Success",resp.message,"success");
                }
                return swal("Payment lookup failed");
            } catch (err) {
                return swal("Success",err,"success");
            }
        }
      };

      const numberWithCommas = (x)=>{
        if(x !==null){
            const formatedNumber = x.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    });
            return formatedNumber;
        }
        return null;
    }

    return (
        <div class="w-full h-screen  border-t flex flex-col mb-10">
            <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                <h1 className="text-lg font-semibold uppercase">Order Invoice</h1>
            </div>
                {
                    (isSuccessSale)?             
                        <div className='w-full p-6'>
                            <div className="w-full mt-4 bg-white p-4 rounded-md">
                                <div class="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
                                    <div>
                                    <h2 class="text-2xl font-semibold text-gray-800 dark:text-gray-200">Invoice</h2>
                                    </div>

                                    <div class="inline-flex gap-x-2">
                                    <a class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                        </svg>
                                        Invoice PDF
                                    </a>
                                        {
                                            (sale.data['0'].sale_info)?
                                                (sale.data['0'].sale_info.status == '0')?
                                                    <div onClick={() => handleClearSale(sale.data['0'].sale_info.sale_id)} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-green-400 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                        Clear
                                                    </div>
                                                :(sale.data['0'].sale_info.status == '1')?
                                                    <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-500 text-white focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                        Cleared
                                                    </div>
                                                :(sale.data['0'].sale_info.status == '2')?
                                                <div class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-red-600 text-white focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                    Failed
                                                </div>
                                                :(sale.data['0'].sale_info.status == '3')?
                                                <div onClick={() => handlePaymentLookUp(sale.data['0'].sale_info.sale_id, sale.data['0'].sale_info.transaction_reference)} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold cursor-pointer bg-yellow-400 text-white focus:outline-none focus:ring-2  focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                                                    Lookup
                                                </div>
                                                :null
                                            :null
                                        }
                                    </div>
                                </div>

                                {
                                    (sale.data['0'].sale_info)?
                                        <div class="grid md:grid-cols-2 gap-3">
                                            <div>
                                                <div class="grid space-y-3">
                                                    <dl class="grid sm:flex gap-x-3 text-sm">
                                                        <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                            Billed to:
                                                        </dt>
                                                        <dd class="text-gray-800 dark:text-gray-200">
                                                            <a class="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="#">
                                                            {sale.data['0'].sale_info.fname} {sale.data['0'].sale_info.lname}
                                                            </a>
                                                        </dd>
                                                    </dl>

                                                    <dl class="grid sm:flex gap-x-3 text-sm">
                                                        <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                            Billing details:
                                                        </dt>
                                                        <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                            <span class="block font-semibold">{sale.data['0'].sale_info.fname} {sale.data['0'].sale_info.lname}</span>
                                                            <address class="not-italic font-normal">
                                                            {sale.data['0'].sale_info.location_name},<br/>
                                                            {sale.data['0'].sale_info.phone},<br/>
                                                            Zambia<br/>
                                                            </address>
                                                        </dd>
                                                    </dl>

                                                    <dl class="grid sm:flex gap-x-3 text-sm">
                                                        <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                            Shipping details:
                                                        </dt>
                                                        <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                            <span class="block font-semibold">{sale.data['0'].sale_info.fname} {sale.data['0'].sale_info.lname}</span>
                                                            <address class="not-italic font-normal">
                                                            {sale.data['0'].sale_info.location_name},<br/>
                                                            {sale.data['0'].sale_info.phone},<br/>
                                                            Zambia<br/>
                                                            </address>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>

                                            <div>
                                                <div class="grid space-y-3">
                                                    <dl class="grid sm:flex gap-x-3 text-sm">
                                                        <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                            Transaction ID:
                                                        </dt>
                                                        <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                            {sale.data['0'].sale_info.transaction_reference}
                                                        </dd>
                                                        </dl>

                                                        <dl class="grid sm:flex gap-x-3 text-sm">
                                                        <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                            Currency:
                                                        </dt>
                                                        <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                            ZMW - ZAMBIAN KWACHA
                                                        </dd>
                                                        </dl>

                                                        <dl class="grid sm:flex gap-x-3 text-sm">
                                                        <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                            Date:
                                                        </dt>
                                                        <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                            {sale.data['0'].sale_info.created_at}
                                                        </dd>
                                                        </dl>

                                                        <dl class="grid sm:flex gap-x-3 text-sm">
                                                        <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                            Billing method:
                                                        </dt>
                                                        <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                            {sale.data['0'].sale_info.payment_method}
                                                        </dd>
                                                    </dl>

                                                    <dl class="grid sm:flex gap-x-3 text-sm">
                                                    <dt class="min-w-[150px] max-w-[200px] text-gray-500">
                                                        Order number:
                                                    </dt>
                                                    <dd class="font-medium text-gray-800 dark:text-gray-200">
                                                        {sale.data['0'].sale_info.token}
                                                    </dd>
                                                </dl>
                                                </div>
                                            </div>
                                        </div>
                                    :null
                                }

                                <div class="mt-6 border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                                    <div class="hidden sm:grid sm:grid-cols-6">
                                    <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">Item</div>
                                    <div class="text-left text-xs font-medium text-gray-500 uppercase">Qty</div>
                                    <div class="text-left text-xs font-medium text-gray-500 uppercase">Price</div>
                                    <div class="text-left text-xs font-medium text-gray-500 uppercase">Shipping</div>
                                    <div class="text-right text-xs font-medium text-gray-500 uppercase">Total Amount</div>
                                    </div>

                                    <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>
                                        {
                                            (sale.data['1'].products_info)?
                                                sale.data['1'].products_info.map(data => (                
                                                    <div class="text-sm grid grid-cols-3 sm:grid-cols-6 gap-2">
                                                        <div class="col-span-full sm:col-span-2">
                                                            <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Item</h5>
                                                            <p class="font-medium text-gray-800 dark:text-gray-200">{data.product_name}</p>
                                                        </div>
                                                        <div>
                                                            <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Qty</h5>
                                                            <p class="text-gray-800 dark:text-gray-200">{(data.is_backstock)? (data.backorder_quantity > 1)? data.product_quantity+ ' ('+data.backorder_quantity+' backorders)' : data.product_quantity+ ' ('+data.backorder_quantity+' backorder)' : data.product_quantity}</p>
                                                        </div>
                                                        <div>
                                                            <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Price</h5>
                                                            <p class="text-gray-800 dark:text-gray-200">K{data.sale_price}</p>
                                                        </div>
                                                        <div>
                                                            <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Shipping</h5>
                                                            <p class="text-gray-800 dark:text-gray-200">{(data.has_shipping)? <CheckIcon className='h-4' /> : <XIcon className='h-4'/>}</p>
                                                        </div>
                                                        <div>
                                                            <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase">Total Amount</h5>
                                                            <p class="sm:text-right text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(data.total_price))}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            :null
                                        }
                                </div>

                                {
                                    (sale.data['0'].sale_info)?
                                        <div class="mt-8 flex sm:justify-end">
                                            <div class="w-full max-w-2xl sm:text-right space-y-2">
                                                <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                                                    <dl class="grid sm:grid-cols-5 gap-x-3 text-sm">
                                                        <dt class="col-span-3 text-gray-500">Subtotal:</dt>
                                                        <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{(numberWithCommas(parseInt(sale.data['0'].sale_info.total_charge) - parseInt(sale.data['0'].sale_info.shipping_charge)))}</dd>
                                                    </dl>

                                                    <dl class="grid sm:grid-cols-5 gap-x-3 text-sm">
                                                        <dt class="col-span-3 text-gray-500">Shipping:</dt>
                                                        <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(sale.data['0'].sale_info.shipping_charge))}</dd>
                                                    </dl>

                                                    <dl class="grid sm:grid-cols-5 gap-x-3 text-sm">
                                                        <dt class="col-span-3 text-gray-500">Discount:</dt>
                                                        <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{(sale.data['0'].sale_info.discount)? numberWithCommas(parseInt(sale.data['0'].sale_info.discount)) : '00.00'}</dd>
                                                    </dl>

                                                    <dl class="grid sm:grid-cols-5 gap-x-3 text-sm">
                                                        <dt class="col-span-3 text-gray-500">Total:</dt>
                                                        <dd class="col-span-2 font-medium text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(sale.data['0'].sale_info.total_charge))}</dd>
                                                    </dl>

                                                    <dl class="grid sm:grid-cols-5 font-semibold gap-x-3 text-sm">
                                                        <dt class="col-span-3 font-semibold text-gray-500">Amount paid:</dt>
                                                        <dd class="col-span-2 text-gray-800 dark:text-gray-200">K{numberWithCommas(parseInt(sale.data['0'].sale_info.total_charge))}</dd>
                                                    </dl>

                                                </div>
                                            </div>
                                        </div>
                                    :null
                                }

                            </div>
                        </div>
                    :(isLoadingSale)?
                        <LoadingSpinner />
                    :(isErrorSale)?
                        <div>
                            failed
                        </div>
                    :null
                }
        </div>
    )
}

export default AdminUserOrderView;
