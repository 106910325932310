import { Link, useNavigate, useParams } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import swal from 'sweetalert';
import { useDeleteCategoryMutation, useGetCategoryByIdMutationMutation } from '../../Features/categoriesSlice';
import LoadingSpinner from '../LoadingSpinner';
import { useCreateBrandModelMutation, useDeleteBrandModelMutation, useEditBrandModelMutation, useGetBrandModelByIdMutationMutation, useGetBrandModelsQuery } from '../../Features/brandsSlice';


function AdminUserManageBrandModelsView ({props}) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openBrandModelModal, setOpenBrandModelModal] = useState(false)
    const cancelButtonRef = useRef(null)
    const { brand_id } = useParams();

    const [input, setInput] = useState({
      model: '',
      full_service_charge: '',
      partial_service_charge: '',
      custom_service_charge: '',
      id: '',
    })

    const [errors, setErrors] = useState({
        model: '',
        full_service_charge: '',
        partial_service_charge: '',
        custom_service_charge: '',
    })
      
    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const handleCloseEditModal = (e)=>{
      setInput({...input,  
        model: '',
        full_service_charge: '',
        partial_service_charge: '',
        custom_service_charge: '',
      })

      setErrors({...errors,  
        model: '',
        full_service_charge: '',
        partial_service_charge: '',
        custom_service_charge: '',
      })
      setOpenEditModal(false)
    }

    const {
      data: brandModels,
      isLoading: isLoadingBrandModels,
      isSuccess: isSuccessBrandModels,
      isError: isErrorBrandModels,
      error: errorBrandModels
    } = useGetBrandModelsQuery(brand_id)

    const [editBrandModel, {isLoading: isLoadingEdit, isSuccess: isSuccessEdit}] = useEditBrandModelMutation()

    const handleEditBrandModelSubmit = async(e)=>{
        e.preventDefault();
        try {
            const resp = await editBrandModel(input).unwrap()
            handleCloseEditModal()
            swal("Success",resp.message,"success");
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors,  
                  model: err.data.errors.model,
                  full_service_charge: err.data.errors.full_service_charge,
                  partial_service_charge: err.data.errors.partial_service_charge,
                  custom_service_charge: err.data.errors.custom_service_charge,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const [deleteBrandModel, {isLoading: isLoadingDelete, isSuccess: isSuccessDelete}] = useDeleteBrandModelMutation()

    const handleDelete = async(e, id)=>{
      if(window.confirm("Are you sure you want to delete this item?")){
        try {
            const resp = await deleteBrandModel(id).unwrap()
            swal("Success",resp.message,"success");
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
      }
    }

    const [getBrandModelByIdMutation, {isLoading: isLoadingGetCategoryById, isSuccess: isSuccessGetCategoryById}] = useGetBrandModelByIdMutationMutation()

    const handleEdit = async(e, id)=>{
      try {
          const resp = await getBrandModelByIdMutation(id).unwrap()

          setInput({...input,  
            model: resp.data.model,
            full_service_charge: resp.data.full_service_charge,
            partial_service_charge: resp.data.partial_service_charge,
            custom_service_charge: resp.data.custom_service_charge,
            id: resp.data.id,
          })
          setOpenEditModal(true)
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
      }
    }

    const [createBrandModel, {isLoading: isLoadingCreateBrandModel, isSuccess:isSuccessCreateBrandModel}] = useCreateBrandModelMutation()

    const handleCreatBrandelSubmit = async(e)=>{
      e.preventDefault();
      try {
          const data = {
            model: input.model,
            full_service_charge: input.full_service_charge,
            partial_service_charge: input.partial_service_charge,
            custom_service_charge: input.custom_service_charge,
            brand_id: brand_id,
          }
          const resp = await createBrandModel(data).unwrap()
          handleCloseBrandModelModal()
          swal("Success",resp.message,"success");
      } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
              setErrors({...errors,  
                model: err.data.errors.model,
                full_service_charge: err.data.errors.full_service_charge,
                partial_service_charge: err.data.errors.partial_service_charge,
                custom_service_charge: err.data.errors.custom_service_charge,
              })
          }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
          }
      }           
  }

  const handleAddBrandModel = async(e, id)=>{
    setInput({...input,  
      id: id,
    })
    setOpenBrandModelModal(true)
  }

  const handleCloseBrandModelModal = (e)=>{
    setInput({...input,  
      model: '',
      full_service_charge: '',
      partial_service_charge: '',
      custom_service_charge: '',
      id: '',
    })

    setErrors({...errors,  
      model: '',
      full_service_charge: '',
      partial_service_charge: '',
      custom_service_charge: '',
    })
    setOpenBrandModelModal(false)
  }

    return (
        <main className='w-full flex-grow'>
            <Transition.Root show={openBrandModelModal} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenBrandModelModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                            Add Brand Model
                        </p>
                        <form autoComplete='off'>
                        <div class="pl-6 pr-6 pt-6">
                            <label className="block text-sm text-gray-600" for="model">Model</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="model" 
                              id="model" 
                              value={input.model}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.model}</p>
                        </div>
                        <div class="pl-6 pr-6 pt-3">
                            <label className="block text-sm text-gray-600" for="full_service_charge">Full Service Charge</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="full_service_charge" 
                              id="full_service_charge" 
                              value={input.full_service_charge}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.model}</p>
                        </div>
                        <div class="pl-6 pr-6 pt-3">
                            <label className="block text-sm text-gray-600" for="partial_service_charge">Partial Service Charge</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="partial_service_charge" 
                              id="partial_service_charge" 
                              value={input.partial_service_charge}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.partial_service_charge}</p>
                        </div>
                        <div class="pl-6 pr-6 pt-3">
                            <label className="block text-sm text-gray-600" for="custom_service_charge">Custom Service Charge</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="custom_service_charge" 
                              id="custom_service_charge" 
                              value={input.custom_service_charge}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.custom_service_charge}</p>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                            onClick={handleCreatBrandelSubmit}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={handleCloseBrandModelModal}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <Transition.Root show={openEditModal} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenEditModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                            Edit Brand Model
                        </p>
                        <form autoComplete='off'>
                        <div class="p-6">
                            <label className="block text-sm text-gray-600" for="model">Name</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="model" 
                              id="model" 
                              value={input.model}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.model}</p>
                        </div>
                        <div class="pl-6 pr-6 pt-3">
                            <label className="block text-sm text-gray-600" for="full_service_charge">Full Service Charge</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="full_service_charge" 
                              id="full_service_charge" 
                              value={input.full_service_charge}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.full_service_charge}</p>
                        </div>
                        <div class="pl-6 pr-6 pt-3">
                            <label className="block text-sm text-gray-600" for="partial_service_charge">Partial Service Charge</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="partial_service_charge" 
                              id="partial_service_charge" 
                              value={input.partial_service_charge}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.partial_service_charge}</p>
                        </div>
                        <div class="pl-6 pr-6 pt-3">
                            <label className="block text-sm text-gray-600" for="custom_service_charge">Custom Service Charge</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="custom_service_charge" 
                              id="custom_service_charge" 
                              value={input.custom_service_charge}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.custom_service_charge}</p>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                            onClick={handleEditBrandModelSubmit}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={handleCloseEditModal}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
            <div className='flex items-center justify-between'>
            <div className="w-full flex-grow">
                <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                    <h1 className="text-lg font-semibold">Brand Models</h1>
                </div>
            </div>
            </div>
            {
              (isSuccessBrandModels)?
                <div className='flex justify-center'>
                  <div className="w-full lg:w-2/2 mt-4 p-4 rounded-md">
                    <div className="bg-white p-4 rounded-md">
                      <button
                          type="button"
                          class="py-2 px-4 sm:ml-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                          onClick={handleAddBrandModel}
                        >
                          Add Model
                        </button>
                          <table className="text-left w-full border-collapse mt-6"> 
                              <thead>
                                  <tr>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Model</th>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Full Service</th>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Partial Service</th>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Custom Service</th>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Actions</th>
                                  </tr>
                              </thead>
                              <tbody>
                                {
                                  (brandModels.data.length > 0)?
                                  brandModels.data.map(data => (
                                      <tr className="hover:bg-gray-50">
                                          <td className="py-1 px-6 border-b border-grey-light">{data.model}</td>
                                          <td className="py-1 px-6 border-b border-grey-light">K{data.full_service_charge}</td>
                                          <td className="py-1 px-6 border-b border-grey-light">K{data.partial_service_charge}</td>
                                          <td className="py-1 px-6 border-b border-grey-light">K{data.custom_service_charge}</td>
                                          <td className="py-1 px-6 border-b border-grey-light">
                                              <div className='flex items-center space-x-3'>
                                              <div onClick={(e)=>handleEdit(e,data.id)} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Edit</div>
                                              <div onClick={(e)=>handleDelete(e,data.id)} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Delete</div>
                                              </div>
                                          </td>
                                      </tr>
                                    ))
                                  :null
                                }
                              </tbody>
                          </table>
                      </div>
                    </div>
                  </div>
                :(isLoadingBrandModels)?
                  <LoadingSpinner />
                :(isErrorBrandModels)?
                <div className='flex justify-center'>
                  <div className="w-full lg:w-2/2 mt-4 p-4 rounded-md">
                    <div className="bg-white p-4 rounded-md">
                      <button
                          type="button"
                          class="py-2 px-4 sm:ml-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                          onClick={handleAddBrandModel}
                        >
                          Add Model
                        </button>
                        <p class="text-md sm:ml-4 mt-2 mb-2 bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                            <i class=""></i> No brand models found
                        </p>
                          <table className="text-left w-full border-collapse mt-6"> 
                              <thead>
                                  <tr>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Model</th>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Actions</th>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Full Service Charge</th>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Partial Service Charge</th>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Custom Service Charge</th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr className="hover:bg-gray-50">
                                    <td className="py-2 px-6 border-b border-grey-light"></td>
                                    <td className="py-2 px-6 border-b border-grey-light">
                                        <div className='flex items-center space-x-3'>
                                        <div className="py-1 cursor-pointer text-blue-600 font-light tracking-wider"></div>
                                        <div className="py-1 cursor-pointer text-blue-600 font-light tracking-wider"></div>
                                        <div className="py-1 cursor-pointer text-blue-600 font-light tracking-wider"></div>
                                        <div className="py-1 cursor-pointer text-blue-600 font-light tracking-wider"></div>
                                        <div className="py-1 cursor-pointer text-blue-600 font-light tracking-wider"></div>
                                        </div>
                                    </td>
                                </tr>
                              </tbody>
                          </table>
                      </div>
                    </div>
                </div>
              :null
            }
        </main>
    )
}

export default AdminUserManageBrandModelsView ;
