
import { HomeIcon, PhoneIcon } from '@heroicons/react/outline';
import Home from '../../HeaderLayouts/Home';
import { useEffect, useState } from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
  } from "react-google-maps";

function ContactPageView({props}) {
    const [open, setOpen] = useState(false)

    const toggleQuestion = (id)=>{
        (id == open)? setOpen(false) : setOpen(id)
    }

    const AnyReactComponent = ({ text }) => <div>{text}</div>;

    useEffect(()=>{
        window.scrollTo(0, 0)
        return () =>{
        }
      },[]);

      const defaultProps = {
        center: {
          lat: -15.421738,
          lng: 28.343379
        },
        zoom: 50
      };

    return (
        <main className='w-full bg-gray-50 flex-grow'>
            <Home />
            <div className='p-6'>
                <section class="bg-white text-gray-900 body-font"> 
                    <div class="container px-5 py-6 mx-auto">
                        <div class="text-start mb-12">
                            <h1 class="text-3xl leading-relaxed text-center xl:w-2/6 lg:w-3/4 mx-auto text-gray-900 font-extrabold mb-4">Get In Touch</h1>
                            <h1 class="sm:text-m text-md font-sm text-center title-font text-text-400 ">We are at your disposal 7 days a week!</h1>
                        </div>
                    </div>

                    <section className="text-gray-600 body-font pb-20">
                        <div className="container md:px-2 mx-auto">
                        <div className="flex justify-center">
                            <div className="flex flex-wrap items-center  rounded-md">
                                <div className="flex flex-wrap justify-center items-center">
                                    <div className="w-1/2 md:w-1/4">
                                        <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                            <HomeIcon className="h-5 text-blue-500" />
                                            <p className='text-sm text-gray-900 text-md'>Head Office</p>
                                        </p>
                                        <div className="leading-relaxed text-base border-b ">
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>Autozed Ltd</p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>P.O Box F/W-189</p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>Plot no 1345 corner of</p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>Freedomway & Kalambo Road,</p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>Lusaka</p>
                                        </div>
                                        <div className="leading-relaxed mt-2 text-base">
                                            <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                                <PhoneIcon className="h-5 text-blue-500" />
                                                <p className='text-sm text-gray-800 text-md'>Phone Number</p>
                                            </p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-600 pb-1'>+260 96 5878692</p>
                                        </div>

                                        <div className="leading-relaxed mt-2 text-base">
                                            <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 text-blue-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                </svg>

                                                <p className='text-sm text-gray-800 text-md'>Email Us</p>
                                            </p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-600 pb-1'>sales@autozedltd.com</p>
                                        </div>
                                        <div className="leading-relaxed mt-2 text-base">
                                        <div style={{ height: '250px', width: '150px' }}>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.3627086539727!2d28.2778849!3d-15.410962899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f585a33769d3%3A0x7693e92c7a4e59b1!2sAUTO%20ZED%20LTD!5e0!3m2!1sen!2szm!4v1691492628018!5m2!1sen!2szm" style={{width: '150px', height: '250px', border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="w-1/2 md:w-1/4">
                                        <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                            <HomeIcon className="h-5 text-blue-500" />
                                            <p className='text-sm text-gray-900 text-md'>Branch #2</p>
                                        </p>
                                        <div className="leading-relaxed text-base border-b ">
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>Off Los Angeles Road</p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>Opp. Saro</p>
                                            <p className='text-white text-sm pb-1'>Plot no 1345 corner of</p>
                                            <p className='text-white text-sm pb-1'>Freedomway & Kalambo Road,</p>
                                            <p className='text-white text-sm pb-1'>Lusaka</p>
                                        </div>
                                        <div className="leading-relaxed mt-2 text-base">
                                            <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                                <PhoneIcon className="h-5 text-blue-500" />
                                                <p className='text-sm text-gray-800 text-md'>Phone Number</p>
                                            </p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-600 pb-1'>+260 96 5878692</p>
                                        </div>

                                        <div className="leading-relaxed mt-2 text-base">
                                            <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 text-blue-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                </svg>

                                                <p className='text-sm text-gray-800 text-md'>Email Us</p>
                                            </p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-600 pb-1'>sales@autozedltd.com</p>
                                        </div>
                                        <div className="leading-relaxed mt-2 text-base">
                                        <div style={{ height: '250px', width: '150px' }}>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.1299141767245!2d28.275232799999994!3d-15.423537999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f3cd8082bd57%3A0x337253dd1405464!2sAUTO%20ZED%20LTD!5e0!3m2!1sen!2szm!4v1691492894938!5m2!1sen!2szm" style={{width: '150px', height: '250px', border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="w-1/2 md:w-1/4 mt-5 md:mt-0">
                                        <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                            <HomeIcon className="h-5 text-blue-500" />
                                            <p className='text-sm text-gray-900 text-md'>Branch #3</p>
                                        </p>
                                        <div className="leading-relaxed text-base border-b ">
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>Shop No. 5 </p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>Ezgo Auto Mall</p>
                                            <p className='text-gray-500 text-sm pb-1'>Next to Auto World</p>
                                            <p className='text-gray-500 text-sm pb-1'>Freedomway</p>
                                            <p className='text-gray-500 text-sm pb-1'>Lusaka</p>
                                            <p className='text-white text-sm pb-1'>Lusaka</p>
                                        </div>
                                        <div className="leading-relaxed mt-2 text-base">
                                            <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                                <PhoneIcon className="h-5 text-blue-500" />
                                                <p className='text-sm text-gray-800 text-md'>Phone Number</p>
                                            </p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-600 pb-1'>+260 96 5878692</p>
                                        </div>

                                        <div className="leading-relaxed mt-2 text-base">
                                            <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 text-blue-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                </svg>

                                                <p className='text-sm text-gray-800 text-md'>Email Us</p>
                                            </p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-600 pb-1'>sales@autozedltd.com</p>
                                        </div>
                                        <div className="leading-relaxed mt-2 text-base">
                                        <div style={{ height: '250px', width: '150px' }}>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3846.33413373413!2d28.27791!3d-15.412507000000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTXCsDI0JzQ1LjAiUyAyOMKwMTYnNDAuNSJF!5e0!3m2!1sen!2szm!4v1691494438657!5m2!1sen!2szm" style={{width: '150px', height: '250px', border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="w-1/2 md:w-1/4 mt-5 md:mt-0">
                                        <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                            <HomeIcon className="h-5 text-blue-500" />
                                            <p className='text-sm text-gray-900 text-md'>Branch #4</p>
                                        </p>
                                        <div className="leading-relaxed text-base border-b ">
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>Shop No. 5 </p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-400 pb-1'>Plot No 488</p>
                                            <p className='text-gray-500 text-sm pb-1'>Malasha Road</p>
                                            <p className='text-gray-500 text-sm pb-1'>Lusaka</p>
                                            <p className='text-white text-sm pb-1'>Lusaka</p>
                                            <p className='text-white text-sm pb-1'>Lusaka</p>
                                        </div>
                                        <div className="leading-relaxed mt-2 text-base">
                                            <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                                <PhoneIcon className="h-5 text-blue-500" />
                                                <p className='text-sm text-gray-800 text-md'>Phone Number</p>
                                            </p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-600 pb-1'>+260 96 5878692</p>
                                        </div>

                                        <div className="leading-relaxed mt-2 text-base">
                                            <p className='flex space-x-2 mb-2 items-center text-gray-600 text-sm hover:text-yellow-600 pb-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 text-blue-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                </svg>

                                                <p className='text-sm text-gray-800 text-md'>Email Us</p>
                                            </p>
                                            <p className='text-gray-500 text-sm hover:text-yellow-600 pb-1'>sales@autozedltd.com</p>
                                        </div>
                                        <div className="leading-relaxed mt-2 text-base">
                                        <div style={{ height: '250px', width: '150px' }}>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3846.3223264453873!2d28.277431075122333!3d-15.413144985175267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTXCsDI0JzQ3LjMiUyAyOMKwMTYnNDguMCJF!5e0!3m2!1sen!2szm!4v1691494252233!5m2!1sen!2szm" style={{width: '150px', height: '250px', border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                </section>
            </div>
        </main>
    )
}

export default ContactPageView;
