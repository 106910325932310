import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from 'react';
import {ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import { useState } from "react";

function CarouselCommentsSliderView(props) {  
    const navigate = useNavigate();

    const sliderContainerRef = useRef(null);
    const sliderRef = useRef(null);
    const [elementsToShow, setElementsToShow] = useState(1);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        const sliderContainer = sliderContainerRef.current;
        const slider = sliderRef.current;
        const cards = slider.getElementsByTagName('li');

        const handleResize = () => {
            if (window.innerWidth < 1000) {
              setElementsToShow(1);
            } else if (window.innerWidth < 1500) {
              setElementsToShow(1);
            }
          };
      
        window.addEventListener('resize', handleResize);

        const sliderContainerWidth = sliderContainer.clientWidth;
        const cardsWidth = sliderContainerWidth / elementsToShow;

        slider.style.width = cards.length * cardsWidth + 'px';
        slider.style.transition = 'margin';
        slider.style.transitionDuration = '1s';

        for (let index = 0; index < cards.length; index++) {
        const element = cards[index];
        element.style.width = cardsWidth + 'px';
        }

        //  Auto slide to the next item every 3 seconds
         const interval = setInterval(() => {
            if (!isPaused) {
              next();
            }
          }, 3000);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearInterval(interval);
          };
    }, [isPaused]);

    const prev = ()=> {
        const sliderContainer = sliderContainerRef.current;
        const slider = sliderRef.current;
        const cards = slider.getElementsByTagName('li');

        const sliderContainerWidth = sliderContainer.clientWidth;
        const cardsWidth = sliderContainerWidth / elementsToShow;

        const currentMarginLeft = +slider.style.marginLeft.slice(0, -2);
        const maxMarginLeft = -cardsWidth * (cards.length - elementsToShow);

        if (currentMarginLeft === 0) {
        // If at the first item, wrap around to the last item
        slider.style.marginLeft = maxMarginLeft + 'px';
        } else {
        slider.style.marginLeft = currentMarginLeft + cardsWidth + 'px';
        }
    }

    const next = ()=> {
        const sliderContainer = sliderContainerRef.current;
        const slider = sliderRef.current;
        const cards = slider.getElementsByTagName('li');
    
        const sliderContainerWidth = sliderContainer.clientWidth;
        const cardsWidth = sliderContainerWidth / elementsToShow;
    
        const currentMarginLeft = +slider.style.marginLeft.slice(0, -2);
        const maxMarginLeft = -cardsWidth * (cards.length - elementsToShow);
    
        if (currentMarginLeft === maxMarginLeft) {
          // If at the last item, wrap around to the first item
          slider.style.marginLeft = '0px';
        } else {
          slider.style.marginLeft = currentMarginLeft - cardsWidth + 'px';
        }
    }

    const handleItemHover = () => {
        setIsPaused(true);
      };
    
      const handleItemLeave = () => {
        setIsPaused(false);
      };

    const trancateText = (str)=> {
        return str.length > 50 ? str.substring(0, 80) + "..." : str;
    }

    return (
        <section className=''>
            <div>
                <div className="flex justify-end bg-gray-50 items-center space-x-2 pr-5">
                    <div onClick={prev} className="flex items-center">
                        <button onMouseEnter={handleItemHover} onMouseLeave={handleItemLeave} className="rounded-full shadow-lg p-1 flex items-center justify-center text-gray-700 hover:bg-rose-500 hover:text-white">
                                <ChevronLeftIcon className="h-6 w-6" />
                            </button>
                        </div>
                    <div onClick={next} className="flex items-center">
                        <button onMouseEnter={handleItemHover} onMouseLeave={handleItemLeave} className="rounded-full shadow-lg p-1 flex items-center justify-center text-gray-700 hover:bg-rose-500 hover:text-white">
                            <ChevronRightIcon className="h-6 w-6" /> 
                        </button>
                    </div> 
                </div>
                <div  className="md:p-6 flex">        
                    <div style={{backgroundImage: `url(https://api.autozedltd.com/uploads/h3_BG.jpg)`}} ref={sliderContainerRef} id="sliderContainer" className="w-full overflow-hidden">
                        <ul ref={sliderRef} id="slider" className="flex w-full md:py-10">
                            <li className='md:p-5 p-2 flex justify-center' key={''} onMouseEnter={handleItemHover} onMouseLeave={handleItemLeave} style={{ marginRight: '0px' }}>
                                <div class="md:w-3/4  border rounded-t-lg rounded-b-md p-2 h-84 md:96">
                                    <div>
                                        <div className="flex items-end justify-start space-x-3 border-b pb-2">
                                            <a class="block relative w-16 h-16 rounded-full overflow-hidden">
                                            <img 
                                                className="object-cover w-full rounded-t-md shodow-lg group-hover:opacity-50"
                                                src={`https://api.autozedltd.com/uploads/About_tes3.jpg`} 
                                                alt="Image" 
                                            />
                                            </a>
                                            <h2 class=" title-font font-bold text-md mb-2">
                                                <p className="flex text-gray-100 flex-col">Gozanyulume Nangula</p>
                                                <p className="flex text-gray-300 flex-col">Online Shopper</p>
                                            </h2>
                                        </div>
                                        <div className="">
                                            <div class="flex items-start justify-start md:p-3 mt-4">
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                            </div>
                                            <div class="mt-4 rounded-b-md ml-1 mr-1 flex flex-wrap md:flex-nowrap items-center justify-start">
                                                <h1 className="md:text-9xl text-5xl text-yellow-300">5.0</h1>
                                                <p className="md:text-lg text-md font-bold text-white text-center">
                                                “My car part arrived very quickly! The part needed was a perfect fit and I am so happy there is a company that I can trust for future car parts when needed. I highly recommend this company from my experience.”                                                
                                                </p>        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='md:p-5 p-2 flex justify-center' key={''} onMouseEnter={handleItemHover} onMouseLeave={handleItemLeave} style={{ marginRight: '0px' }}>
                                <div class="md:w-3/4  border rounded-t-lg rounded-b-md p-2 h-84 md:96">
                                    <div>
                                        <div className="flex items-end justify-start space-x-3 border-b pb-2">
                                            <a class="block relative w-16 h-16 rounded-full overflow-hidden">
                                            <img 
                                                className="object-cover w-full rounded-t-md shodow-lg group-hover:opacity-50"
                                                src={`https://api.autozedltd.com/uploads/About_tes3.jpg`} 
                                                alt="Image" 
                                            />
                                            </a>
                                            <h2 class=" title-font font-bold text-md mb-2">
                                                <p className="flex text-gray-100 flex-col">Gozanyulume Nangula</p>
                                                <p className="flex text-gray-300 flex-col">Online Shopper</p>
                                            </h2>
                                        </div>
                                        <div className="">
                                            <div class="flex items-start justify-start md:p-3 mt-4">
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                            </div>
                                            <div class="mt-4 rounded-b-md ml-1 mr-1 flex flex-wrap md:flex-nowrap items-center justify-start">
                                                <h1 className="md:text-9xl text-5xl text-yellow-300">5.0</h1>
                                                <p className="md:text-lg text-md font-bold text-white text-center">
                                                your delivery systems got me to like your services such that you simplified my movements when had no one to remain on my broken car. Boom!!!! a brand new tyre was brought suddenly right after pressing an order.
                                                </p>        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='md:p-5 p-2 flex justify-center' key={''} onMouseEnter={handleItemHover} onMouseLeave={handleItemLeave} style={{ marginRight: '0px' }}>
                                <div class="md:w-3/4  border rounded-t-lg rounded-b-md p-2 h-84 md:96">
                                    <div>
                                        <div className="flex items-end justify-start space-x-3 border-b pb-2">
                                            <a class="block relative w-16 h-16 rounded-full overflow-hidden">
                                            <img 
                                                className="object-cover w-full rounded-t-md shodow-lg group-hover:opacity-50"
                                                src={`https://api.autozedltd.com/uploads/About_tes3.jpg`} 
                                                alt="Image" 
                                            />
                                            </a>
                                            <h2 class=" title-font font-bold text-md mb-2">
                                                <p className="flex text-gray-100 flex-col">Gozanyulume Nangula</p>
                                                <p className="flex text-gray-300 flex-col">Online Shopper</p>
                                            </h2>
                                        </div>
                                        <div className="">
                                            <div class="flex items-start justify-start md:p-3 mt-4">
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                            </div>
                                            <div class="mt-4 rounded-b-md ml-1 mr-1 flex flex-wrap md:flex-nowrap items-center justify-start">
                                                <h1 className="md:text-9xl text-5xl text-yellow-300">5.0</h1>
                                                <p className="md:text-lg text-md font-bold text-white text-center">
                                                Sent my boy to go purchase servicing kit without specifying the brand. guess what? i got the best brands for what i didn't even expect.
                                                </p>        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='md:p-5 p-2 flex justify-center' key={''} onMouseEnter={handleItemHover} onMouseLeave={handleItemLeave} style={{ marginRight: '0px' }}>
                                <div class="md:w-3/4  border rounded-t-lg rounded-b-md p-2 h-84 md:96">
                                    <div>
                                        <div className="flex items-end justify-start space-x-3 border-b pb-2">
                                            <a class="block relative w-16 h-16 rounded-full overflow-hidden">
                                            <img 
                                                className="object-cover w-full rounded-t-md shodow-lg group-hover:opacity-50"
                                                src={`https://api.autozedltd.com/uploads/About_tes3.jpg`} 
                                                alt="Image" 
                                            />
                                            </a>
                                            <h2 class=" title-font font-bold text-md mb-2">
                                                <p className="flex text-gray-100 flex-col">Gozanyulume Nangula</p>
                                                <p className="flex text-gray-300 flex-col">Online Shopper</p>
                                            </h2>
                                        </div>
                                        <div className="">
                                            <div class="flex items-start justify-start md:p-3 mt-4">
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                <svg aria-hidden="true" class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                            </div>
                                            <div class="mt-4 rounded-b-md ml-1 mr-1 flex flex-wrap md:flex-nowrap items-center justify-start">
                                                <h1 className="md:text-9xl text-5xl text-yellow-300">5.0</h1>
                                                <p className="md:text-lg text-md font-bold text-white text-center">
                                                i paid a little amount & the spare part i ordered was delivered right on my door step. nice job
                                                </p>        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CarouselCommentsSliderView;
