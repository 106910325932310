import { useEffect } from 'react';
import Home from '../../HeaderLayouts/Home';

function PrivacyPlicyView({props}) {

  useEffect(()=>{
    window.scrollTo(0, 0)
    return () =>{
    }
  },[]);

    return (
        <main className='w-full bg-white flex-grow'>
            <Home />
            <div className='flex items-center justify-between'>
            <div className="w-full flex-grow">
                <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                    <h1 className="text-lg font-semibold">Privacy Policy</h1>
                </div>
            </div>
            </div>
            <div className='md:p-6'>
            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Who we are</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">The AUTO ZED LIMITED is an incorporated company to be located on Plot No. 1345, Freedom way, Lusaka.

The company is specialized in the general trading of Motor Vehicle Spares, Accessories, Batteries, Tyres and Lubricants.</p>
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Comments</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className=""> 
                  When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.<br/><br/>
                  An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.</p>
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Media</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.
                  </p> 
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Cookies</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.<br/><br/>

                  If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.<br/><br/>

                  When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select "Remember Me", your login will persist for two weeks. If you log out of your account, the login cookies will be removed.<br/><br/>

                  If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.
                  </p> 
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Embedded content from other websites</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.<br/><br/>

                  These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
                  </p> 
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Who we share your data with</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  If you request a password reset, your IP address will be included in the reset email.
                  </p> 
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">How long we retain your data</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.<br/><br/>

                  For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.
                  </p> 
                </span>
              </div>
            </div>
            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">What rights you have over your data</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
                  </p> 
                </span>
              </div>
            </div>

            <div class="rounded flex p-4 h-full items-start">
              <div className="flex flex-col">
                <span class="title-font font-bold text-3xl text-gray-900 mb-3">Where your data is sent</span>
                <span class="title-font font-medium flex space-x-2">
                  <p className="">
                  Visitor comments may be checked through an automated spam detection service.
                  </p> 
                </span>
              </div>
            </div>
            </div>
        </main>
    )
}

export default PrivacyPlicyView;
