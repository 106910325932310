import { apiSlice } from "../app/api/apiSlice";

export const rolesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSystemRoles: builder.query({
            query: () => '/user/roles',
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Roles']
        }),
        getSystemRoleById: builder.query({
            query: (id) => `/user/get-single-role/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Roles']
        }),
        addSystemRole: builder.mutation({
            query: (reqBoby) => ({
                url: `user/add-role`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Roles']
        }),
        editRole: builder.mutation({
            query: (input) => ({
                url: `user/edit-role/${input.id}`,
                method: 'PUT',
                body: {...input}
            }),
            invalidatesTags: ['Roles']
        }),
        getUserRolesByUserId: builder.query({
            query: (id) => `/user/view-user-roles/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Roles']
        }),
        getCurrentUserRoles: builder.query({
            query: () => `/user/user-roles`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['User']
        }),
        assignUserARole: builder.mutation({
            query: ({id, roles}) => ({
                url: `user/assing-user-role/${id}`,
                method: 'POST',
                body: { roles }
            }),
            invalidatesTags: ['Roles']
        }),
        deleteSystemRole: builder.mutation({
            query: (id) => ({
                url: `user/delete-role/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Roles']
        }),
        removeRoleFromUser: builder.mutation({
            query: ({role_id, user_id}) => ({
                url: `user/unregister-user-role/${user_id}/${role_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Roles']
        }),
    })
})

export const {
    useGetSystemRolesQuery,
    useGetSystemRoleByIdQuery,
    useDeleteSystemRoleMutation,
    useAddSystemRoleMutation,
    useGetUserRolesByUserIdQuery,
    useGetCurrentUserRolesQuery,
    useAssignUserARoleMutation,
    useRemoveRoleFromUserMutation,
    useEditRoleMutation,
} = rolesApiSlice

