import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: input => ({
                url: '/user/login',
                method: 'POST',
                body: { ...input }
            })
        }),
        register: builder.mutation({
            query: input => ({
                url: '/user/register',
                method: 'POST',
                body: { ...input }
            })
        }),
    })
})

export const {
    useLoginMutation,
    useRegisterMutation
} = authApiSlice