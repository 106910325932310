import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {persistor, store } from '../src/app/store';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { RecoilRoot } from 'recoil';
import { Toaster } from 'react-hot-toast';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <RecoilRoot>
      <PersistGate persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={
                <div className='customFont'>
                  < App />
                </div>
              } />
            </Routes>
          </BrowserRouter>
      </PersistGate>
      <Toaster position='bottom-center' />
      </RecoilRoot>
    </ Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
