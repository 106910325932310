import { Link, useNavigate } from "react-router-dom";
import Home from "../../HeaderLayouts/Home";
import { ReceiptRefundIcon, ShieldCheckIcon } from "@heroicons/react/outline";
import { useGetAllBrandsQuery } from "../../Features/brandsSlice";
import CommentsByCustomerCarouselSliderView from "./CommentsByCustomerCarouselSliderView";
import { useEffect } from "react";

function AboutPageView(props) {  
    const navigate = useNavigate();

    const {  
        data: brands,
        isLoading: isLoadingBrands,
        isSuccess: isSuccessBrands,
        isError: isErrorBrands,
        error: errorBrands
      } = useGetAllBrandsQuery()

      useEffect(()=>{
        window.scrollTo(0, 0)
        return () =>{
        }
      },[]);

    return (
        <div className='bg-gry-50'>
            <Home />
            <div className="bg-white md:px-12">
                <div className="flex flex-wrap border-b border-gray-200 md:p-3">
                    <div className="md:w-2/3 w-full md:pl-5 pt-5 mb-3 md:mb-0">
                        <section class="text-gray-900 body-font">
                        <div class="container px-5 py-24 mx-auto">
                            <div class="text-start mb-12">
                            <h1 class="sm:text-m text-xl font-medium text-center title-font text-rose-600 mb-4">Welcome To Our Store.</h1>
                            <h1 class="text-3xl leading-relaxed text-center xl:w-3/4 lg:w-3/4 mx-auto text-gray-900 font-bold">Largest auto spare online market-place in the country</h1>
                            </div>
                            <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                            <div class="p-2 sm:w-1/2 w-full items-start">
                                <div class="rounded flex p-4 h-full items-start">
                                    <div className="flex flex-col">
                                    <span class="title-font font-semibold text-gray-500 mb-3">Introduction</span>
                                    <span class="title-font font-medium">
                                        The AUTO ZED LIMITED is an incorporated company to be located on Plot No. 1345, Freedom way, Lusaka. <br/><br/>

                                        The company is specialized in the general trading of Motor Vehicle Spares, Accessories, Batteries, Tyres and Lubricants. <br/><br/>

                                        AUTO ZED LIMITED is registered and located in Zambia. It was established in Zambia since 2014 and having 4 branches and subsidiaries across the country. <br/><br/>

                                        The company has been incorporated in Zambia as a private company limited by shares. The company has been formed to meet the business opportunity, which has arisen as a result of government’s policy to liberalize the economy and embrace private sector participation in social and economic development of the country arising in the manufacturing and trading sector. The company is Dedicated to the service of Republic of Zambia with integrity and trust.
                                    </span>
                                    <span class="title-font font-semibold text-gray-500 mb-3 mt-3">Vision</span>
                                    <span class="title-font font-medium">
                                    “To be one of the major Importer, Distributor and Trader of motor vehicle spare parts and Quality Lubricant products in Zambia.”
                                    </span>
                                    <span class="title-font font-semibold text-gray-500 mb-3 mt-3"></span>
                                    <span class="title-font font-medium">
                                        
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 w-full">
                                <div class="rounded flex p-4 h-full items-start">
                                <div className="flex flex-col">
                                    <span class="title-font font-semibold text-gray-500 mb-3">Mission Statement</span>
                                    <span class="title-font font-medium">
                                        “Fully discerning and dedicated to the services to the Nation with integrity, trust and consistency”<br/><br/>

                                        “To be a partner in prosperity and development of the manufacturing industry through cost efficiency and environmental friendly operations”
                                    </span>
                                    <span class="title-font font-semibold text-gray-500 mb-3 mt-3">Products and Services</span>
                                    <span class="title-font font-medium">
                                    Supplier and Distributor of Engine oil, Lubricants, Adhesives, Automobile spares and its accessories, other motor vehicle spare parts items.
                                    </span>
                                    <span class="title-font font-semibold text-gray-500 mb-3 mt-3">Online Services</span>
                                    <span class="title-font font-medium">
                                    The company has established it’s website to promote online trading so that it can reach upto the people at their conveniences and comfort. <br/><br/>
                                    Auto zed Ltd is Zambia’s No 1 leading online portal for automotive spares and Lubricants. You can find more than 2000 spares and Lubricants online at your finger tips.
                                    </span>
                                    <span class="title-font font-semibold text-gray-500 mb-3 mt-3"></span>
                                    <span class="title-font font-medium">
                                        <section class="text-gray-600 body-font">
                                            <div class="container px- py-2 mx-auto flex flex-wrap">
                                                <div class="flex flex-col -m-4">
                                                <div class="p-2  md:w-full">
                                                    <div class="flex rounded-lg sm:flex-row flex-col">
                                                    <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full flex-shrink-0">
                                                    <ShieldCheckIcon className="h-20 w-40 text-rose-500" />
                                                    </div>
                                                    <div class="flex-grow">
                                                        <h2 class="text-gray-900 text-lg title-font font-bold mb-3">Trusted & Experienced</h2>
                                                        <p class="leading-relaxed text-base">All our mechanics and sales team are fully trained and experienced to offer top notch services.</p>
                                                    </div>
                                                    </div>
                                                    <div class="flex rounded-lg sm:flex-row flex-col">
                                                    <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full flex-shrink-0">
                                                    <ReceiptRefundIcon className="h-20 w-40 text-rose-500" />
                                                    </div>
                                                    <div class="flex-grow">
                                                        <h2 class="text-gray-900 text-lg title-font font-bold mb-3">Reliable & Fully Insured</h2>
                                                        <p class="leading-relaxed text-base">We stock genuine auto parts from reputable brand for all Japanese Vehicles.</p>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </section>
                                    </span>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>
                    </div>
                    <div className="md:w-1/3 w-full md:pl-5 pt-5 md:pr-5 py-24 mb-12">
                    <section>
                        <div className='relative bg-yellow-300'>
                            <ul className='overflow-hidden transition-margin duration-1000 ease-in-out'>
                            <li
                                className={`max-h-96 relative transform`}
                            >
                                <img
                                src={`https://api.autozedltd.com/uploads/motor-repair-store.jpg`}
                                className='h-96 w-full object-fill'
                                alt='Image'
                                />
                            </li>
                            </ul>
                        </div>
                        </section>
                    </div>
                </div>
                <section class="text-gray-900 body-font border-b border-gray-200 pb-24">
                    <div class="container px-5 py-12 mx-auto">
                        <div class="text-center mb-12">
                            <h1 class="sm:text-m text-xl font-medium text-center title-font text-rose-600 mb-4">Why Choose Us</h1>
                            <h1 class="text-3xl leading-relaxed xl:w-2/4 lg:w-1/4 mx-auto text-gray-900 font-bold">Growing with a global creative community</h1>
                        </div>
                    </div>
                    <section class="text-gray-600 body-font">
                        <div class="container px-5 py-2 mx-auto">
                            <div class="flex flex-col">
                            </div>
                            <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
                                <div class="rounded-lg h-54 overflow-hidden">
                                <img alt="content" class="object-fill object-center h-full w-full" src={`https://api.autozedltd.com/uploads/motor-repair-man.jpg`}/>
                                </div>
                                <h2 class="text-xl font-bold text-center title-font text-gray-900 mt-5">Dedicated staff</h2>
                                <p class="text-sm leading-relaxed mt-2 text-center">We have dedicated and experienced staff ready to offer you expert advise for all your auto needs.</p>
                            </div>
                            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
                                <div class="rounded-lg h-54 overflow-hidden">
                                <img alt="content" class="object-fill object-center h-full w-full" src={`https://api.autozedltd.com/uploads/customer-service-for-moto-shop.jpg`}/>
                                </div>
                                <h2 class="text-xl font-bold text-center title-font text-gray-900 mt-5">Individual Approach To Customers</h2>
                                <p class="text-sm leading-relaxed mt-2 text-center">We adapt to needs and demands of customers, offering individual conditions and discounts.</p>
                            </div>
                            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
                                <div class="rounded-lg h-54 overflow-hidden">
                                <img alt="content" class="object-fill object-center h-full w-full" src={`https://api.autozedltd.com/uploads/car-parts.jpg`}/>
                                </div>
                                <h2 class="text-xl font-bold text-center title-font text-gray-900 mt-5">Professional Customer Service</h2>
                                <p class="text-sm leading-relaxed mt-2 text-center">Customer service of our shop is always glad to share their professional knowledge and necessary technical information and support.</p>
                            </div>
                            </div>
                        </div>
                        </section>
                </section>
                <section class="text-gray-900 py-16">
                    <div class="container px-5 py-3 mx-auto">
                        <div class="text-center ">
                            <h1 class="sm:text-m text-xl font-medium text-center title-font text-rose-600 mb-4">Most Valuable Brands Are Available</h1>
                            <h1 class="text-3xl leading-relaxed xl:w-1/4 lg:w-3/4 mx-auto text-gray-900 font-bold">Offer for leading brands of motor cars</h1>
                        </div>
                    </div>
                    <section class="text-gray-900 body-font border-b border-gray-200 pb-24">
                        <div class="container px-5 py-2 mx-auto">
                            <div class="flex flex-col">
                            </div>
                            <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                            {
                                (isSuccessBrands)?
                                    <div className="flex flex-col">
                                        <div className='mr-5 ml-5 flex flex-wrap justify-between items-center pt-5 pb-5'>
                                            {
                                                (brands.data.length > 0)?
                                                    brands.data.map(data => (
                                                        <Link to={`/shop/${data.name}`} className="space-x-10 mb-3 cursor-pointer">
                                                            <img 
                                                                className="object-fill h-30 w-40 shodow-lg hover:opacity-50"
                                                                src={`https://api.autozedltd.com/uploads/`+data.brand_logo} 
                                                                alt="Image" 
                                                            />
                                                        </Link>
                                                    ))
                                                :null
                                            }
                                        </div>
                                    </div>
                                :null
                            }
                            </div>
                        </div>
                    </section>
                    <section class="text-gray-900 body-font pb-12">
                        <div class="container px-5 py-12 mx-auto">
                            <div class="text-center mb-12">
                                <h1 class="sm:text-m text-xl font-medium text-center title-font text-rose-600 mb-4">Testimonials</h1>
                                <h1 class="text-3xl leading-relaxed xl:w-2/1 lg:w-1/4 mx-auto text-gray-900 font-bold">What do clients say about us</h1>
                            </div>
                        </div>
                        <section class="text-gray-600 body-font">
                            <CommentsByCustomerCarouselSliderView />
                        </section>
                    </section>
                </section>
            </div>
        </div>
    )
}

export default AboutPageView;
