import { apiSlice } from "../app/api/apiSlice";

export const bookingsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllBookingsMutation: builder.mutation({
            query: (pagination) => `/user/bookings/${pagination}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['bookings']
        }),
        searchBookings: builder.mutation({
            query: (input) => ({
                url: `user/search-bookings`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Sales']
        }),
        getRecentBookings: builder.query({
            query: () => `/user/recent-bookings`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['bookings']
        }),
        getPendingBookings: builder.query({
            query: ({pagination, page}) => (page < 1)? `/user/pending-bookings/${pagination}` : `user/pending-bookings/${pagination}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['bookings']
        }),
        getApprovedBookings: builder.mutation({
            query: (pagination) => `/user/approved-bookings/${pagination}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['bookings']
        }),
        getCompletedBookings: builder.mutation({
            query: (pagination) => `/user/completed-bookings/${pagination}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['bookings']
        }),
        getCancelledBookings: builder.mutation({
            query: (pagination) => `/user/cancelled-bookings/${pagination}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['bookings']
        }),
        getReceitedBookings: builder.mutation({
            query: (pagination) => `/user/receited-bookings/${pagination}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['bookings']
        }),
        createBooking: builder.mutation({
            query: (input) => ({
                url: `user/create-booking`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['bookings']
        }),
        validateBooking: builder.mutation({
            query: (input) => ({
                url: `user/validate-booking`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['bookings']
        }),
        editBooking: builder.mutation({
            query: (input) => ({
                url: `user/edit-booking/${input.booking_id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['bookings']
        }),
        getBookingById: builder.query({
            query: (id) => `/user/booking/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['bookings']
        }),
        getBookingByIdMutation: builder.mutation({
            query: (id) => `/user/booking/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['bookings']
        }),
        getBookingByTransactionReference: builder.query({
            query: (id) => `/user/booking-by-payment-reference/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['bookings']
        }),
        toggleBookingStatus: builder.mutation({
            query: ({id, status}) => ({
                url: `user/toggle-booking-status/${id}/${status}`,
                method: 'post',
            }),
            invalidatesTags: ['bookings']
        }),
        updateServiceAndTransportPaymentStatus: builder.mutation({
            query: (input) => ({
                url: `user/update-service-and-transport-charge-transaction-status`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['bookings']
        }),
        updateServicePaymentStatus: builder.mutation({
            query: (input) => ({
                url: `user/update-service-charge-transaction-status`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['bookings']
        }),
        updateTransportChargePaymentStatus: builder.mutation({
            query: (input) => ({
                url: `user/update-transport-charge-transaction-status`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['bookings']
        }),
    })
})

export const {
    useCreateBookingMutation,
    useGetRecentBookingsQuery,
    useGetBookingByIdQuery,
    useEditBookingMutation,
    useGetPendingBookingsQuery,
    useGetCompletedBookingsMutation,
    useGetApprovedBookingsMutation,
    useGetCancelledBookingsMutation,
    useGetAllBookingsMutationMutation,
    useToggleBookingStatusMutation,
    useSearchBookingsMutation,
    useGetReceitedBookingsMutation,
    useGetBookingByIdMutationMutation,
    useGetBookingByTransactionReferenceQuery,
    useUpdateServiceAndTransportPaymentStatusMutation,
    useUpdateServicePaymentStatusMutation,
    useUpdateTransportChargePaymentStatusMutation,
    useValidateBookingMutation,
} = bookingsApiSlice

