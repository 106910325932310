import React, { useEffect, useRef, useState } from 'react';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';

function CarouselAutoSlider(props) {
  const home_slider = useRef(null);
  const [totalSlides, setTotalSlides] = useState(0);
  const [currentSlideID, setCurrentSlideID] = useState(1);
  const sliderRef = useRef(null);

  useEffect(() => {
    const sliderElements = home_slider.current;
    const slides = sliderElements.children;
    const slider = home_slider.current;
    slider.style.transition = 'margin';
    slider.style.transitionDuration = '1s';
    
    setTotalSlides(slides.length);
  }, []);

  const next = () => {
    if (currentSlideID < totalSlides) {
      setCurrentSlideID(currentSlideID + 1);
    }
  };

  const prev = () => {
    if (currentSlideID > 1) {
      setCurrentSlideID(currentSlideID - 1);
    }
  };

  useEffect(() => {
    showSlide();
  }, [currentSlideID]);

  const showSlide = () => {
    const slides = home_slider.current.children;
    for (let i = 0; i < slides.length; i++) {
      if (i === currentSlideID - 1) {
        slides[i].classList.remove('hidden');
      } else {
        slides[i].classList.add('hidden');
      }
    }
  };

  return (
    <section>
      <div className='relative'>
        <ul ref={home_slider} id='home-slider' className='overflow-hidden transition-margin duration-1000 ease-in-out'>
          <li
            className={`max-h-96 relative transform ${
              currentSlideID === 1 ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <img
              src='https://api.autozedltd.com/uploads/main-image_1.jpg'
              className='md:h-96 w-full object-fill md:rounded-lg'
              alt='Image'
            />
            <div className='absolute top-0 left-0 h-96 w-full flex'>
              <h2 className='text-lg font-bold text-white my-auto w-full text-center p-20'></h2>
            </div>
          </li>
        </ul>
        <div className='absolute px-5 flex h-full w-full top-0 left-0'>
        </div>
      </div>
    </section>
  );
}

export default CarouselAutoSlider;