import { createSlice } from "@reduxjs/toolkit"

const menuSlice = createSlice({
    name: 'menu',
    initialState: { client_id: null },
    reducers: {
        setClientInfo: (state, action) => {
            const { id } = action.payload
            state.client_id = 5
        },
    },
})

export const { setClientInfo } = menuSlice.actions

export default menuSlice.reducer

export const selectCurrentClientId = (state) => state.menu.client_id
