import { Link, useNavigate, useParams } from 'react-router-dom';
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FileUploader } from "react-drag-drop-files";
import swal from 'sweetalert';
import LoadingSpinner from '../LoadingSpinner';
import {useDeleteBrandMutation, } from '../../Features/brandsSlice';
import {  useGetProductByIdQuery, useToggleProductStatusMutation } from '../../Features/productsSlice';
import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/solid';


function AdminUserProductView({props}) {
    const navigate = useNavigate();
    const { product_id } = useParams();
    const [activeImage, setActiveImage] = useState(false)
      
    const {
      data: product,
      isLoading: isLoadingProducts,
      isSuccess: isSuccessProducts,
      isError: isErrorProducts,
      error: errorProducts
    } = useGetProductByIdQuery(product_id)

    useEffect(()=>{
      if(isSuccessProducts && product.hasOwnProperty("data")){
        setActiveImage(product.data['0'].product.image);

        }
      return () =>{
      }
    },[product]);

    const [toggleProductStatus] = useToggleProductStatusMutation()

    const handleToggleProductStatus = async(id)=>{
      if(window.confirm("Are you sure you want to toggle product status?")){
        try {
            const resp = await toggleProductStatus(id).unwrap()
            swal("Success",resp.message,"success");
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
      }
    }

    const [deleteBrand, {isLoading: isLoadingDelete, isSuccess: isSuccessDelete}] = useDeleteBrandMutation()

    const handleDelete = async(e, id)=>{
      if(window.confirm("Are you sure you want to delete this item?")){
        try {
            const resp = await deleteBrand(id).unwrap()
            swal("Success",resp.message,"success");
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
      }
    }

    const trancateText = (str)=> {
      return str.length > 50 ? str.substring(0, 80) + "..." : str;
  }

  const numberWithCommas = (x)=>{
    if(x !==null){
        const formatedNumber = x.toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                                });
        return formatedNumber;
    }
    return null;
}

    return (
        <main className='w-full flex-grow mb-16'>
            <div className='flex items-center justify-between'>
            <div className="w-full flex-grow">
                <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                    <h1 className="text-lg font-semibold">Product</h1>
                </div>
            </div>
            </div>
            {
              (isSuccessProducts)?
                  <div className='flex justify-center'>
                    <div className="w-full lg:w-2/2 mt-2">
                      <div className="bg-white ">
                      <section class="py-12 sm:py-16"> 
                        <div class="container mx-auto px-4">
                          <nav class="flex">
                            <ol role="list" class="flex items-center">
                              <li class="text-left">
                                <div class="-m-1">
                                  <a href="#" class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"> Admin </a>
                                </div>
                              </li>

                              <li class="text-left">
                                <div class="flex items-center">
                                  <span class="mx-2 text-gray-400">/</span>
                                  <div class="-m-1">
                                    <a href="#" class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"> Products </a>
                                  </div>
                                </div>
                              </li>

                              <li class="text-left">
                                <div class="flex items-center">
                                  <span class="mx-2 text-gray-400">/</span>
                                  <div class="-m-1">
                                    <a href="#" class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800" aria-current="page"> Product </a>
                                  </div>
                                </div>
                              </li>
                            </ol>
                          </nav>

                          <div class="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                            <div class="lg:col-span-3 lg:row-end-1">
                              <div class="lg:flex lg:items-start">
                                <div class="lg:order-2 lg:ml-5">
                                  <div class="max-w-xl overflow-hidden rounded-lg">
                                    <img class="h-full w-full max-w-full object-contain" src={`${(activeImage)? 'https://api.autozedltd.com/uploads/'+activeImage : 'https://api.autozedltd.com/uploads/'+product.data['0'].product.image}`} alt="" />
                                  </div>
                                </div>

                                <div class="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                                  <div class="flex flex-row items-start lg:flex-col">
                                    {
                                      (product.data['1'].product_images)?
                                      product.data['1'].product_images.map(data => (
                                          <button onClick={(e) => setActiveImage(data.image_name)} type="button" class={`${(activeImage == data.image_name)? 'border-2 border-gray-400' : ''} flex-0 aspect-square mb-3 h-20 w-28 overflow-hidden rounded-lg text-center`}>
                                            <img class="h-full w-full object-contain" src={`https://api.autozedltd.com/uploads/`+data.image_name} alt="" />
                                          </button>
                                        ))
                                      :null
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                              <h1 class="text-gray-800 title-font text-lg mt-3">{product.data['0'].product.name}</h1>

                              <div class="mt-5 flex items-center border-b pb-4">
                                <div class="flex items-center">
                                  <svg class="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                  </svg>
                                  <svg class="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                  </svg>
                                  <svg class="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                  </svg>
                                  <svg class="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                  </svg>
                                  <svg class="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                  </svg>
                                </div>
                                <p class="ml-2 text-sm font-medium text-gray-500">4 Reviews</p>
                              </div>

                              <h2 class="mt-3 text-xs uppercase text-gray-900 font-semibold">Product Information</h2>
                              <div class="mt-3 flex select-none items-center gap-1">
                                <div className="flex flex-col gap-y-2">
                                  <div className="flex items-center gap-x-6 justify-start">
                                    <p className="mr-4 text-gray-600">Brand:</p>
                                    <p className="text-gray-700">{(product.data['0'].product.brand != null)? product.data['0'].product.brand :(product.data['0'].product.custome_brand != null)? product.data['0'].product.custome_brand : 'None'}</p>
                                  </div>
                                  <div className="flex items-center gap-x-6 justify-start">
                                    <p className="mr-4 text-gray-600">Category:</p>
                                    <p className="text-gray-700">{product.data['0'].product.category}</p>
                                  </div>
                                  <div className="flex items-center gap-x-6 justify-start">
                                    <p className="mr-4 text-gray-600">Product No:</p>
                                    <p className="text-gray-700">{(product.data['0'].product.sku)? product.data['0'].product.sku : '---'}</p>
                                  </div>
                                  <div className="flex items-center gap-x-6 justify-start">
                                    <p className="mr-4 text-gray-600">Quantity:</p>
                                    <p className="text-gray-700">{(product.data['0'].product.has_inventory)? (product.data['0'].product.quantity < 1)? product.data['0'].product.quantity+ ' (On backorders)' : product.data['0'].product.quantity :(product.data['0'].product.stock_status == '1')? 'In stock' :(product.data['0'].product.stock_status == '2')? 'Out of stock' :(product.data['0'].product.stock_status == '3')? 'On backorders' : null}</p>
                                  </div>
                                </div>
                              </div>

                              <h2 class="mt-8 text-base text-gray-900"></h2>
                              <div class="mt-3 flex select-none flex-wrap items-center gap-1">
                                <label class="">
                                  <input type="radio" name="subscription" value="4 Months" class="peer sr-only" />
                                  <p onClick={(e) => handleToggleProductStatus(product.data['0'].product.product_id)} class={`${(product.data['0'].product.status == 0)? 'bg-green-400 hover:bg-green-500' : 'bg-gray-400 hover:bg-gray-500'} text-white rounded-lg border border-gray-300 cursor-pointer px-6 py-2`}>
                                    {(product.data['0'].status == 0)? 'Active' : 'Disabled'}
                                  </p>
                                </label>
                                <label class="">
                                  <input type="radio" name="subscription" value="8 Months" class="peer sr-only" checked />
                                  <p class=" bg-red-400 hover:bg-red-500 rounded-lg border border-gray-100 cursor-pointer text-white px-6 py-2">Delete</p>
                                </label>
                              </div>

                              <div class="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                                <div class="flex space-x-2 items-end">
                                  <h1 class="text-2xl text-red-500">K{numberWithCommas(parseInt(product.data['0'].product.price))}</h1>
                                  <span class="text-md line-through">K{numberWithCommas(parseInt(product.data['0'].product.old_price))}</span>
                                </div>

                                <Link to={`/admin/edit-product/${product.data['0'].product.product_id}`} class="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-rose-600 bg-none px-8 py-1 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-rose-700">
                                  <PencilIcon className='h-4 mr-1'/>
                                  <div>Edit</div>
                                </Link>
                              </div>

                              <ul class="mt-8 space-y-2">
                                <li class="flex items-center text-left text-sm font-medium text-gray-600">
                                  <svg class="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" class=""></path>
                                  </svg>
                                  <p class={`mt-1 text-xs text-gray-700 flex items-center gap-x-1 ${(product.data['0'].product.has_shipping)? 'text-green-500' : `text-red-500`}`}><div>Shipping:</div>  {(product.data.has_shipping)? <CheckIcon className='h-4' /> : <XIcon className='h-4'/>}</p>
                                </li>
                              </ul>
                            </div>

                            <div class="lg:col-span-3">
                              <div class="border-b border-gray-300">
                                <nav class="flex gap-4">
                                  <a href="#" title="" class="border-b-2 border-gray-900 py-4 text-sm font-medium text-gray-900 hover:border-gray-400 hover:text-gray-800"> Description </a>
                                </nav>
                              </div>
                              <div class="mt-3 flow-root p-4">
                                {product.data['0'].product.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      </div>
                    </div>
                  </div>
                :(isLoadingProducts)?
                  <LoadingSpinner />
                :(isErrorProducts)?
                  <div>
                    failed
                  </div>
              :null
            }
        </main>
    )
}

export default AdminUserProductView;
