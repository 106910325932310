import { apiSlice } from "../app/api/apiSlice";

export const SalesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllSalesMutation: builder.mutation({
            query: (pagination) => `/user/sales/${pagination}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Sales']
        }),
        searchSales: builder.mutation({
            query: (input) => ({
                url: `user/search-sales`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Sales']
        }),
        updatePaymentStatus: builder.mutation({
            query: (input) => ({
                url: `user/transaction-status`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Sales']
        }),
        updatePaymentStatusLookUp: builder.mutation({
            query: (input) => ({
                url: `user/transaction-status-look-up`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Sales']
        }),
        getRecentSales: builder.query({
            query: () => `/user/recent-sales`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Sales']
        }),
        getActiveSales: builder.query({
            query: ({pagination, page}) => (page < 1)? `/user/active-sales/${pagination}` : `user/active-sales/${pagination}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Sales']
        }),
        getClearedSalesMutation: builder.mutation({
            query: (pagination) => `/user/cleared-sales/${pagination}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Sales']
        }),
        validateSale: builder.mutation({
            query: (input) => ({
                url: `user/validate-sale`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Sales']
        }),
        createSale: builder.mutation({
            query: (input) => ({
                url: `user/create-sale`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Sales']
        }),
        getSalesById: builder.query({
            query: (id) => `/user/sale/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Sales']
        }),
        getSalesByIdMutation: builder.mutation({
            query: (id) => `/user/sale/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            invalidatesTags: ['Sales']
        }),
        getSaleByTransactionReference: builder.query({
            query: (id) => `/user/sale-by-payment-reference/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Sales']
        }),
        clearSale: builder.mutation({
            query: (id) => ({
                url: `user/clear-sale/${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Sales']
        }),
        nextPage: builder.mutation({
            query: (url) => `${url}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Sales']
        }),
    })
})

export const {
    useCreateSaleMutation,
    useValidateSaleMutation,
    useGetRecentSalesQuery,
    useGetActiveSalesQuery,
    useGetClearedSalesMutationMutation,
    useGetAllSalesMutationMutation,
    useUpdatePaymentStatusLookUpMutation,
    useGetSalesByIdQuery,
    useClearSaleMutation,
    useUpdatePaymentStatusMutation,
    useGetSalesByIdMutationMutation,
    useGetSaleByTransactionReferenceQuery,
    useSearchSalesMutation,
    useNextPageMutation,
} = SalesApiSlice

