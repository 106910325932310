import React, { useEffect } from 'react'

function UndefinedRouteView() {

  return (
    <div className='flex h-screen bg-white justify-center items-center'>
        <div className='space-y-8 flex flex-col justify-center items-center'>
          <h1 className='text-2xl text-gray-300'>Ooops... resource not found</h1>
          <h1 className='text-2xl font-gray-100'>4 0 4</h1>
        </div>
    </div>
  )
}

export default UndefinedRouteView