import { apiSlice } from "../app/api/apiSlice";

export const clientsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClients: builder.query({
            query: () => '/user/clients',
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients']
        }),
        getRegisteredPartiners: builder.query({
            query: () => '/user/registered-partiners',
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients']
        }),
        getActiveOperators: builder.query({
            query: () => '/user/operators',
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients']
        }),
        getOperatorById: builder.query({
            query: (id) => `/user/operator/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients']
        }),
        registerClient: builder.mutation({
            query: (reqBoby) => ({
                url: `user/register-client`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Clients']
        }),
        validateClientBusinessInformation: builder.mutation({
            query: (reqBoby) => ({
                url: `user/validate-client-business-information`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Clients']
        }),
        validateClientBusinessBankingInformation: builder.mutation({
            query: (reqBoby) => ({
                url: `user/validate-client-business-banking-information`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Clients']
        }),
        editClientProfile: builder.mutation({
            query: (input) => ({
                url: `user/client-edit/${input.id}`,
                method: 'PUT',
                body: {...input}
            }),
            invalidatesTags: ['Clients']
        }),
        getClientById: builder.query({
            query: (id) => `/user/client/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients']
        }),
        toggleClientStatus: builder.mutation({
            query: (id) => `/user/toggle-client-status/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            invalidatesTags: ['Clients']
        }),
        getClientByClientRoleId: builder.query({
            query: (id) => `/user/client-portal/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients']
        }),
        getClientManagerUserSalesUsers: builder.query({
            query: ({admin_user_id, paginate, search, page}) => (page == "")? `/user/client-manager-user-sales-users/${admin_user_id}/${search}/${paginate}` : `user/client-manager-user-sales-users/${admin_user_id}/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientSalesUser']
        }),
        getClientAgentUsers: builder.query({
            query: ({client_id, paginate, search, page}) => (page == "")? `/user/client-stationed-sales-users/${client_id}/${search}/${paginate}` : `user/client-stationed-sales-users/${client_id}/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientSalesUser']
        }),
        getClientMobileAgentUsers: builder.query({
            query: ({client_id, paginate, search, page}) => (page == "")? `/user/client-mobile-sales-users/${client_id}/${search}/${paginate}` : `user/client-mobile-sales-users/${client_id}/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientSalesUser']
        }),
        getClientAgentUsersForSalesUserRegistration: builder.query({
            query: (client_id) => `/user/client-manager-users-for-sales-user-registration/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientSalesUser']
        }),
        getAllClientManagerUsers: builder.query({
            query: ({client_id, paginate, search, page}) => (page == "")? `/user/client-manager-users/${client_id}/${search}/${paginate}` : `user/client-manager-users/${client_id}/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientManagerUser']
        }),
        getAllClientDataAnalystManagerUsers: builder.query({
            query: ({client_id, paginate, search, page}) => (page == "")? `/user/client-data-analyst-manager-users/${client_id}/${search}/${paginate}` : `user/client-data-analyst-manager-users/${client_id}/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientAnalystManagerUser']
        }),
        getAllClientAccountingUsers: builder.query({
            query: ({client_id, paginate, search, page}) => (page == "")? `/user/client-accounting-users/${client_id}/${search}/${paginate}` : `user/client-accounting-users/${client_id}/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientAccountingUser']
        }),
        getAllClientMarketingUsers: builder.query({
            query: ({client_id, paginate, search, page}) => (page == "")? `/user/client-marketing-users/${client_id}/${search}/${paginate}` : `user/client-marketing-users/${client_id}/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientMarketingUser']
        }),
        clientCustomerRetentionAnalysisReport: builder.query({
            query: (client_id) =>`user/client-customer-retention-analysis/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 20
        }),
    })
})
export const {
    useGetClientsQuery,
    useGetClientByIdQuery,
    useToggleClientStatusMutation,
    useGetActiveOperatorsQuery,
    useGetOperatorByIdQuery,
    useGetRegisteredPartinersQuery,
    useClientCustomerRetentionAnalysisReportQuery,
    useGetAllClientAccountingUsersQuery,
    useGetAllClientMarketingUsersQuery,
    useValidateClientBusinessInformationMutation,
    useValidateClientBusinessBankingInformationMutation,
    useGetClientManagerUserSalesUsersQuery,
    useGetAllClientManagerUsersQuery,
    useGetClientMobileAgentUsersQuery,
    useGetAllClientDataAnalystManagerUsersQuery,
    useGetClientAgentUsersForSalesUserRegistrationQuery,
    useGetClientAgentUsersQuery,
    useRegisterClientMutation,
    useGetClientByClientRoleIdQuery,
    useEditClientProfileMutation,
} = clientsApiSlice

