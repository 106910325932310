import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useNextPageMutation } from '../../Features/SalesSlice';

function OrdersTableHelperView({data, prev, next, view, isDashboard, isAll}) {
    const navigate = useNavigate();

    const numberWithCommas = (x)=>{
        if(x !==null){
            const formatedNumber = x.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    });
            return formatedNumber;
        }
        return null;
    }

    const nextPage = ()=>{
        next(data.next_page_url);
    }

    const prevPage = ()=>{
        if(data.prev_page_url){
            prev(data.prev_page_url);
        }
    }

    return (
            <div class="bg-white overflow-auto">
                <table class="text-left w-full border-collapse">
                    <thead>
                            <tr>
                                <th
                                    class="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">
                                    Order no.
                                </th>
                                <th
                                    class="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Name
                                </th>
                                <th
                                    class="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Phone
                                </th>
                                <th
                                    class="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Location
                                </th>
                                <th
                                    class="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Charge
                                </th>
                                <th
                                    class="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Shipping
                                </th>
                                <th
                                    class="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Discount
                                </th>
                                <th
                                    class="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    QTY
                                </th>
                                <th
                                    class="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Status
                                </th>
                                <th
                                    class="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                    <tbody>
                      {
                          (data.data.length > 0)?
                            data.data.map(data => (
                                <tr class="hover:bg-gray-50 text-xs">
                                    <td class="px-6 border-b border-grey-light">{data.token}</td>
                                    <td class="px-6 border-b border-grey-light">{data.fname} {data.lname}</td>
                                    <td class="px-6 border-b border-grey-light">{data.phone}</td>
                                    <td class="px-6 border-b border-grey-light">{data.location_name}</td>
                                    <td class="px-6 border-b border-grey-light">K{numberWithCommas(data.total_charge)}</td>
                                    <td class="px-6 border-b border-grey-light">K{numberWithCommas(data.shipping_charge)}</td>
                                    <td class="px-6 border-b border-grey-light">{(data.discount)? "K"+numberWithCommas(data.discount) : "K00.00"}</td>
                                    <td class="px-6 border-b border-grey-light">{data.quantity}</td>
                                    <td class="px-6 border-b border-grey-light">
                                        {
                                            (data.status == '0')?
                                                <span
                                                    class="relative inline-block px-3 py-1 font-semibold text-green-600 leading-tight">
                                                    <span aria-hidden
                                                        class="absolute inset-0 bg-green-400 opacity-50 rounded-full"></span>
                                                    <span class="relative">Active</span>
                                                </span>
                                            :(data.status == '1')?
                                                <span
                                                class="relative inline-block px-3 py-1 font-semibold text-rose-600 leading-tight">
                                                    <span aria-hidden
                                                        class="absolute inset-0 bg-orange-200 opacity-50 rounded-full"></span>
                                                    <span class="relative">Cleared</span>
                                                </span>
                                            :(data.status == '2')?
                                                    <span
                                                    class="relative inline-block px-3 py-1 font-semibold text-red-600 leading-tight">
                                                        <span aria-hidden
                                                            class="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                                                        <span class="relative">Failed</span>
                                                    </span>
                                            :(data.status == '3')?
                                            <span
                                                class="relative inline-block px-3 py-1 font-semibold text-yellow-400 leading-tight">
                                                    <span aria-hidden
                                                        class="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"></span>
                                                    <span class="relative">
                                                        lookup
                                                    </span>
                                                </span>
                                            :null
                                        }
                                    </td>
                                    <td class="py-4 px-6 border-b border-grey-light">
                                        <div onClick={(isAll)? (e)=>view(data.id) : (e)=>view(data.sale_id)} className='text-blue-500 cursor-pointer'>View</div>
                                    </td>
                                </tr>
                              ))
                          :null
                      }
                    </tbody>
                </table>
                    {
                        (!isDashboard)?
                            <div className='w-full flex justify-end items-center space-x-3 pt-4 pr-16'>
                                {
                                    (data.prev_page_url)?
                                        <ChevronLeftIcon onClick={prevPage} className='h-7 rounded-md text-white bg-gray-300 cursor-pointer hover:text-gray-400'/>
                                    :
                                        <ChevronLeftIcon className='h-7 rounded-md text-gray-200 bg-gray-300 cursor-pointer'/>
                                }
                                <div className='text-gray-500'>{data.current_page}/{data.last_page}</div>
                                {
                                    (data.last_page > data.current_page)?
                                        <ChevronRightIcon onClick={nextPage} className='h-7 rounded-md text-white bg-gray-300 cursor-pointer hover:text-gray-400' />
                                        :
                                        <ChevronRightIcon className='h-7 rounded-md text-gray-200 bg-gray-300 cursor-pointer' />
                                }
                            </div>
                        :null
                    }
              </div>
    )
}

export default OrdersTableHelperView;
