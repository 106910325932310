import { apiSlice } from "../app/api/apiSlice";

export const topCategoriesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllTopCategories: builder.query({
            query: () => `/user/top_categories`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['top_category']
        }),
        createTopCategory: builder.mutation({
            query: (input) => ({
                url: `user/create-top_category`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['top_category']
        }),
        editTopCategoryImage: builder.mutation({
            query: (input) => ({
                url: `user/edit-top_category-image/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['top_category']
        }),
        editTopCategory: builder.mutation({
            query: (input) => ({
                url: `user/edit-top_category/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['top_category']
        }),
        getTopCategoryById: builder.query({
            query: (id) => `/user/top_category/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['top_category']
        }),

        getTopCategoryByIdMutation: builder.mutation({
            query: (id) => `/user/top_category/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['top_category']
        }),

        deleteTopCategory: builder.mutation({
            query: (id) => ({
                url: `user/delete-top_category/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['top_category']
        }),
    })
})

export const {
    useGetAllTopCategoriesQuery,
    useCreateTopCategoryMutation,
    useEditTopCategoryImageMutation,
    useEditTopCategoryMutation,
    useDeleteTopCategoryMutation,
    useGetTopCategoryByIdMutationMutation,
} = topCategoriesApiSlice

