import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: 'auth',
    initialState: {
         user: null, 
         token: null, 
         roles: null, 
         client_role: null, 
         is_manager: null, 
         is_client_accountant: null, 
         is_client_promotor: null, 
         is_client_data_analyst_manager: null, 
         is_client: null 
        },
    reducers: {
        setCredentials: (state, action) => {
            const { user, token, roles, client_role } = action.payload
            state.user = user
            state.is_manager = (user.is_manager == 1)? 1 : null
            state.is_client = (user.is_client == 1)? 1 : null
            state.is_client_accountant = (user.is_client_accountant == 1)? 1 : null
            state.is_client_promotor = (user.is_client_promotor == 1)? 1 : null
            state.is_client_data_analyst_manager = (user.is_client_data_analyst_manager == 1)? 1 : null
            state.token = token
            state.roles = roles
            state.client_role = client_role
        },
        logOut: (state, action) => {
            state.user = null
            state.is_manager = null
            state.is_client_accountant = null
            state.is_client_promotor = null
            state.is_client_data_analyst_manager = null
            state.is_client = null
            state.token = null
            state.roles = null
            state.client_role = null
        }
    },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token
export const selectCurrentRoles = (state) => state.auth.roles
export const selectCurrentClientRole = (state) => state.auth.client_role
export const selectIsManagerState = (state) => state.auth.is_manager
export const selectIsClientAccountantState = (state) => state.auth.is_client_accountant
export const selectIsClientPromotorState = (state) => state.auth.is_client_promotor
export const selectIsClientDataAnalystManagerState = (state) => state.auth.is_client_data_analyst_manager
export const selectIsClientState = (state) => state.auth.is_client