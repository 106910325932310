import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react'
import swal from 'sweetalert';
import { FileUploader } from "react-drag-drop-files";
import { useEditLogoMutation } from '../../Features/settingsSlice';


function AdminUserSettingsView({props}) {
    const navigate = useNavigate();
    const [openTab, setOpenTab] = useState(1);
    const [file, setFile] = useState(null);
    const fileTypes = ["JPG", "PNG", "GIF"];

    const getBase64 = (file) => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
      });
    };

    const handleFileChange = async(file) => {
      const base64 = await getBase64(file[0])
      setFile(file);
      setInput({...input,  
        logo_image: base64,
      })
    };

    const [input, setInput] = useState({
      logo_image: '',
    })

    const [errors, setErrors] = useState({
      logo_image: '',
    })

    const [editLogo, {isLoading, isSuccess, isError}] = useEditLogoMutation()

    const handleSubmit = async(e)=>{
      e.preventDefault();
      try {
          const resp = await editLogo(input).unwrap()
          swal("Success",resp.message,"success");
          navigate(-1)
      } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
              setErrors({...errors,  
                logo_image: err.data.errors.logo_image,
              })
          }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
          }
      }           
  }
    
    const handleTabClick = (tab) => {
      setOpenTab(tab);
    };

    return (
            <div className="w-full h-screen overflow-x-hidden border-t flex flex-col">
            <main className="w-full flex-grow">
            <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                <h1 className="text-lg font-semibold">Settings</h1>
            </div>
              <div className="w-full mt-4 p-6">
                <div className="flex justify-end items-center">
                  <ul className="flex border-b">
                    <li className="-mb-px mr-1" onClick={() => handleTabClick(1)}>
                      <a
                        className={`${
                          openTab === 1
                            ? 'border-l border-t border-r rounded-t text-gray-900 font-semibold'
                            : 'text-gray-500 hover:text-gray-500'
                        } bg-white inline-block py-2 px-4 font-semibold`}
                        href="#"
                      >
                        Logo
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="bg-white p-6">
                  <div
                    id="tab-1"
                    className={openTab === 1 ? '' : 'hidden'}
                  >
                    <div className='flex justify-center p-10'>
                      <div className="w-full lg:w-2/2 mt-1">
                        <div className="bg-white flex justify-center rounded-md p-4">
                        <form onSubmit={handleSubmit}>
                          <div class="space-y-12">
                            <div class="border-b border-gray-900/10 pb-12">
                              <h2 class="text-base font-semibold leading-7 text-gray-900">Company Logo</h2>

                              <div class="mt-10 grid grid-cols-1 gap-x-10 gap-y-3 sm:grid-cols-6">
                                <div class="col-span-full">
                                  <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Logo image</label>
                                  <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div>
                                      <FileUploader
                                        multiple={true}
                                        handleChange={handleFileChange}
                                        name="file"
                                        types={fileTypes}
                                      />
                                      <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>
                                      <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.logo_image}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="mt-6 flex items-center justify-end gap-x-6">
                            <button type="submit" class="rounded-md bg-rose-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                          </div>
                        </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
    )
}

export default AdminUserSettingsView;
