
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import Home from '../../HeaderLayouts/Home';
import { useEffect, useState } from 'react';


function FAQView({props}) {
    const [open, setOpen] = useState(false)

    const toggleQuestion = (id)=>{
        (id == open)? setOpen(false) : setOpen(id)
    }

    useEffect(()=>{
        window.scrollTo(0, 0)
        return () =>{
        }
      },[]);

    return (
        <main className='w-full bg-gray-50 flex-grow'>
            <Home />
            <div className='md:p-6'>
             <section class="text-gray-900 body-font">
                <div class="container md:px-5 py-24 mx-auto">
                    <div class="text-start mb-12">
                      <h1 class="text-3xl leading-relaxed text-center xl:w-2/6 lg:w-3/4 mx-auto text-gray-900 font-extrabold mb-4">Do You Have Some Questions?</h1>
                      <h1 class="sm:text-m text-md font-sm text-center title-font text-text-400 ">Welcome to your new go-to source for aftermarket auto parts and accessories.</h1>
                    </div>
                    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2 p-4 bg-white">
                    <div class="p-2 sm:w-1/2 w-full items-start">
                        <div class="rounded flex p-4 w-full h-full items-start">
                            <div className="flex flex-col w-full">
                            <span class="title-font text-2xl font-extrabold text-gray-900 mb-3">Shipping Information</span>
                            <span onClick={() => setOpen(open == 1? false : 1)} class="title-font cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 1)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Ship?</p>
                              </div>
                              <p className={`${(open == 1)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span> 

                            <span onClick={() => setOpen(open == 2? false : 2)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 2)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Arrive?</p>
                              </div>
                              <p className={`${(open == 2)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 3? false : 3)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 3)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Can You Leave My Order Outside My Door?</p>
                              </div>
                              <p className={`${(open == 3)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 4? false : 4)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 4)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Where Do You Ship To?</p>
                              </div>
                              <p className={`${(open == 4)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 5? false : 5)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 5)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>What Payments Do You Accept?</p>
                              </div>
                              <p className={`${(open == 5)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 6? false : 6)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 6)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Collaboration/Partnership With Mossery</p>
                              </div>
                              <p className={`${(open == 6)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>
                            </div>  
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 w-full">
                        <div class="rounded flex p-4 h-full items-start">
                        <div className="flex flex-col w-full">
                            <span class="title-font text-2xl font-extrabold text-white mb-3">Shipping Information</span>
                            <span onClick={() => setOpen(open == 7? false : 7)} class="title-font cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 7)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Ship?</p>
                              </div>
                              <p className={`${(open == 7)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span> 

                            <span onClick={() => setOpen(open == 8? false : 8)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 8)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Arrive?</p>
                              </div>
                              <p className={`${(open == 8)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 9? false : 9)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 9)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>How Long Does It Take To Ship To Me?</p>
                              </div>
                              <p className={`${(open == 9)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 10? false : 10)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 10)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>What Is A Refundable Core Deposit?</p>
                              </div>
                              <p className={`${(open == 10)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 11? false : 11)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 11)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Where Do You Ship To?</p>
                              </div>
                              <p className={`${(open == 11)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 12? false : 12)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 12)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>How Long Do I Have To Send Back My Core?</p>
                              </div>
                              <p className={`${(open == 12)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>
                            </div> 
                        </div>
                    </div>
                    </div>

                    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2 p-4 bg-white">
                    <div class="p-2 sm:w-1/2 w-full items-start">
                        <div class="rounded flex p-4 w-full h-full items-start">
                            <div className="flex flex-col w-full">
                            <span class="title-font text-2xl font-extrabold text-gray-900 mb-3">Payments</span>
                            <span onClick={() => setOpen(open == 13? false : 13)} class="title-font cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 13)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Ship?</p>
                              </div>
                              <p className={`${(open == 13)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span> 

                            <span onClick={() => setOpen(open == 14? false : 14)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 14)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Arrive?</p>
                              </div>
                              <p className={`${(open == 14)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 15? false : 15)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 15)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Can You Leave My Order Outside My Door?</p>
                              </div>
                              <p className={`${(open == 15)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 16? false : 16)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 16)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Where Do You Ship To?</p>
                              </div>
                              <p className={`${(open == 16)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 17? false : 17)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 17)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>What Payments Do You Accept?</p>
                              </div>
                              <p className={`${(open == 17)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 18? false : 18)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 18)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Collaboration/Partnership With Mossery</p>
                              </div>
                              <p className={`${(open == 18)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>
                            </div>  
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 w-full">
                        <div class="rounded flex p-4 h-full items-start">
                        <div className="flex flex-col w-full">
                            <span class="title-font text-2xl font-extrabold text-white mb-3">Shipping Information</span>
                            <span onClick={() => setOpen(open == 19? false : 19)} class="title-font cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 19)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Ship?</p>
                              </div>
                              <p className={`${(open == 19)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span> 

                            <span onClick={() => setOpen(open == 20? false : 20)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 20)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Arrive?</p>
                              </div>
                              <p className={`${(open == 20)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 21? false : 21)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 21)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>How Long Does It Take To Ship To Me?</p>
                              </div>
                              <p className={`${(open == 21)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 22? false : 22)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 22)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>What Is A Refundable Core Deposit?</p>
                              </div>
                              <p className={`${(open == 22)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 23? false : 23)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 23)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Where Do You Ship To?</p>
                              </div>
                              <p className={`${(open == 23)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 24? false : 24)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 24)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>How Long Do I Have To Send Back My Core?</p>
                              </div>
                              <p className={`${(open == 24)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>
                            </div> 
                        </div>
                    </div>
                    </div>

                    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2 p-4 bg-white">
                    <div class="p-2 sm:w-1/2 w-full items-start">
                        <div class="rounded flex p-4 w-full h-full items-start">
                            <div className="flex flex-col w-full">
                            <span class="title-font text-2xl font-extrabold text-gray-900 mb-3">Warranty</span>
                            <span onClick={() => setOpen(open == 25? false : 25)} class="title-font cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 25)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Ship?</p>
                              </div>
                              <p className={`${(open == 25)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span> 

                            <span onClick={() => setOpen(open == 26? false : 26)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 26)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Arrive?</p>
                              </div>
                              <p className={`${(open == 26)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 27? false : 27)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 27)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Can You Leave My Order Outside My Door?</p>
                              </div>
                              <p className={`${(open == 27)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 28? false : 28)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 28)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Where Do You Ship To?</p>
                              </div>
                              <p className={`${(open == 28)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 29? false : 29)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 29)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>What Payments Do You Accept?</p>
                              </div>
                              <p className={`${(open == 29)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 30? false : 30)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 30)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Collaboration/Partnership With Mossery</p>
                              </div>
                              <p className={`${(open == 30)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>
                            </div>  
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 w-full">
                        <div class="rounded flex p-4 h-full items-start">
                        <div className="flex flex-col w-full">
                            <span class="title-font text-2xl font-extrabold text-white mb-3">Shipping Information</span>
                            <span onClick={() => setOpen(open == 31? false : 31)} class="title-font cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 31)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Ship?</p>
                              </div>
                              <p className={`${(open == 31)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span> 

                            <span onClick={() => setOpen(open == 36? false : 36)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 36)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>When Will My Order Arrive?</p>
                              </div>
                              <p className={`${(open == 36)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 32? false : 32)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 32)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>How Long Does It Take To Ship To Me?</p>
                              </div>
                              <p className={`${(open == 32)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 33? false : 33)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 33)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>What Is A Refundable Core Deposit?</p>
                              </div>
                              <p className={`${(open == 33)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 34? false : 34)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 34)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>Where Do You Ship To?</p>
                              </div>
                              <p className={`${(open == 34)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>

                            <span onClick={() => setOpen(open == 35? false : 35)} class="title-font mt-2 cursor-pointer font-medium flex flex-col w-full rounded-md space-x-2 border border-gray-200 p-2">
                              <div className='flex items-center space-x-2 w-full'>
                                {
                                    (open == 35)?
                                    <MinusIcon className='h-4'/>
                                    :
                                    <PlusIcon className='h-4'/>
                                }
                              <p className='font-bold'>How Long Do I Have To Send Back My Core?</p>
                              </div>
                              <p className={`${(open == 35)? 'flex' : 'hidden'} mt-3`}>
                              We’re always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You’ll get a notification when your order has started its journey.
                              </p>
                            </span>
                            </div> 
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>
        </main>
    )
}

export default FAQView;
