import { apiSlice } from "../app/api/apiSlice";

export const shippingLocationsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllLocations: builder.query({
            query: () => `/user/locations`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Locations']
        }),
        createLocation: builder.mutation({
            query: (input) => ({
                url: `user/create-location`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Locations']
        }),
        editLocation: builder.mutation({
            query: (input) => ({
                url: `user/edit-location/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['Locations']
        }),
        getLocationById: builder.query({
            query: (id) => `/user/location/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Locations']
        }),

        getLocationByIdMutation: builder.mutation({
            query: (id) => `/user/location/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Locations']
        }),

        deleteLocation: builder.mutation({
            query: (id) => ({
                url: `user/delete-location/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Locations']
        }),
    })
})

export const {
    useGetAllLocationsQuery,
    useCreateLocationMutation,
    useEditLocationMutation,
    useGetLocationByIdMutationMutation,
    useDeleteLocationMutation,
} = shippingLocationsSlice

