import { Link, useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { ArrowCircleDownIcon, ChevronLeftIcon, ChevronRightIcon, PencilAltIcon } from '@heroicons/react/outline';
import { useToggleBookingStatusMutation } from '../../Features/bookingsSlice';
import swal from 'sweetalert';


function BookingsTableHelperView({data, prev, next, view, isDashboard, isAll}) {
    const navigate = useNavigate();

    const [toggleBookingStatus] = useToggleBookingStatusMutation()

    const toggleStatus = async(id,status) => {
      try {
        const resp = await toggleBookingStatus({id: id,status: status}).unwrap()
        swal("Success",resp.message,"success");
      } catch (err) {
        if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
      }
    };

    const nextPage = ()=>{
        next(data.next_page_url);
    }

    const prevPage = ()=>{
        if(data.prev_page_url){
            prev(data.prev_page_url);
        }
    }

    const viewBooking = (id)=>{
            view(id);
    }

    return (
            <div class="bg-white overflow-auto">
                <table class="text-left w-full border-collapse">
                    <thead>
                            <tr>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Name
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Phone
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Address
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Date
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Service
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Site
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Make
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Model
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Payment
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Status
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                    <tbody>
                      {
                          (data.data.length > 0)?
                              data.data.map(data => (

                                <tr class="hover:bg-gray-50 text-xs">
                                    <td class="px-6 border-b border-grey-light">{data.name}</td>
                                    <td class="px-6 border-b border-grey-light">{data.phone}</td>
                                    <td class="px-6 border-b border-grey-light">{data.address}</td>
                                    <td class="px-6 border-b border-grey-light">{data.date}</td>
                                    <td class="px-6 border-b border-grey-light">{(data.service_type == '1')? 'Full Servicing' :(data.service_type == '2')? 'Partial Servicing' :(data.service_type == '3')? 'Custom Servicing' : 'Invalid'}</td>
                                    <td class="px-6 border-b border-grey-light">{data.working_location}</td>
                                    <td class="px-6 border-b border-grey-light">{data.brand_name}</td>
                                    <td class="px-6 border-b border-grey-light">{data.brand_model}</td>
                                    <td class={`px-6 border-b border-grey-light ${(data.payment_status == '1')? 'text-green-500' :(data.payment_status == '2')? 'text-red-600' :(data.payment_status == '3' || data.transport_charge_payment_status == '3')? 'text-yellow-400' : 'text-red-600'}`}>
                                        {
                                            (data.payment_status == '1')? 'Paid' :(data.payment_status == '2')? 'Failed': (data.payment_status == '3' || data.transport_charge_payment_status == '3')? 'Lookup': 'Unpaid'
                                        }
                                    </td>
                                    <td class="px-6 border-b border-grey-light">
                                        {
                                            (data.status == '0')?
                                                <span
                                                    class="relative inline-block px-3 py-1 font-semibold text-green-500 leading-tight">
                                                    <span aria-hidden
                                                        class="absolute inset-0 bg-green-400 opacity-50 rounded-full"></span>
                                                    <span class="relative">Pending</span>
                                                </span>
                                            :(data.status == '1')?
                                                <span
                                                class="relative inline-block px-3 py-1 font-semibold text-yellow-400 leading-tight">
                                                    <span aria-hidden
                                                        class="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"></span>
                                                    <span class="relative">Ongoing</span>
                                                </span>
                                            :(data.status == '2')?
                                                <span
                                                class="relative inline-block px-3 py-1 font-semibold text-red-500 leading-tight">
                                                    <span aria-hidden
                                                        class="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                                                    <span class="relative">Completed</span>
                                                </span>
                                            :(data.status == '3')?
                                                <span
                                                class="relative inline-block px-3 py-1 font-semibold text-red-600 leading-tight">
                                                    <span aria-hidden
                                                        class="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                                                    <span class="relative">Cancelled</span>
                                                </span>
                                            :null
                                        }
                                    </td>
                                    <td class="py-4 px-6 border-b border-grey-light">
                                        <div onClick={(isAll)? (e)=>view(data.booking_id) : (e)=>view(data.booking_id)} className='text-blue-500 cursor-pointer'>View</div>
                                    </td>
                                </tr>
                              ))
                          :null
                      }
                    </tbody>
                </table>
                    {
                        (!isDashboard)?
                            <div className='w-full flex justify-end items-center space-x-3 pt-4 pr-16'>
                                {
                                    (data.prev_page_url)?
                                        <ChevronLeftIcon onClick={prevPage} className='h-7 rounded-md text-white bg-gray-300 cursor-pointer hover:text-gray-400'/>
                                    :
                                        <ChevronLeftIcon className='h-7 rounded-md text-gray-200 bg-gray-300 cursor-pointer'/>
                                }
                                <div className='text-gray-500'>{data.current_page}/{data.last_page}</div>
                                {
                                    (data.last_page > data.current_page)?
                                        <ChevronRightIcon onClick={nextPage} className='h-7 rounded-md text-white bg-gray-300 cursor-pointer hover:text-gray-400' />
                                        :
                                        <ChevronRightIcon className='h-7 rounded-md text-gray-200 bg-gray-300 cursor-pointer' />
                                }
                            </div>
                        :null
                    }

              </div>
    )
}

export default BookingsTableHelperView;
